import { Component, ElementRef } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { liveThreshold } from '../configuration/player-general.config';

@Component({
  selector: 'ty-live-button',
  templateUrl: './live-button.component.html',
  styleUrls: ['./live-button.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LiveButtonComponent }],
})
export class LiveButtonComponent extends NavigationComponent {
  isAtLiveEdge$: Observable<boolean>;

  constructor(public el: ElementRef, private galgoPlayerService: GalgoPlayerService) {
    super(el);

    this.isAtLiveEdge$ = this.galgoPlayerService.currentTime$.pipe(
      map(currentTime => this.galgoPlayerService.driver.duration - currentTime < liveThreshold)
    );
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onClick(): void {
    this.isAtLiveEdge$.pipe(first()).subscribe({
      next: isAtLiveEdge => {
        if (!isAtLiveEdge) {
          this.galgoPlayerService.driver.currentTime = this.galgoPlayerService.driver.duration;
        }
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  private selectMenuItem$: Subject<string>;

  get menuItemSelected$(): Observable<string> {
    return this.selectMenuItem$.asObservable();
  }

  constructor() {
    this.selectMenuItem$ = new Subject<string>();
  }

  selectMenuItem(id: string): void {
    this.selectMenuItem$.next(id);
  }
}

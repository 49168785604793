<div *ngIf="(showAVList$ | async) === false"
     class="primary-ui-button"
     appNavigation
     [navigableId]="0"
     (click)="onClickBtn()">
  <span *ngIf="showAVControlsTooltip"
        class="tooltip"> {{ itemType | translate }}</span>
  <button class="ui-button button-medium">
    <div class="content">
      <svg *ngIf="icon"
           class="icon"
           [class.icon--space]="!showAVControlsPlayerText"
           aria-hidden="true"
           viewBox="0 0 64 64">
        <use [attr.href]="icon + '#icon'"></use>
      </svg>
      <span *ngIf="showAVControlsPlayerText"
            class="text">{{itemType | translate}}</span>
    </div>
  </button>
</div>
<ty-list-selector *ngIf="showSubtitleList"
                  [navigableId]="1"
                  [itemTracks]="itemTracks"
                  [typeOfList]="typeOfList"
                  (subtitledChanged)="changeItem($event)"></ty-list-selector>
import { Component } from '@angular/core';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { LanguageService } from '@app/core/services/language.service';
import { SubtitleApi } from '@app/galgo-api/models.js';
import { PLAYER_UNAVAILABLE_MODAL } from '@app/galgo-player/config/player-unavailable-modal.conf';
import { GalgoPlayerComponent } from '@app/galgo-player/galgo-player.component';
import { Driver, IDriverCreateParams, PlayerDetailType } from '@app/galgo-player/models';
import { ModalService } from '@app/shared/components/modal/services/modal.service';
import { AudioTrack } from '@app/shared/models/audioTrack.interface.js';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import * as THEOplayer from '../../../../assets/libraries/theo/THEOplayer.js';
import { THEO_DEFAULT_OPTIONS } from './theo-default-options';

enum PresentationModes {
  fullScreen = 'fullscreen',
  inline = 'inline',
  pip = 'picture-in-picture'
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-theo-driver',
  template: '',
})
export class TheoDriverComponent extends Driver {
  constructor(
    private logger: NGXLogger,
    private customerDataService: CustomerDataService,
    private languageService: LanguageService,
    private galgoPlayerService: GalgoPlayerService,
    private modalService: ModalService,
    private browsingHistoryService: BrowsingHistoryService,
    private galgoPlayerRef: GalgoPlayerComponent) {
    super();
  }

   get paused(): boolean{
    return this.player.paused;
   }

   get live(): boolean {
    return this.player.duration === Infinity;
   }

   get duration(): number {
    return this.player.duration;
  }

  get audioTracks(): AudioTrack[] {
    return Array.from(this.player.audioTracks as any[])
      .filter(track => !track.label.startsWith('Sound'))
      .map(track => ({
        enabled: track.enabled,
        id: track.id,
        kind: track.kind,
        label: track.label,
        language: track.language,
        isAudio: true
      }));
  }

  get currentTime(): number{
    return this.player.currentTime;
  }

  set currentTime(time: number){
    this.player.currentTime = time < 0 ? 0 : time;
  }

  enableAudioTrack(id: number): void {
    this.player.audioTracks[id].enabled = true;
  }

  getAvailableSubtitles(): TextTrack[] {
    return this.player.textTracks;
  }

  isSeekable() {
    return !this.live;
  };

  create(params: IDriverCreateParams): void {
    const options: THEOplayer.UIPlayerConfiguration = {
      ...THEO_DEFAULT_OPTIONS,
      license: this.customerDataService.playerCredentials?.license
    };
    options.ui.language = this.languageService.currentLang;
    this.logger.trace('TheoDriverComponent => create: params', params);
    this.player = new THEOplayer.Player(params.videoElement, options);
    this.player.autoplay = params.autoplay;
    this.player.preload = 'auto';
    this.player.controls = false;
    if (params.fullScreen) {
      this.checkFullscreenMode();
    }
    this.captureEvents();
  }

  destroy(): void {
    this.logger.debug('TheoDriverComponent => destroy');
    this.player.destroy();
  }

  captureEvents(): void {
    this.logger.trace('TheoDriverComponent => captureEvents');
    // TODO: map events from theo to standard
    this.player.addEventListener('playing', () => {
      this.logger.trace('TheoDriverComponent => captureEvents: onPlaying');
      this.onPlaying();
      this.galgoPlayerService.updateStatus((status) => (status.playing = true));
    });
    this.player.addEventListener('pause', () => {
      this.logger.trace('TheoDriverComponent => captureEvents: onPause');
      this.onPause();
      this.galgoPlayerService.updateStatus((status) => (status.playing = false));
    });
    this.player.addEventListener('ended', () => {
      this.onEnded();
      this.galgoPlayerService.updateStatus((status) => (status.ended = true));
    });

    //this.player.addEventListener('adscomplete', () => this.onAdsComplete());

    this.player.addEventListener('error', (event) => {
      console.error('Error in reproduction:', event.error);
      this.modalService.open(PLAYER_UNAVAILABLE_MODAL, this.galgoPlayerRef, () => {
        this.browsingHistoryService.goBack();
      });
    });
  }

  updateSource(params: PlayerDetailType): void {
    this.logger.trace('TheoDriverComponent => updateSource params are', params);
    const source: any = {
      sources: [{
        src: params.src,
        contentProtection: params.drm
      }],
      poster: params.poster,
      // FIX: try for detect ad blocker
      // blockContentIfAdError: true
      metadata: {
        title: params.title
      }
    };
    if (params.adsTagUrl) {
      source.ads = [{
        sources: params.adsTagUrl
      }];
    }

    const tracks = [];
    if (params?.subtitlesData) {
      params.subtitlesData.forEach((element: SubtitleApi) => {
        tracks.push({
          kind: 'captions',
          label: element.description,
          srclang: element.language,
          src: element.url,
          forced: element.forced
        });
      });
    }
    if (tracks?.length) {
      this.logger.trace('Text tracks lenght', tracks?.length);
      tracks[0].default = false;
      source.textTracks = tracks;
    }

    this.logger.trace('TheoDriverComponent => updateSource: source', source);
    this.player.source = source;

    this.logger.trace('TheoDriverComponent => updateSource: player object', this.player);

    this.galgoPlayerService.setAspectRatio();
  }

  forward(seconds?: number): void {
    this.logger.trace('TheoDriverComponent => forward: currentTime', seconds, this.player);
    this.player.currentTime += seconds;
  }

  rewind(seconds?: number): void {
    this.logger.trace('TheoDriverComponent => rewind: currentTime', seconds, this.player);
    this.player.currentTime -= seconds;
  }


  private async checkFullscreenMode() {
    if (this.player.presentation.supportsMode(PresentationModes.fullScreen)) {
      try {
        this.player.presentationMode = PresentationModes.fullScreen;
        await this.player.presentation.requestMode('fullscreen');
        this.logger.log('Current mode', this.player.presentation.currentMode);
      } catch {
        this.logger.log('NO FULLSCREEN ALLOWED');
      }
    } else {
      this.player.presentationMode = PresentationModes.inline;
    }
  }

}

<ty-background-thumbnail class="co-background-thumbnail"
                         *ngIf="backgroundUrl | async as background"
                         [thumbnail]="background">
</ty-background-thumbnail>
<div class="content-detail">
  <div class="content-detail__container">
    <div class="content-detail__title">
      {{title | async}}
    </div>
    <div class="event-time">
      <div class="time-range">{{startEvent | async}}{{endEvent | async}}</div>
    </div>
    <div class="description">{{description | async}}</div>
  </div>
</div>
<div class="epg-container">
  <ty-day-selector class="day-selector"
                   [days]="daysMenuItems"
                   [daySelected]="activeDay"
                   (daySelectedChange)="changeDay($event)"
                   [navigableId]="0"></ty-day-selector>
  <div class="epg"
       appNavigation
       (click)="goToProgram()"
       [navigableId]="1"
       [visTimeline]="timelineName"
       [visTimelineItems]="timelineItems"
       [visTimelineOptions]="timelineOptions"
       [visTimelineGroups]="timelineGroups"
       (initialized)="timelineInitialized()"></div>
</div>
<div class="landing-page-logo">
  <img src="{{defaultLogoUrl}}"
       alt="{{customerProductName}}">
</div>
<h1 *ngIf="clientTopic?.title; else noTitle">{{clientTopic?.title}}</h1>
<ng-template #noTitle>
  <h1 [innerHTML]="'customer.landing.title' | translate: {'productName': customerProductName}"></h1>
</ng-template>
<div class="primary-button login-button-container"
     (click)="goTo(appRoutes.login)"
     appNavigation
     [navigableId]="0">
  <button class="button button-large">{{'landing.button' | translate}}</button>
</div>
<div class="secondary-button login-button-container"
     *ngIf="exploreMode"
     (click)="goTo(appRoutes.home)"
     appNavigation
     [navigableId]="1">
  <button class="button button-large">{{'landing.explore' | translate}}</button>
</div>
<div class="tertiary-button login-button-container"
     *ngIf="!hideCreateAccount"
     (click)="goTo(appRoutes.signIn)"
     appNavigation
     [navigableId]="2">
  <button class="button button-large">{{'landing.create' | translate}}</button>
</div>
<div class="landing-footer-links">

  <div class="quaternary-button login-button-container"
       (click)="goTo(appRoutes.terms)"
       appNavigation
       [navigableId]="3">
    <button class="button button-medium">{{'legal.terms' | translate}}</button>
  </div>

  <div class="quaternary-button login-button-container"
       (click)="goTo(appRoutes.privacy)"
       appNavigation
       [navigableId]="4">
    <button class="button button-medium">{{'legal.privacy' | translate}}</button>
  </div>
</div>

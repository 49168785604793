import { Injectable } from '@angular/core';
import { NextVideoApi } from '@app/galgo-api/models';
import { PlayerDrivers } from '@app/galgo-player/models';
import { Store } from '@shared/utils/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AudioTrack } from '../models/audioTrack.interface';
import { PlayerStatus } from '../models/player-status.model';

@Injectable({
  providedIn: 'root'
})
export class GalgoPlayerService {

  driver: PlayerDrivers;
  driverLoader: Subject<boolean>;

  endedVideo$: Subject<void>;
  showNextChapter$: BehaviorSubject<boolean>;
  nextVideo$: BehaviorSubject<NextVideoApi>;
  private goBack$: BehaviorSubject<boolean>;

  private store: Store<PlayerStatus>;

  constructor() {
    this.store = new Store<PlayerStatus>(new PlayerStatus());
    this.driverLoader = new Subject<boolean>();

    this.nextVideo$ = new BehaviorSubject<NextVideoApi>(null);
    this.goBack$ = new BehaviorSubject<boolean>(false);
    this.endedVideo$ = new Subject<void>();
    this.showNextChapter$ = new BehaviorSubject<boolean>(false);
  }

  get status() {
    return this.store.getStatus();
  }

  get live(): boolean {
    return this.driver?.live;
  }

  get duration(): number {
    return this.driver?.duration;
  }

  get currentTime(): number {
    return this.driver?.currentTime;
  }

  get playbackPercentage(): number {
    if (!this.duration || this.duration === 0) {
      return 0;
    }
    return (this.currentTime / this.duration) * 100;
  }

  get subtitles(): any[] {
    return this.driver.getAvailableSubtitles()?.filter(track =>
      ['captions', 'subtitles'].includes(track.kind)
    );
  }

  get audioTracks(): AudioTrack[]{
    return this.driver.audioTracks;
  }

  enableAudioTrack(id: number) {
    this.driver?.enableAudioTrack(id);
  }

  select(selector: (status: PlayerStatus) => any, distinct: boolean = true): Observable<PlayerStatus> {
    return this.store.select(selector, distinct);
  }

  updateStatus(callback: (status: PlayerStatus) => void): PlayerStatus {
    const result = this.store.updateStatus(callback);
    return result;
  }

  reset(): void {
    this.store = new Store<PlayerStatus>(new PlayerStatus());
  }

  setDriver(driver: PlayerDrivers) {
    this.driver = driver;
    this.driverLoader.next(true);
  }

  getShowPlayerControls(): boolean {
    return this.store.getStatus().showControls;
  }

  getDriver() {
    return this.driver;
  }

  getNextVideo(): Observable<NextVideoApi> {
    return this.nextVideo$.asObservable();
  }

  nextVideo(nextVideo: NextVideoApi): void {
    this.nextVideo$.next(nextVideo);
  }

  isGoBack(): Observable<boolean> {
    return this.goBack$.asObservable();
  }

  goBack(isGoBack: boolean): void {
    this.goBack$.next(isGoBack);
  }

  endedVideo(): void {
    this.endedVideo$.next();
  }

  nextchapterShow(value: boolean): void {
    this.showNextChapter$.next(value);
  }

  setAspectRatio(): void {
    const videoElement = document.querySelector('video');
    videoElement.addEventListener('loadedmetadata', () => {
      // Obtains the actual height and width of the video and calculates the aspect ratio
      const videoHeight = videoElement.videoHeight;
      const videoWidth = videoElement.videoWidth;

      if (videoWidth / videoHeight < 1.7) {
        // If it's not 16:9, it sets a maximum height for the videos and selects their parent .vjs-tech to center them.
        videoElement.style.maxHeight = '100vh';

        const vjsElement = document.querySelector('.vjs-tech') as HTMLElement;

        if (vjsElement) {
          vjsElement.style.display = 'flex';
          vjsElement.style.alignItems = 'center';
          vjsElement.style.justifyContent = 'center';
        }
      }
    });
  }


}

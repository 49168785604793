<div class="primary-ui-button"
     appNavigation
     [navigableId]="0"
     (click)="buttonPlayerEvent()">
  <span *ngIf="showTooltipButton"
        class="tooltip"> {{ tooltipButton | translate }}</span>
  <button class="ui-button button-medium">
    <div class="content">
      <svg *ngIf="icon"
           class="icon"
           [class.icon--space]="!showLabelButton"
           aria-hidden="true"
           viewBox="0 0 32 32">
        <use [attr.href]="'./assets/icons/'+icon + '#icon'"></use>
      </svg>
      <span *ngIf="showLabelButton"
            class="text">{{labelButton | translate}}</span>
    </div>
  </button>
</div>
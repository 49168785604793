import { ActionList } from '@core/models/action-list.enum';
import { IconList } from '@core/models/icon-list.enum';
import { IButtonConfig } from '@shared/components/button-list/button-config.interface';
import { IDetailConfig } from '@shared/components/detail/detail-config';
import { IIconSource } from '@shared/models/button/icon-source.interface';

export const subscriptionButtonConfig: IButtonConfig = {
  icon: {
    unfilled: IconList.playIcon,
    filled: IconList.playIcon
  },
  alternativeIcon: {
    unfilled: IconList.playIcon,
    filled: IconList.playIcon
  },
  id: 'subscription',
  text: 'buttons.watchWithSubscription',
  alternativeText: 'buttons.play',
  action: ActionList.play,
  active: true,
  disable: true,
  secondaryText: false,
  initialPosition: true
};

export const rentButtonConfig: IButtonConfig = {
  icon: {
    unfilled: IconList.purchasesIcon,
    filled: IconList.purchasesIcon
  },
  alternativeIcon: {
    unfilled: IconList.playIcon,
    filled: IconList.playIcon
  },
  id: 'rent',
  text: 'buttons.rent',
  alternativeText: 'buttons.play',
  action: ActionList.rent,
  active: true,
  disable: true,
  secondaryText: false,
  initialPosition: true
};

export const videoDetailBtnListConfig: IButtonConfig[] = [
  subscriptionButtonConfig,
  rentButtonConfig
];

export const mySpaceButtonIcons: IIconSource[] = [
  {
  unfilled: IconList.addMySpaceIcon,
  filled: IconList.addMySpaceIcon
  },
  {
  unfilled: IconList.removeMySpaceIcon ,
  filled: IconList.removeMySpaceIcon
  }
];

export const videoDetailConfig: IDetailConfig =
{
    showDescription: true
};

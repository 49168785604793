<ty-player-ui *ngIf="showControls && !isTrailer"
              [navigableId]="0"></ty-player-ui>
<ng-container [ngSwitch]="playerName">
  <div *ngSwitchCase="'theo'"
       [id]="playerName"
       #theo
       class="theoplayer-container video-js vjs-fluid theoplayer-skin">
  </div>

  <div *ngSwitchCase="'videojs'">
    <video [id]="playerName"
           #videojs
           [muted]="options.muted"
           class="video-js">
    </video>
  </div>

  <div *ngSwitchCase="'brightcove'"
       [id]="playerName"
       #brightcove>
    <video playsinline
           [muted]="options.muted"
           id="player"></video>
    <img *ngIf="isAudio"
         [src]="thumbnail"
         class="audio-poster"
         #audioPoster>
  </div>

  <div *ngSwitchCase="'shaka'"
       id="video-container">
    <video #shaka
           [poster]="poster"
           autoplay
           [muted]="options.muted"
           controls>
    </video>
  </div>

  <div *ngSwitchDefault>
    <h1>Not Player selected</h1>
  </div>

</ng-container>
<ty-next-chapter *ngIf="!showControls && !isTrailer && showNextChapter"
                 [navigableId]="1"></ty-next-chapter>
<ty-dog *ngIf="showDogLogo && !isTrailer"></ty-dog>
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { platforms } from '@app/core/navigation/models/platforms.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { NavigationModeService } from '@app/core/navigation/services/navigation-mode.service';
import { PlatformService } from '@app/core/navigation/services/platform.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Carousel } from '@shared/features/carousel/model/carousel.model';
import { Collection } from '@shared/models/collection/collection.model';
import { ItemTypes } from '@shared/models/item-types.enum';
import { Video } from '@shared/models/video/video.model';
import { VirtualScrollItem } from '../virtual-scroll/virtual-scroll-item.interface';
import { VirtualScrollComponent } from '../virtual-scroll/virtual-scroll.component';

@UntilDestroy()
@Component({
  selector: 'ty-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: CarouselComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent extends NavigationComponent implements OnInit, OnChanges {
  @Input() carouselIndex?: number;
  @Input() set data(carousel: Carousel) {
    this.initData(carousel);
  }
  @Input() firstSelectedChildNodeId: number;

  @Output() selectedItem: EventEmitter<MediaItem<MediaItemTypes>>;

  @ViewChild('carousel') carousel!: ElementRef;
  @ViewChild('virtualScroll') virtualScrollComponent!: VirtualScrollComponent;

  virtualScrollItems: VirtualScrollItem[];
  carouselActive: number;
  carouselItem;
  scrollLeft: number;
  mediaItems: MediaItem<MediaItemTypes>[];
  isCursorMode: boolean;
  isBrowser: boolean;
  moveRight: any;
  moveLeft: any;
  selectionDelayTimer: ReturnType<typeof setTimeout>;

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
    public el: ElementRef,
    private settingsService: SettingsService,
    private navigationModeService: NavigationModeService,
    private featureFlagsService: FeatureFlagsService,
    private platformService: PlatformService,
    private changeDetector: ChangeDetectorRef

  ) {
    super(el);
    this.isBrowser = this.platformService.platform === platforms.browser;
    this.carouselActive = 0;
    this.virtualScrollItems = [];
    this.firstSelectedChildNodeId = 0;
    this.scrollLeft = 0;
    this.mediaItems = [];
    this.selectedItem = new EventEmitter<MediaItem<MediaItemTypes>>();
  }

  ngOnInit(): void {
    if(this.firstSelectedChildNodeId === 15){
      this.firstSelectedChildNodeId--;
    }
    this.navigationModeService.cursor.pipe(untilDestroyed(this)).subscribe({
      next: (isCursorMode: boolean) => {
        this.changeCursorMode(isCursorMode);
      },
      error: (error) => {
        this.isCursorMode = false;
      }
    });
  }

  ngOnChanges(): void {
    this.selectChildNodeById(this.firstSelectedChildNodeId);
    this.processItems();
  }

  onFirstChildNodes(): void {
    this.updateScroll();
    this.changeDetector.detectChanges();
  }

  processItems(): void {
    this.virtualScrollItems =
    this.mediaItems?.map((item: any, index: number) => ({
        id: index,
        width: 18,
        height: 0,
        data: item,
      })) || [];
  }

  onStartScrollLeft() {
    this.moveLeft = setInterval(() => {
      if (this.scrollLeft < 0) {
        this.carousel.nativeElement.style.transform = `translateX(${this.scrollLeft}px)`;
        this.scrollLeft += 3;
      }
    }, 10);
  }

  onStartScrollRight() {
    const last = this.getChildNodeByPosition(this.getChildNodes().length - 1);
    let scrollLeft = 0;
    if (last) {
      scrollLeft = last.getLeafOffsetLeft();
    }
    this.moveRight = setInterval(() => {
      if (this.scrollLeft <= 0 && this.scrollLeft > -scrollLeft) {
        this.carousel.nativeElement.style.transform = `translateX(${this.scrollLeft}px)`;
        this.scrollLeft -= 3;
      }
    }, 10);
  }

  onStopScrollLeft() {
    clearInterval(this.moveLeft);
  }

  onStopScrollRight() {
    clearInterval(this.moveRight);
  }

  isScrollLeftEnabled(): boolean {
    if (this.mediaItems.length > 6 && this.scrollLeft < 0) {
      return true;
    } else {
      return false;
    }
  }

  isScrollRightEnabled(): boolean {
    const last = this.getChildNodeByPosition(this.getChildNodes().length - 1);
    let scrollLeft = 0;
    if (last) {
      scrollLeft = last.getLeafOffsetLeft();
    }
    if (this.mediaItems.length > 6 && this.scrollLeft >= -(scrollLeft - 100)) {
      return true;
    } else {
      return false;
    }
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() > 0) {
      if (this.selectPreviousChildNode()) {
        this.virtualScrollComponent?.scrollToPreviousElement();
        this.updateScroll();
        this.changeDetector.detectChanges();
      }
      return true;
    }
    return false;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() < this.virtualScrollItems.length - 1) {
      if (this.selectNextChildNode()) {
        this.virtualScrollComponent?.scrollToNextElement();
        this.updateScroll();
        this.changeDetector.detectChanges();
      }
      return true;
      //Id last element of the virtual scroll
      }else if(this.getSelectedChildNodeId() === 14 && this.selectNextChildNode()){
        this.selectNextChildNode();
        this.updateScroll();
        this.changeDetector.detectChanges();
      }
      return false;
    }

  updateScroll(): void {
    const focused = this.getSelectedChildNode();
    if (focused) {
      //Id see more element
      if(this.getSelectedChildNodeId() === 15){
        this.carousel.nativeElement.style.transform = `translateX(-271.25rem)`;
      }else{
        this.scrollLeft = focused.getLeafOffsetLeft();
        this.carousel.nativeElement.style.transform = `translateX(-${this.scrollLeft}px)`;
      }
    }
  }

  navigateToVideoDetails(item: MediaItem<MediaItemTypes>): void {
    this.cancelTimeout();
    this.settingsService.lastView$ = 'home';
    switch (item?.itemType) {
      case ItemTypes.Collection:
        switch (item?.collectionType) {
          case 'Microsite':
            this.router.navigate([AppRoutes.microsites, item?.id]);
            break;
          case 'Collection':
            this.router.navigate([AppRoutes.collections, item?.id]);
            break;
          case 'Serie':
            this.router.navigate([AppRoutes.series, item?.id]);
            break;
        }
        break;
      case ItemTypes.Video:
        const video = item as unknown as Video;
        this.analyticsService.logEvent(GalgoEvent.videoFromHome, {
          video: item,
        });
        this.router.navigate([item.live ? AppRoutes.player : AppRoutes.video, video?.id]);
        break;
    }
  }

  navigateToCarouselDetails(): void {
    this.settingsService.lastView$ = 'home';
    this.router.navigate([AppRoutes.seeMore, this.carouselItem._id]);
  }

  carouselItemSelected(item?) {
    this.selectedItem.emit(item);
  }

  carouselItemSelectedHover(item?) {
    this.selectionDelayTimer  = setTimeout(() => {
      this.selectedItem.emit(item);
    }, 500);
  }

  cancelTimeout() {
    clearTimeout(this.selectionDelayTimer );
  }

  trackByFn(index: number, item: any): number {
    return item.id;
  }

  private initData(carousel: Carousel) {
    this.carouselItem = carousel;
    const collections: MediaItem<Collection>[] =
    this.filterCollections(carousel);
    const filteredVideos = this.filterVideos(carousel);
    this.mediaItems = [...collections, ...filteredVideos].slice(0, 15);

    //filtro para remover directo
    if(this.featureFlagsService.currentFeaturesValue.filterLives){
    this.mediaItems = this.mediaItems.filter((element) => element.live !== true);
    }
  }

  private filterCollections(carousel: Carousel) {
    return carousel?.subContainers
      ?.map((collection) => new Collection(collection))
      ?.filter((collection: Collection) => collection.numOfChapters > 0);
  }

  private filterVideos(carousel: Carousel): MediaItem<Video>[] {
    return carousel?.videos?.filter(
      (video: MediaItem<Video>) =>
        !video.itemType?.includes(ItemTypes.Advertisement)
    );
  }

  private changeCursorMode(isCursorMode: boolean): void {
    this.isCursorMode = isCursorMode;
    if (!this.isCursorMode) {
      this.onStopScrollLeft();
      this.onStopScrollRight();
    }
  }
}

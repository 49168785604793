import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { ErrorResponseApi } from '@app/galgo-api/models';
import { PlayerName } from '@app/galgo-player/config';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { AudioTrack } from '@app/shared/models/audioTrack.interface';
import { IPlayPauseButton } from '@app/shared/models/button-player.interface';
import { PlayerStatus } from '@app/shared/models/player-status.model';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { IButtonConfig } from '../button-list/button-config.interface';
import { playerButtons } from '../controls-player/configuration/player-buttons.config';
import { PlayerConfigUserService } from '../controls-player/services/player-config-user.service';

@Component({
  selector: 'ty-player-ui',
  templateUrl: './player-ui.component.html',
  styleUrls: ['./player-ui.component.scss'],
   providers: [
      { provide: NavigationComponent, useExisting: PlayerUiComponent },
    ],
})
export class PlayerUiComponent extends NavigationComponent implements OnInit {
  playerName: PlayerName;
  isLive: boolean;
  isSeekable: boolean;
  showRestartButton: boolean;
  audioTracksArray: AudioTrack[] = [];
  iconSubtitle = './assets/icons/btn-subtitle.svg';
  iconAudio = './assets/icons/btn-audio.svg';
  isSeeking$: Observable<boolean>;
  showAVList$: Observable<boolean>;
  isPlaying: boolean;
  iconFadeOutSubject = new BehaviorSubject<boolean>(true);
  iconFadeOut$ = this.iconFadeOutSubject.asObservable();

  get centralButton(): IPlayPauseButton {
    return playerButtons?.play_pause;
  }

  get nextChapterButton(): IButtonConfig {
    return playerButtons?.nextChapter;
  }

  get restartButton(): IButtonConfig {
    return playerButtons?.restart;
  }

  get showNextChapterButton(): boolean {
    return this.videoDetailService.getVideoNext() ? true : false;
  }

  get textTracks(): TextTrack[] {
    return this.galgoPlayerService?.subtitles;
  }

  get audioTracks(): AudioTrack[]{
    return this.galgoPlayerService?.audioTracks;
  }

  get showBackButton(): boolean {
    return this.featureFlagsService?.currentFeaturesValue?.showBackButton;
  }

  get showControls(): boolean {
    return this.galgoPlayerService?.status.showControls;
  }

  get showNextChapterButtonTooltip(): boolean {
    return this.customerDataService?.playerUI?.showNextChapterButtonTooltip;
  }

  get showNextChapterButtonText(): boolean {
    return this.customerDataService?.playerUI?.showNextChapterButtonText;
  }

  get showStartOverButtonTooltip(): boolean {
    return this.customerDataService?.playerUI?.showStartOverButtonTooltip;
  }

  get showStartOverButtonText(): boolean {
    return this.customerDataService?.playerUI?.showStartOverButtonText;
  }

  get iconUrl(): string {
    return this.isPlaying
      ? './assets/icons/icon_play.svg#icon'
      : './assets/icons/icon_pause.svg#icon';
  }

  get isBrowserOldVersion(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    const chromeMatch = userAgent.match(/chrome\/(\d+)\./);
    const tizenMatch = userAgent.match(/tizen\/(\d+\.\d+)/);

    if (chromeMatch) {
      const chromeVersion = parseInt(chromeMatch[1], 10);
      return chromeVersion < 79;
    }

    if (tizenMatch) {
      const tizenVersion = parseFloat(tizenMatch[1]);
      return tizenVersion < 6;
    }

    return true;
  }

  constructor(
    public el: ElementRef,
    public logger: NGXLogger,
    private customerDataService: CustomerDataService,
    private galgoPlayerService: GalgoPlayerService,
    private featureFlagsService: FeatureFlagsService,
    private videoDetailService: VideoDetailService,
    private playerConfigUserService: PlayerConfigUserService
  ) {
    super(el);
    this.playerName = this.customerDataService.playerName;
    this.isLive = this.galgoPlayerService.live;
    this.isSeekable = this.galgoPlayerService.isSeekable;
    this.showRestartButton = this.galgoPlayerService.playbackPercentage > 90;
   }

  ngOnInit(): void {
    this.setDefaultActiveChildNodeId(4);
    this.isSeeking$ = this.galgoPlayerService.getSeeking();
    this.showAVList$ = this.galgoPlayerService.getShowAVList();
    this.playingChanges();
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() > 4 && this.getSelectedChildNodeId() < 10){
      if (this.getSelectedChildNodeId() > 5) {
        this.selectPreviousChildNode();
      }
    }

    return true;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() > 4 && this.getSelectedChildNodeId() < 10){
      if (this.getSelectedChildNodeId() < 10) {
        this.selectNextChildNode();
      }
    }
    return true;
  }

  onDownKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
      this.selectChildNodeById(4);
    } else if (this.getSelectedChildNodeId() >4 && this.getSelectedChildNodeId() < 10) {
      this.selectChildNodeById(4);
    }
    return true;
  }

  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() > 4 && this.getSelectedChildNodeId() < 10 && this.showBackButton) {
      this.selectChildNodeById(0);
    }
    if (this.getSelectedChildNodeId() === 4) {
      if(this.getChildNodeById(5)){
        this.selectChildNodeById(5);
      }else if(this.getChildNodeById(6)){
        this.selectChildNodeById(6);
      }else if(this.getChildNodeById(7)){
        this.selectChildNodeById(7);
      }else if(this.getChildNodeById(8)){
        this.selectChildNodeById(8);
      }
    }
    return true;
  }

  changeSubtitle(subtitleId: string | number) {
    if (subtitleId !== 0) {
      this.textTracks.forEach(track => {
        track.mode = 'disabled';
      });
      this.galgoPlayerService.driver.unselectSubtitles();
      this.textTracks.find(track => track.id === subtitleId).mode = 'showing';
      this.playerConfigUserService.saveSelectedSubtitles(subtitleId, this.textTracks);
      this.galgoPlayerService.driver.setSelectedSubtitle(subtitleId);
    } else {
      this.textTracks.forEach(track => {
        track.mode = 'disabled';
      });
      this.playerConfigUserService.removeSelectedSubtitles();
      this.galgoPlayerService.driver.unselectSubtitles();
    }
  }
  changeAudio(audioId: number) {
    this.playerConfigUserService.saveSelectedAudio(audioId, this.audioTracks);
    this.galgoPlayerService.enableAudioTrack(audioId);
  }

  private playingChanges() {
    this.galgoPlayerService
      .select((status) => status.playing)
      .pipe(untilDestroyed(this))
      .subscribe(
        (status: PlayerStatus) => {
          this.isPlaying = status.playing;
          this.iconFadeOutSubject.next(false);
          setTimeout(() => {
            this.iconFadeOutSubject.next(true);
          }, 1000); // Match the duration of the CSS transition
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'ButtonPlayerComponent -> PlayingChanges(): Failed',
            error
          );
        }
      );
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISubscriptionResult } from '@app/auth/models/check-subscription.interface';
import { SubscriptionPlan } from '@app/auth/models/subscription-plan.model';
import { SubscriptionResults } from '@app/auth/models/subscription-results.enum';
import { UserService } from '@app/auth/services/user/user.service';
import { IFeatureToggle } from '@app/core/models/feature-toggle.interface';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { PaidItemsResponseApi, VideoAccessApi } from '@app/galgo-api/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CheckSubscriptionService {
  features: IFeatureToggle;
  featureToggleData: Observable<IFeatureToggle>;
  constructor(
    private userService: UserService,
    private featureFlagsService: FeatureFlagsService,
    public logger: NGXLogger,
  ) {
    this.getFeatures();
    }

  blockMedia(mediaItem: MediaItem<MediaItemTypes>): ISubscriptionResult {
    const purchases = this.userService?.userProducts?.paidVideos;
    let blockPlayer = false;
    let reason: SubscriptionResults = SubscriptionResults.noUserAuthenticated;

    const requiresPPV = !!mediaItem.layout?.ppv?._id;
    const requiresSubscription = !!mediaItem.layout?.subscription?._id;
    const subscriptionList = mediaItem.products?.map(product => product) || [];

    if (!this.userService.isLoggedIn() && mediaItem.access !== VideoAccessApi.Public) {
        return { result: true, reason: SubscriptionResults.noUserAuthenticated };
    }

    const userSubscriptions = this.userService?.userProducts?.subscriptions?.map(
        (sub: SubscriptionPlan) => sub.id
    ) || [];

    const purchased = [...userSubscriptions, ...(purchases?.map(purchase => purchase.videoId) || [])];

    if (requiresSubscription && requiresPPV) {
        const hasSubscription = purchased.some(id => subscriptionList.includes(id));
        const hasPPV = purchases?.some(purchase => purchase.videoId === mediaItem.id);

        if (!hasSubscription && !hasPPV) {
            reason = SubscriptionResults.noUserSubscriptionsAndPpv;
            blockPlayer = true;
        }
    } else if (requiresSubscription) {
        if (!userSubscriptions.some(id => subscriptionList.includes(id))) {
            reason = SubscriptionResults.noUserSubscriptions;
            blockPlayer = true;
        }
    } else if (requiresPPV) {
        if (!purchases?.some(purchase => purchase.videoId === mediaItem.id)) {
            reason = SubscriptionResults.ppvError;
            blockPlayer = true;
        }
    }

    return { result: blockPlayer, reason };
  }

  getPurchases(): Promise<PaidItemsResponseApi[]> {
    return new Promise<PaidItemsResponseApi[]>((resolve, reject) => {
        this.userService.getUserPurchases().pipe(untilDestroyed(this)).subscribe({
            next: (items) => {
              this.logger.debug('CollectionsMenuComponent -> GetCollections(): Success', items);
              resolve(items);
            },
            error: (error: HttpErrorResponse) => {
              this.logger.error('PurchasesComponent -> GetPurchases(): Failed', error);
              reject(error);
            }
        });
    });
  }

  private getFeatures() {
    this.featureToggleData = this.featureFlagsService.features;
    this.features = this.featureFlagsService.currentFeaturesValue;
  }
}

/* tslint:disable */
/* eslint-disable */

/**
 * list of platforms on license is applied
 */
export enum PlatformsApi {
  Android = 'android',
  Androidtv = 'androidtv',
  Ios = 'ios',
  Smarttv = 'smarttv',
  Web = 'web',
  Roku = 'roku'
}

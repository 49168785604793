<ty-galgo-epg *ngIf="hasEPG else grid"
              [navigableId]="0"
              class="epg"></ty-galgo-epg>

<ng-template #grid>
  <div id="lives-menu">
    <h4>{{ title | translate }}</h4>
    <div class="black-gradient-overlay"></div>
    <div class="grid-overflow-hidden"
         #lives>
      <ty-grid class="grid"
               *ngIf="mediaItems?.length && this.isDataReceived"
               [items]="mediaItems"
               [navigableId]="0"
               [rowItems]="3"
               (updateScroll)="updateScroll()">
      </ty-grid>
    </div>
  </div>
</ng-template>
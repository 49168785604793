<section class="detail-info-wrapper"
         id="detail-info-wrapper"
         appNavigation
         [navigableId]="0">
  <div id="detail-info">
    <dl class="detail-info-section detail-info-section__letf">
      <div class="detail-info-section__item"
           *ngIf="audios?.length">
        <dt>{{'details.audios' | translate }}</dt>
        <dd *ngFor="let audio of audios">
          {{audio.label}}
        </dd>
      </div>
      <div class="detail-info-section__item"
           *ngIf="translatedSubtitles?.length">
        <dt>{{'details.subtitles' | translate }}</dt>
        <dd *ngFor="let subtitle of translatedSubtitles">
          {{subtitle | async}}
        </dd>
      </div>
      <div class="detail-info-section__item"
           *ngIf="originalTitle">
        <dt>{{'details.originalTitle' | translate }}</dt>
        <dd>{{ originalTitle }}</dd>
      </div>
      <div class="detail-info-section__item"
           *ngIf="otherTitle">
        <dt>{{'details.otherTitle' | translate }}</dt>
        <dd>{{ otherTitle }}</dd>
      </div>
      <div class="detail-info-section__item"
           *ngIf="contentType">
        <dt>{{ contentType.title }}</dt>
        <dd>{{ contentType.value }}</dd>
      </div>
      <div class="detail-info-section__item"
           *ngIf="formattedTags">
        <dt>{{ 'details.labels' | translate }}</dt>
        <dd>{{ formattedTags }}</dd>
      </div>
    </dl>
    <dl class="detail-info-section detail-info__right">
      <div class="detail-info-section__item"
           *ngIf="platformCollection">
        <dt>{{ platformCollection.title }}</dt>
        <dd>{{ platformCollection.value }}</dd>
      </div>
      <div class="detail-info-section__item"
           *ngIf="producer">
        <dt>{{ producerLabel}}</dt>
        <dd>{{ producer }}</dd>
      </div>
      <div class="detail-info-section__item"
           *ngIf="countries?.length">
        <dt>{{ countries[0].title }}</dt>
        <dd *ngFor="let country of countries">
          {{ country.value }}
        </dd>
      </div>
      <div class="detail-info-section__item detail-info-section__item--participants"
           *ngIf="participants?.length">
        <ng-container *ngFor="let participant of participants">
          <dt>{{ participant.personalRole.name }}</dt>
          <dd *ngFor="let person of participant.persons">
            {{ person.complete }}
          </dd>
        </ng-container>
      </div>
      <div class="detail-info-section__item"
           *ngIf="rightsOwnership">
        <dt>{{ rightsOwnership.title }}</dt>
        <dd>{{ rightsOwnership.value }}</dd>
      </div>
    </dl>
  </div>
</section>
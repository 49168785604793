/* tslint:disable */
/* eslint-disable */
import { Injectable, Provider } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

/**
 * Global configuration
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ApiConfiguration implements ApiConfigurationParams {
  rootUrl?: string = '';
  catalog$: Observable<string>;
  update(params: ApiConfigurationParams): void{
    this.rootUrl = params?.rootUrl;
    params.catalog$.pipe(untilDestroyed(this)).subscribe((catalog: string) => {
      this.rootUrl = catalog;});
  }
}

/**
 * Parameters for `ApiModule.forRoot()`
 */
export interface ApiConfigurationParams {
  config?: Provider;
  rootUrl?: string;
  catalog$?: Observable<string>;
}

import { IconList } from '@core/models/icon-list.enum';

export const videoConfig = {
  fadeOutTitleDuration: 20000,
};

export const logoConfig = {
  image: IconList.appLogo,
  showTitle: false
};

export const buttonsPlayerSelector = 'button-icon-';

export const durationInterval = 5000;

export const liveThreshold = 30;

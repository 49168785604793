import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogService } from '@app/auth/services/catalog/catalog.service';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { HomeService } from '@app/core/services/home.service';
import { LanguageService } from '@app/core/services/language.service';
import { AdvertisementApi } from '@app/galgo-api/models';
import { IVideoApi, Video } from '@app/shared/models/video/video.model';
import { CacheService } from '@app/shared/services/cache.service';
import { Container, IContainer } from '@core/models/container.model';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Carousel } from '@shared/features/carousel/model/carousel.model';
import { NGXLogger } from 'ngx-logger';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-home',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: HomePageComponent }],
})
export class HomePageComponent extends NavigationComponent implements OnInit {
  carousels: Carousel[];
  containerId: string;
  customCarousels: Carousel[];
  featuredMediaItems: MediaItem<MediaItemTypes>[];
  isHidden: boolean;
  isLoading: boolean;
  selectedItem: MediaItem<MediaItemTypes>;
  showThumbnailInformation: boolean;

  constructor(
    public logger: NGXLogger,
    private cacheService: CacheService,
    private catalogService: CatalogService,
    private homeService: HomeService,
    private browsingHistoryService: BrowsingHistoryService,
    private featureFlagsService: FeatureFlagsService,
    private languageService: LanguageService,
    private router: Router,

    public el: ElementRef
  ) {
    super(el);
    this.carousels = [];
    this.containerId = '';
    this.customCarousels = [];
    this.isHidden = false;
    this.showThumbnailInformation = false;
  }

  get currentUrl(): string {
    return this.browsingHistoryService.currentURL;
  }

  ngOnInit(): void {
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData?.heroBanner){
      this.selectChildNodeById(0);
      this.browsingHistoryService.removeAllFocusData();
    } else
    if (focusData?.viewAggregator) {
      this.selectChildNodeById(1);
    }
    this.getContainers();
    this.selectedItem = null;
    this.checkCatalogs();
  }

  setSelectedItem(event: MediaItem<MediaItemTypes>) {
    this.selectedItem = event;
    this.showThumbnailInformation = true;
    this.isHidden = true;
  }

  onUpKey(): boolean {
    this.selectPreviousChildNode();
    if(this.featuredMediaItems && this.featuredMediaItems.length > 0  && this.getSelectedChildNodeId() === 0) {
      this.showThumbnailInformation = false;
      this.isHidden = false;
    }
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onBackKey(): boolean {
    if(this.featureFlagsService?.currentFeaturesValue?.haveCatalogs){
      this.router.navigate([AppRoutes.catalogSelector]);
    }else{
      if(this.currentUrl === AppRoutes.home){
        this.router.navigate([AppRoutes.exitApp]);
      }else{
        this.browsingHistoryService.goBack();}
    }
    return true;
  }

  checkCatalogs(): void {
    if(this.featureFlagsService.currentFeaturesValue.haveCatalogs && this.catalogService.getImageCatalog() == null){
      this.router.navigate([AppRoutes.catalogSelector]);
    }
  }

  getContainers(): void {
    this.containerId = this.currentUrl.substring(this.currentUrl.lastIndexOf('/') + 1);
    const homeParams = {
      id: this.containerId,
      version: 2,
      page: 1,
      size: 100,
      pv: 1,
      sv: 25,
      language: this.languageService.currentLang,
    };

    const customParams = { language: this.languageService.currentLang };

    this.homeService
      .getHomeContainers(homeParams, customParams)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([homeContainer, customCarousels]: [Container, Carousel[]]) => {
          const indexKeyToSort = 'position';
          const homeCarusels = this.processHomeContainers(homeContainer);
          this.customCarousels = customCarousels;
          if(this.homeService.customCarouselsError){
            this.cacheService.clearCache();
          }
          this.formatPositionOrder(
            homeCarusels,
            customCarousels,
            indexKeyToSort
          );
          this.logger.debug('HomeComponent -> GetContainers(): Success', [
            homeContainer,
            customCarousels,
          ]);
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error('HomeComponent -> GetContainers(): Failed', error);
        },
      });
  }

  processHomeContainers(homeContainer: Container) {

    if (homeContainer.heroBanner.length > 0) {
      this.getHeroBannerVideos(homeContainer);
    }

    let homeCarousels: Carousel[] = [];
    if (!!homeContainer?.subContainers?.length) {
      homeCarousels = homeContainer.subContainers.map(
        (carousel: IContainer) => new Carousel(carousel)
      );

      return this.getCarousels(homeCarousels);
    }
    return [];
  }

  private getHeroBannerVideos(homeContainer: Container) {
    this.featuredMediaItems = homeContainer.heroBanner
      .filter((element) => element.itemType !== 'Advertisement')
      .map((videoItem: IVideoApi) => new Video(videoItem));

      //filtro para remover directo
      if(this.featureFlagsService.currentFeaturesValue.filterLives){
      this.featuredMediaItems = this.featuredMediaItems.filter((element) => element.analytics.live !== true);
      }
  }

  private getCarousels(
    carousels: (AdvertisementApi | Carousel)[]) {
      const arrayToSave: Carousel[] = [];
    carousels.forEach((carousel: Carousel) => {
      if (carousel.hasVideos || carousel.hasCollections) {
        arrayToSave.push(carousel);
      }
    });
    return arrayToSave;
  }

  /**
   * Sorts the source array to insert via am especified key as index. Then inserts the items in the container by position
   *
   * @param carousels {@link Carousel}
   * @param arrayToInsert {@link Carousel}
   * @param indexKeyToFormatBy {@link string}
   * @returns carousel {@link Carousel}
   */
  private formatPositionOrder(
    carousels: Carousel[],
    arrayToInsert: Carousel[],
    indexKeyToFormatBy: string
  ){
    const userSegments = arrayToInsert.filter(
      (carousel) => carousel[indexKeyToFormatBy] === null
    );
    arrayToInsert = arrayToInsert.filter(
      (carousel) => carousel[indexKeyToFormatBy] !== null
    );

    arrayToInsert = arrayToInsert.sort(
      (a, b) => a[indexKeyToFormatBy] - b[indexKeyToFormatBy]
    );

    arrayToInsert.forEach((carousel: Carousel) => {
      if (!carousels.includes(carousel)) {
        carousels.splice(carousel[indexKeyToFormatBy], 0, carousel);
      }
    });
    this.carousels =  [...userSegments, ...carousels];
  }

}

<div id="selector">
  <div #chapterWrapper
       class="selector-wrapper">
    <div *ngFor="let chapter of chapters; let chapterNumber = index;">
      <div class="chapter"
           appNavigation
           [navigableId]="chapterNumber"
           (click)="playChapter(chapter)">
        <div class="thumb">
          <img #chapterImage
               [src]="getThumbnail(chapter)"
               (error)="setDefaultLandscape(chapterNumber)"
               [alt]="chapter?.title">
          <div *ngIf="getVideoProgressPercentage(chapter) !== 0"
               class="progress-marker">
            <div class="progress-marker__container">
              <div class="progress-marker__line"
                   [class.progress-marker__line--right-border]="getVideoProgressPercentage(chapter) === 1"
                   [style.width]="(getVideoProgressPercentage(chapter) * 100) + '%'"></div>
            </div>
          </div>
          <div class="underline-active-item"></div>
        </div>
        <div class="info">
          <div class="info__header">
            <span class="title">{{chapterNumber + 1 }}. {{chapter?.title}}</span>
            <span class="duration">{{duration(chapter)}}</span>
          </div>
          <span class="description">{{chapter?.shortDescription}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
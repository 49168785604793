
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IIconSource } from '@shared/models/button/icon-source.interface';

@Component({
  selector: 'ty-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ButtonComponent }]
})
export class ButtonComponent extends NavigationComponent implements OnInit {

  @Input() text?: string;
  @Input() icon?: IIconSource;
  @Input() initialPosition: boolean;
  @Input() selectorId?: string;
  isHover: boolean;
  private _iconName: string;

  public get iconName(): string {
    return './assets/icons/' + this._iconName + '#icon';
  }
  public set iconName(value: string) {
    this._iconName = value;
  }

  constructor(
    public el: ElementRef
   ) {
    super(el);
   }

  ngOnInit(): void {
    this.iconName = this.icon?.unfilled;
  }

  focus() {
    setTimeout(() => {
      this.iconName = this.icon?.filled;
    }, 0);
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  blur() {
    if (document.getElementById(this.selectorId) !== document.activeElement) {
      setTimeout(() => {
        this.iconName = this.icon?.unfilled;
      }, 0);
    }
  }

}

import { ISubscriptionResult } from '@app/auth/models/check-subscription.interface';
import { SubscriptionResults } from '@app/auth/models/subscription-results.enum';
import { ActionList } from '@core/models/action-list.enum';
import { IconList } from '@core/models/icon-list.enum';
import { IButtonConfig } from '@shared/components/button-list/button-config.interface';
import { IDetailConfig } from '@shared/components/detail/detail-config';
import { IIconSource } from '@shared/models/button/icon-source.interface';

export const videoDetailBtnListConfig: IButtonConfig[] = [
  {
    icon: {
      unfilled: IconList.playIconUnfilled,
      filled: IconList.playIcon
    },
    text: 'buttons.play',
    action: ActionList.play,
    active: true,
    disable: true,
    initialPosition: true
  }
];

export const mySpaceButtonIcons: IIconSource[] = [
  {
  unfilled: IconList.addMySpaceIconUnfilled,
  filled: IconList.addMySpaceIcon
  },
  {
  unfilled: IconList.removeMySpaceIconUnfilled,
  filled: IconList.removeMySpaceIcon
  }
];

export const videoDetailConfig: IDetailConfig =
{
    showDescription: true
};

export const subscriptionConfig: ISubscriptionResult =
{
    result: false,
    reason: SubscriptionResults.noSubscription
};

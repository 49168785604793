/* eslint-disable rxjs/no-ignored-error */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationNode, NavigationType } from './models/navigation.types';
import { NavigationDirective } from './navigation.directive';

@UntilDestroy()
@Component({
  template: '',
})
export class NavigationComponent implements AfterViewInit {
  @Input() navigableId: number;
  @Input() navigable: boolean;

  @ViewChildren(NavigationDirective)
  private subDirectivesQL!: QueryList<NavigationNode>;
  @ViewChildren(NavigationComponent)
  private subComponentsQL!: QueryList<NavigationNode>;

  private type: NavigationType;
  private childNodes: NavigationNode[];
  private selectedChildNodeId: number;
  private active: boolean;
  private defaultActiveChildNodeId: number;
  private firstChildNodes: boolean;
  private parentNode: NavigationComponent;

  constructor(public el: ElementRef) {
    this.el = el;
    this.navigableId = -1;
    this.navigable = true;
    this.type = NavigationType.component;
    this.childNodes = [];
    this.selectedChildNodeId = -1;
    this.active = false;
    this.defaultActiveChildNodeId = -1;
    this.firstChildNodes = false;
    this.parentNode = undefined;
  }

  ngAfterViewInit(): void {
    this.afterChildNodesChanged();
    this.subDirectivesQL.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.afterChildNodesChanged();
    });
    this.subComponentsQL.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.afterChildNodesChanged();
    });
  }

  onChildNodesUpdated(): void {}
  onActivate(): void {}
  onDeactivate(): void {}
  onChangeSelectedChildNode(): void {}
  onFirstChildNodes(): void {}

  getType(): NavigationType {
    return this.type;
  }

  appendChildNode(node: NavigationNode): void {
    if (node.navigable) {
      this.childNodes.push(node);
    }
    this.configureParentNode();
    this.onChildNodesUpdated();
    this.selectAfterChildNodesUpdated();
  }

  popChildNode(): void {
    this.childNodes.pop();
  }

  getChildNodes(): NavigationNode[] {
    return this.childNodes;
  }

  hasChildNodes(): boolean {
    return this.childNodes.length > 0;
  }

  setParentNode(node: NavigationComponent) {
    this.parentNode = node;
  }

  getParentNode(): NavigationComponent {
    return this.parentNode;
  }

  getChildNodeById(id: number): NavigationNode | undefined {
    return this.childNodes.find(node => node.navigableId === id);
  }

  getChildNodeByPosition(position: number): NavigationNode | undefined {
    return this.childNodes[position];
  }

  getChildNodeIndexById(id: number): number {
    return this.childNodes.findIndex(childNode => childNode.navigableId === id);
  }

  hasFocusableElementsOnBranch(): boolean {
    return this.childNodes.some(
      childNode =>
        childNode.getType() === NavigationType.directive ||
        (childNode as NavigationComponent).hasFocusableElementsOnBranch()
    );
  }

  setDefaultActiveChildNodeId(id: number): void {
    this.defaultActiveChildNodeId = id;
  }

  selectChildNodeById(id: number): boolean {
    const targetNode = this.getChildNodeById(id);
    if (targetNode) {
      const isSameId = this.selectedChildNodeId === targetNode.navigableId;
      if (this.active) {
        this.getSelectedChildNode()?.deactivate();
        this.selectedChildNodeId = targetNode.navigableId;
        this.getSelectedChildNode()?.activate();
        if (!isSameId) {
          this.onChangeSelectedChildNode();
        }
        return true;
      } else {
        this.selectedChildNodeId = targetNode.navigableId;
        if (!isSameId) {
          this.onChangeSelectedChildNode();
        }
        return true;
      }
    } else if (this.selectedChildNodeId < 0 && this.childNodes.length === 0) {
      this.selectedChildNodeId = id;
      return false;
    }
    return false;
  }

  selectChildNodeByPosition(position: number): boolean {
    const targetNode = this.getChildNodeByPosition(position);
    if (targetNode) {
      const isSameId = this.selectedChildNodeId === targetNode.navigableId;
      if (this.active) {
        this.getSelectedChildNode()?.deactivate();
        this.selectedChildNodeId = targetNode.navigableId;
        this.getSelectedChildNode()?.activate();
        if (!isSameId) {
          this.onChangeSelectedChildNode();
        }
        return true;
      } else {
        this.selectedChildNodeId = targetNode.navigableId;
        if (!isSameId) {
          this.onChangeSelectedChildNode();
        }
        return true;
      }
    }
    return false;
  }

  selectNextChildNode(): boolean {
    const actualIndex = this.getChildNodeIndexById(this.selectedChildNodeId);
    if (actualIndex > -1) {
      const targetIndex = actualIndex + 1;
      if (targetIndex < this.getChildNodes().length) {
        this.selectChildNodeByPosition(targetIndex);
        return true;
      }
    }
    return false;
  }

  selectPreviousChildNode(): boolean {
    const actualIndex = this.getChildNodeIndexById(this.selectedChildNodeId);
    if (actualIndex > -1) {
      const targetIndex = actualIndex - 1;
      if (targetIndex >= 0) {
        this.selectChildNodeByPosition(targetIndex);
        return true;
      }
    }
    return false;
  }

  selectBranch(id: number[]): boolean {
    if (this.existsBranch([...id])) {
      const targetId = id.shift();
      this.selectChildNodeById(targetId);
      const targetNode = this.getSelectedChildNode();
      if (targetNode && targetNode.getType() === NavigationType.component) {
        (targetNode as NavigationComponent).selectBranch(id);
      }
      return true;
    }
    return false;
  }

  existsBranch(id: number[]): boolean {
    const targetId = id.shift();
    if (targetId !== undefined) {
      const targetNode = this.getChildNodeById(targetId);
      if (targetNode) {
        if (targetNode.getType() === NavigationType.component) {
          if ((targetNode as NavigationComponent).existsBranch(id)) {
            return true;
          }
        } else if (id.length === 0) {
          return true;
        }
      }
    }
    return false;
  }

  getSelectedBranch(): number[] {
    const selectedChildNode = this.getSelectedChildNode();
    if (selectedChildNode && selectedChildNode.getType() === NavigationType.component) {
      const branch = (selectedChildNode as NavigationComponent).getSelectedBranch();
      return [this.selectedChildNodeId].concat(branch);
    }
    return [this.selectedChildNodeId];
  }

  getSelectedChildNodeId(): number {
    return this.selectedChildNodeId;
  }

  getSelectedChildNode(): NavigationNode | undefined {
    return this.getChildNodeById(this.selectedChildNodeId);
  }

  getSelectedChildNodeIndex(): number {
    return this.childNodes.findIndex(
      childNode => childNode.navigableId === this.selectedChildNodeId
    );
  }

  setRootNode(): void {
    this.activate();
  }

  isActive(): boolean {
    return this.active;
  }

  activate(): void {
    this.active = true;
    if (this.defaultActiveChildNodeId > -1) {
      this.selectedChildNodeId = this.defaultActiveChildNodeId;
    }
    this.getSelectedChildNode()?.activate();
    this.onActivate();
  }

  deactivate(): void {
    this.active = false;
    this.getSelectedChildNode()?.deactivate();
    this.onDeactivate();
  }

  getSelectedChildComponent(): NavigationComponent {
    const activeSubElement = this.getSelectedChildNode();
    if (activeSubElement && activeSubElement.getType() === NavigationType.component) {
      return activeSubElement as NavigationComponent;
    }
    return null;
  }

  resetChildNodes(): void {
    if (this.isActive()) {
      this.getSelectedChildNode()?.deactivate();
      this.resetChildNodesToFirstPosition();
      this.getSelectedChildNode()?.activate();
    } else {
      this.resetChildNodesToFirstPosition();
    }
  }

  emitMessage(message: any): void {
    if (this.parentNode) {
      if (!this.parentNode.handleChildrenMessage(message)) {
        this.parentNode.emitMessage(message);
      }
    }
  }

  handleUpKey(): boolean {
    return this.getSelectedChildComponent()?.handleUpKey() || this.onUpKey();
  }
  handleRightKey(): boolean {
    return this.getSelectedChildComponent()?.handleRightKey() || this.onRightKey();
  }
  handleDownKey(): boolean {
    return this.getSelectedChildComponent()?.handleDownKey() || this.onDownKey();
  }
  handleLeftKey(): boolean {
    return this.getSelectedChildComponent()?.handleLeftKey() || this.onLeftKey();
  }
  handleEnterKey(): boolean {
    return this.getSelectedChildComponent()?.handleEnterKey() || this.onEnterKey();
  }
  handleBackKey(): boolean {
    return this.getSelectedChildComponent()?.handleBackKey() || this.onBackKey();
  }
  handlePlayPauseKey(): boolean {
    return this.getSelectedChildComponent()?.handlePlayPauseKey() || this.onPlayPauseKey();
  }
  handlePlayKey(): boolean {
    return this.getSelectedChildComponent()?.handlePlayKey() || this.onPlayKey();
  }
  handlePauseKey(): boolean {
    return this.getSelectedChildComponent()?.handlePauseKey() || this.onPauseKey();
  }
  handleStopKey(): boolean {
    return this.getSelectedChildComponent()?.handleStopKey() || this.onStopKey();
  }
  handleRewindKey(): boolean {
    return this.getSelectedChildComponent()?.handleRewindKey() || this.onRewindKey();
  }
  handleFastForwardKey(): boolean {
    return this.getSelectedChildComponent()?.handleFastForwardKey() || this.onFastForwardKey();
  }
  handleKeyPress(code: any): boolean {
    return this.getSelectedChildComponent()?.handleKeyPress(code) || this.onKeyPress(code);
  }
  handleChildrenMessage(message: any): boolean {
    return false;
  }

  onUpKey(): boolean {
    return false;
  }
  onRightKey(): boolean {
    return false;
  }
  onDownKey(): boolean {
    return false;
  }
  onLeftKey(): boolean {
    return false;
  }
  onEnterKey(): boolean {
    return false;
  }
  onBackKey(): boolean {
    return false;
  }
  onPlayPauseKey(): boolean {
    return false;
  }
  onPlayKey(): boolean {
    return false;
  }
  onPauseKey(): boolean {
    return false;
  }
  onStopKey(): boolean {
    return false;
  }
  onRewindKey(): boolean {
    return false;
  }
  onFastForwardKey(): boolean {
    return false;
  }
  onKeyPress(code: any): boolean {
    return false;
  }

  getOffsetTop(): number {
    return (this.el.nativeElement as HTMLElement).offsetTop;
  }
  getOffsetLeft(): number {
    return (this.el.nativeElement as HTMLElement).offsetLeft;
  }
  getLeafOffsetTop(): number {
    const activeSubElement = this.getSelectedChildNode();
    return this.getOffsetTop() + (activeSubElement ? activeSubElement.getLeafOffsetTop() : 0);
  }
  getLeafOffsetLeft(): number {
    const activeSubElement = this.getSelectedChildNode();
    return this.getOffsetLeft() + (activeSubElement ? activeSubElement.getLeafOffsetLeft() : 0);
  }

  isInsideViewport(): boolean {
    const rect = this.el.nativeElement.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private afterChildNodesChanged(): void {
    const subDirectives = this.subDirectivesQL.toArray();
    const subComponents = this.subComponentsQL.toArray();
    this.childNodes = subDirectives
      .concat(subComponents)
      .filter(item => item.navigable)
      .sort((a, b) => a.navigableId - b.navigableId);
    if (!this.firstChildNodes && this.childNodes.length > 0) {
      this.firstChildNodes = true;
      this.onFirstChildNodes();
    }
    this.configureParentNode();
    this.onChildNodesUpdated();
    this.selectAfterChildNodesUpdated();
  }

  private configureParentNode(): void {
    this.childNodes.forEach(childNode => {
      childNode.setParentNode(this);
    });
  }

  private selectAfterChildNodesUpdated(): void {
    if (!this.selectChildNodeById(this.selectedChildNodeId)) {
      this.selectChildNodeByPosition(0);
    }
  }

  private resetChildNodesToFirstPosition(): void {
    this.getChildNodes().forEach(childNode => {
      if (childNode.getType() === NavigationType.component) {
        (childNode as NavigationComponent).resetChildNodesToFirstPosition();
      }
    });
    this.selectChildNodeByPosition(0);
  }
}

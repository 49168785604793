import { CategoryFrontApi, LayoutPaymentFrontApi } from '@api/models';
import { Container, IContainer } from '@core/models/container.model';
import { Layout } from '@core/models/layout.model';
import { IMediaItem, MediaItem } from '@core/models/media-item.model';
import { ItemTypes } from '../item-types.enum';
import { Video } from '../video/video.model';
import { CollectionTypes } from './collection-types.enum';

export class Collection extends Container implements IMediaItem {
  private _categories: CategoryFrontApi[];
  private _collectionType: string;
  private _featured: boolean;
  private _layout: Layout;
  private _ranking: number;
  private _isFavourite: boolean;
  private _subscription: LayoutPaymentFrontApi;
  private _hasSubscription: boolean;
  private _heroBannerSeconds: number;
  private _isTitle: boolean;

  get collectionType(): string {
    return this._collectionType;
  }

  get heroBannerSeconds(): number {
    return this._heroBannerSeconds;
  }

  get isTitle(): boolean {
    return this._isTitle;
  }

  get isFeatured(): boolean {
    return this._featured;
  }

  get categories(): CategoryFrontApi[] {
    return this._categories;
  }

  get numOfChapters(): number {
    if (this.isSerie()) {
      return this.countAllChapters();
    }

    return this.videos?.length;
  }

  get numOfSeasons(): number {
    return this.subContainers?.length;
  }

  get numOfActiveSeasons(): number {
    return this.subContainers?.filter((season: Collection) => season.numOfChapters > 0)
      ?.length;
  }

  get firstVideo(): MediaItem<Video> {
    if (this.isSerie()) {
      const season = this.firstActiveSeason;
      return season?.firstVideo;
    }
    if (this.videos?.length) {
      return this.videos[0];
    }

    return undefined;
  }

  get firstActiveSeason(): Collection {
    return this.getActiveSeasons()?.[0];
  }

  get layout(): Layout {
    return this._layout;
  }

  get ranking(): number {
    return this._ranking;
  }

  get isFavourite(): boolean {
    return this._isFavourite;
  }

  set isFavourite(value: boolean) {
    this._isFavourite = value;
  }

  get subscription(): LayoutPaymentFrontApi {
    return this._subscription;
  }

  set hasSubscription(value: boolean) {
    this._hasSubscription = value;
  }

  get hasSubscription(): boolean {
    return this._hasSubscription;
  }

  get type() {
    return ItemTypes.Collection;
  }

  constructor(init?: IContainer, position?: number) {
    super(init);
    if (init) {
      this._collectionType = init?.containerType;
      this._categories = init?.categories;
      this._layout = new Layout(init?.layout);
      this._ranking = init?.ranking;
      this._subscription = init.layout?.subscription;
      this._hasSubscription = false;
      this._isTitle = false;
    }
  }

  isSerie(): boolean {
    return this._collectionType?.includes(CollectionTypes.serie);
  }

  isDefault(): boolean {
    return this._collectionType?.includes(CollectionTypes.default);
  }

  isSeason(): boolean {
    return this._collectionType?.includes(CollectionTypes.season);
  }

  isChannel(): boolean {
    return this._collectionType?.includes(CollectionTypes.channel);
  }

  getSeason(season: number): Collection {
    return new Collection(this.subContainers[season - 1]);
  }

  getSeasons(): Collection[] {
    return this.subContainers;
  }

  getActiveSeasons(): Collection[] {
    return this.subContainers?.map((container: IContainer) => new Collection(container))
    .filter((season: Collection) => season.numOfChapters > 0)
    ;
  }

  getChaptersFromSeason(season: number): MediaItem<Video>[] {
    return this.subContainers[season - 1]?.videos;
  }

  getAllChapters(): MediaItem<Video>[] {
    let allVideos: MediaItem<Video>[] = [];
    if (this.isSerie()) {
      this.getSeasons().forEach((season: Collection) => {
        season.videos.forEach((video: MediaItem<Video>) => {
          allVideos?.push(video);
        });
      });
    }
    if (this.isSeason()) {
      allVideos = this.videos;
    }
    return allVideos;
  }

  getAllChaptersAsVideoId(): string[] {
    return this.getAllChapters().map((chapter: Video) => chapter.id);
  }

  checkChaptersStartSecondPlaying(): boolean {
    return this.getAllChapters().some((chapter: Video) => chapter.startSecond);
  }

  private countAllChapters() {
    return this.subContainers.reduce((total: number, collection: Collection) => {
      if (collection?.videos?.length) {
        return (total += collection?.videos?.length);
      }
      return total;
    }, 0);
  }

  private formatSubcontainers(subcontainers: any[]) {
    if (this.isSerie()) {
      const seasons = subcontainers.map(
        (container: IContainer, index) => new Collection(container, index)
      );
      return seasons;
    }

    return subcontainers;
  }
}

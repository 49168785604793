<div [ngClass]="{'menu-closed': !isSideMenuOpened, 'menu-minimized': isMinimized, 'menu-opened': isSideMenuOpened}"
     class="menu"
     (mouseleave)="closeMenu()"
     (mouseenter)="isMouseOver = true">

  <a *ngIf="!hideLogo"
     [routerLink]="'/pages/home'"
     routerLinkActive="active"
     [ngClass]="{'logo-full-link-hidden': !isMinimized, 'logo-full-link-minimized': isMinimized}"
     class="logo-full-link">
    <div class="logo-wrapper">
      <ty-logo [logo]="appLogo"
               [showTitle]="showTitle"></ty-logo>
    </div>
  </a>

  <div class="menu-items-wrapper">
    <div *ngFor="let item of items; let index = index ">
      <ty-menu-item *ngIf="shouldDisplayItem(item)"
                    [item]="item"
                    appNavigation
                    [navigableId]="item.position"
                    [routerLink]="item.routerLink"
                    [currentRouteActive]="routeActive"
                    [ngClass]="{'menu-item-hidden': !isMinimized, 'menu-item-minimized': isMinimized}"
                    class="menu-item"></ty-menu-item>
    </div>
  </div>
  <div id="menu-background"></div>
</div>
<div class="menu-opener"
     *ngIf="!isSideMenuOpened"
     (mouseover)="openMenu()"></div>
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TvCodeApi } from '@api/models';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { IAuthToken } from '@app/auth/models/token.model';
import { User } from '@app/auth/models/user.model';
import { CodeService } from '@app/auth/services/smarttv-code/code.service';
import { UserService } from '@app/auth/services/user/user.service';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { LanguageService } from '@app/core/services/language.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { CacheService } from '@app/shared/services/cache.service';
import { CustomerDataService } from '@core/services/customer-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Subscription, interval, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { default as appInfo } from '../../../../../package.json';
import { LoginPageConfig } from './login-page.config';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LoginPageComponent }]

})
export class LoginPageComponent extends NavigationComponent implements OnInit, OnDestroy{
  appRoutes: typeof AppRoutes;
  loginSubscription: Subscription;
  showBackButton: boolean;
  SSOMode: boolean;
  emailLoginEnabled: boolean;
  private loginTimeout: any;
  private _smartTVCode: string[];

  get qrURL(): string {
    return `${this.startUrl}?code=${this._smartTVCode.join('')}&k=${this.accessKey}`;
  }

  get startUrl(): string {
    return `${this.customerDataService.webBaseUrl}/${LoginPageConfig.startUrl}`;
  }

  get smartTVCode(): string[] {
    return this._smartTVCode;
  }
  get accessKey(): string {
    return this.customerDataService?.accessKey;
  }

  get version(): string {
    return appInfo.version;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private analyticsService: AnalyticsService,
    private codeService: CodeService,
    private customerDataService: CustomerDataService,
    private cacheService: CacheService,
    private featureFlagsService: FeatureFlagsService,
    private router: Router,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private userService: UserService,
    public logger: NGXLogger,
    public el: ElementRef
  ) {
    super(el);
    this.appRoutes = AppRoutes;
    this.showBackButton = this.featureFlagsService?.currentFeaturesValue?.showBackButton;

    setTimeout(() => {
      this.emailLoginEnabled = this.customerDataService?.emailLoginEnabled;
      this.SSOMode = !!this.customerDataService?.sso;
    }, 10);

    if (this.userService.currentUser) {
      this.goTo(this.appRoutes.home);
    }

    this._smartTVCode = [];
    this.initSubscriptionCodeLogin();
    if (this.SSOMode || this.emailLoginEnabled) {
      this.setDefaultActiveChildNodeId(2);
    } else{
      this.setDefaultActiveChildNodeId(1);
    }

  }

  ngOnInit(): void {
    this.generateSmartTVCode();
    this.loginTimeout = setTimeout(() => {
      this.onBackKey();
    }, 5 * 60 * 1000);//5 minutes to return to the onboarding
  }

  ngOnDestroy(): void {
    if (this.loginTimeout) {
      clearTimeout(this.loginTimeout);
    }
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onUpKey(): boolean {
    this.selectChildNodeById(0);
    return true;
  }

  onDownKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
      this.selectNextChildNode();
    }
    return true;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() === 1) {
      this.selectNextChildNode();
      clearTimeout(this.loginTimeout);

      this.loginTimeout = setTimeout(() => {
        this.onBackKey();
        }, 5 * 60 * 1000);
    }
    return true;
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() === 2 && !this.SSOMode || !this.emailLoginEnabled) {
      this.selectPreviousChildNode();
      clearTimeout(this.loginTimeout);

      this.loginTimeout = setTimeout(() => {
        this.onBackKey();
        }, 5 * 60 * 1000);
    }
    return true;
  }

  onBackKey(): boolean {
    this.router.navigate([AppRoutes.onboarding]);
    return true;
  }

  goTo(route: string): void {
    this.router.navigate([route]);
  }

  generateSmartTVCode() {
    this.codeService.postSmartTVCode()
      .pipe(untilDestroyed(this)).subscribe(
        (code: TvCodeApi) => {
          this._smartTVCode = code.code.split('');
          this.logger.debug('LoginPageComponent -> GenerateSmartTVCode: Success', code);
        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'LoginPageComponent -> GenerateSmartTVCode(): Failed',
            error
          );
          throw new Error('Error obtaining SmartTV Code');
        }
      );
  }

  private initSubscriptionCodeLogin() {
    const loginInterval = interval(LoginPageConfig.refreshLogin);

    this.loginSubscription = loginInterval.pipe(untilDestroyed(this)).subscribe({
      next: () => this.loginWithCode(),
      error: (error: Error) => {
        this.logger.error('LoginPageComponent -> loginInterval(): Failed', error);
        return throwError(() => error);
      }
    });
  }

  private loginWithCode() {
    this.codeService
      .loginSmartTVCode(this._smartTVCode.join(''))
      .pipe(
        first(),
        mergeMap((token: IAuthToken) => {
          this.logger.debug('LoginPageComponent -> LoginWithCode: Success', token);
          return this.userService.getUserDataFromServer();
        }),
        catchError((error: HttpErrorResponse) => {
          this.logger.error('LoginPageComponent -> LoginWithCode: Failed', error);
          return throwError(() => new HttpErrorResponse(error));
        })
      )
      .subscribe({
        next: (user: User) => {
          this.logger.debug('LoginForm -> GetUserData: Success', user);
          this.analyticsService.logEvent(GalgoEvent.userLogin);
          this.setLanguage(user.favoriteLanguageCode);
          this.cacheService.clearCache();
          this.router.navigate(['/pages/home']);
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error('LoginForm -> GetUserData: Failed', error);
          return throwError(() => new HttpErrorResponse(error));
        }
      });

  }

  private setLanguage(id: string) {
    this.languageService.use(id);
    this.languageService.currentLang = id;
    this.document.documentElement.lang = id;
    this.settingsService.languageSet$.next();
  }
}

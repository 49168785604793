import { IModalData } from '@app/shared/models/modal-data.interface';
import { ModalActions } from './modal-actions.enum';

export const LOGOUT_MODAL_DATA: IModalData = {
  title: 'modal.logout.title',
  buttons: [
    {
      text: 'modal.logout.close',
      action: ModalActions.logout
    },
    {
      text: 'buttons.close',
      action: ModalActions.close
    }
  ]
};

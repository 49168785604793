<ty-details-descriptions class="left-wrap"
                         [detailDescription]="textDetails"
                         [navigableId]="0"></ty-details-descriptions>
<ty-details-info class="right-wrap"
                 [tags]="tags"
                 [participants]="participants"
                 [countries]="countryTags"
                 [platformCollection]="platformCollection"
                 [rightsOwnership]="rightsOwnershipTag"
                 [producer]="producer"
                 [producerLabel]="producerLabel"
                 [audios]="audios"
                 [subtitlesAvailable]="subtitlesAvailable"
                 [originalTitle]="originalTitle"
                 [navigableId]="1"
                 [ngClass]="{'hide-tags': !this.detailTabsFlag }">
</ty-details-info>
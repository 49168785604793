import { ModalActions } from '@app/shared/components/modal/config';
import { IModalData } from '@app/shared/models/modal-data.interface';

export const PLAYER_UNAVAILABLE_MODAL: IModalData = {
  title: 'modal.unavailableContent.title',
  content: 'modal.unavailableContent.text',
  buttons: [
    {
      text: 'buttons.return',
      action: ModalActions.close,
    },
  ],
};

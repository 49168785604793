<div class="header-wrapper">
  <ty-header-player [navigableId]="0"></ty-header-player>
</div>
<div class="button-container">
  <ty-button-player class="button play"
                    [config]="centralButton"
                    [navigableId]="2"></ty-button-player>
</div>
<div class="seekbar-container">

  <div class="top-components">
    <ty-list-selector-icon *ngIf="audioTracks?.length > 1 && !isLive"
                           class="list-selector-icon"
                           [itemTracks]="audioTracks"
                           [iconActive]="iconAudioActive"
                           [iconDefault]="iconAudioDefault"
                           [typeOfList]="audio"
                           (subtitledChanged)="changeAudio($event)"
                           [navigableId]="5"></ty-list-selector-icon>
    <ty-list-selector-icon *ngIf="textTracks?.length && !isLive"
                           class="list-selector-icon"
                           [itemTracks]="textTracks"
                           [iconActive]="iconSubtitleActive"
                           [iconDefault]="iconSubtitleDefault"
                           [typeOfList]="subtitle"
                           (subtitledChanged)="changeSubtitle($event)"
                           [navigableId]="6"></ty-list-selector-icon>
    <ty-button-player *ngIf="!isLive && true"
                      class="restart"
                      [config]="restartButton"
                      [navigableId]="7"></ty-button-player>
    <ty-button-player *ngIf="!isLive && showNextChapterButton"
                      class="nextChapter"
                      [config]="nextChapterButton"
                      [navigableId]="8"></ty-button-player>
  </div>
  <ty-seekbar class="seekbar-wrap"
              [navigableId]="4"></ty-seekbar>
</div>
<div class="scrim"></div>
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Layout } from '@app/core/models/layout.model';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { LanguageService } from '@app/core/services/language.service';
import { CreditsFrontApi, ErrorResponseApi, TagResponseApi, VideoTabLayoutApi } from '@app/galgo-api/models';
import { AudioFrontApi } from '@app/galgo-api/models/audio-front-api';
import { CollectionDetailService } from '@app/pages/protected/media-detail/services/collection-detail.service';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { CategoryTypeEnum } from '@app/shared/models/category-type.enum';
import { Collection } from '@app/shared/models/collection/collection.model';
import { Video } from '@app/shared/models/video/video.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getName, registerLocale } from 'i18n-iso-countries';
import ca from 'i18n-iso-countries/langs/ca.json';
import en from 'i18n-iso-countries/langs/en.json';
import es from 'i18n-iso-countries/langs/es.json';
import eu from 'i18n-iso-countries/langs/eu.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import gl from 'i18n-iso-countries/langs/gl.json';
import ja from 'i18n-iso-countries/langs/ja.json';
import pt from 'i18n-iso-countries/langs/pt.json';
import { NGXLogger } from 'ngx-logger';
import { RouteItemType } from '../more-information/config/routes-item-type.enum';
import { TabContentSelector } from '../tabs/models/tab-label.enum';


@Component({
  selector: 'ty-details-tabs',
  templateUrl: './details-tabs.component.html',
  styleUrls: ['./details-tabs.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: DetailsTabsComponent},
  ],
})
@UntilDestroy()
export class DetailsTabsComponent extends NavigationComponent implements OnInit {
  collectionType: string;
  contentId: string;
  countryTags: TagResponseApi[];
  detailTabsFlag: boolean;
  element: HTMLElement;
  element2: HTMLElement;
  layoutDetails: VideoTabLayoutApi;
  mediaItem: MediaItem<MediaItemTypes>;
  platformCollectionTag: TagResponseApi;
  rightsOwnershipTag: TagResponseApi;
  tags: TagResponseApi[];
  textDetails: string;

  constructor(
    public el: ElementRef,
    private collectionDetailService: CollectionDetailService,
    private featureFlagsService: FeatureFlagsService,
    private route: ActivatedRoute,
    private router: Router,
    private videoDetailService: VideoDetailService,
    public logger: NGXLogger,
    private settings: SettingsService,
    private languageService: LanguageService,
  ) {
    super(el);
    this.contentId = '';
    this.detailTabsFlag = this.featureFlagsService?.currentFeaturesValue?.detailsTab;
    // TODO: create a service to register locales
    registerLocale(ca);
    registerLocale(en);
    registerLocale(eu);
    registerLocale(es);
    registerLocale(fr);
    registerLocale(gl);
    registerLocale(ja);
    registerLocale(pt);
  }

  ngOnInit(): void {
    this.tags = [];
    this.getContentId();
    this.settings.lastView$ = TabContentSelector.details;
    this.element = this.getElement('details-descriptions');
    this.element2 = this.getElement('detail-info-wrapper');
  }

  get dateLabel(): string {
    return 'details.date';
  }

  get description(): string {
    return this.mediaItem?.description;
  }

  get labels(): string[] {
    const labels: string[] = [];
    this.mediaItem?.layout?.label?.forEach((label: TagResponseApi) => {
      labels.push(label.value);
    });
    return labels;
  }

  get layout(): Layout {
    return this.mediaItem?.layout;
  }

  get labelsLabel(): string {
    return 'details.labels';
  }

  get pegi(): string {
    return this.mediaItem?.layout?.pegi;
  }

  get pegiLabel(): string {
    return 'details.pegi';
  }

  get producer(): string {
    return this.mediaItem?.analytics?.producerName;
  }

  get producerLabel(): string {
    return 'details.producer';
  }

  get date(): number {
    return this.mediaItem?.date;
  }

  get shortDescription(): string {
    return this.mediaItem?.shortDescription;
  }

  get showLabels(): boolean {
    return !!this.labels?.length;
  }

  get topic(): string {
    return this.mediaItem?.layout?.topic.toString();
  }

  get longDescription(): string {
    return this.mediaItem?.description ?? '';
  }

  get historicalNote(): string {
    return this.mediaItem?.historicalNote ?? '';
  }

  get others(): TagResponseApi[] {
    return this.mediaItem?.layout?.other;
  }

  get participants(): CreditsFrontApi[] {
    return this.mediaItem?.participants;
  }

  get platformCollection(): TagResponseApi{
    return this.platformCollectionTag;
  }

  get audios(): Array<AudioFrontApi>{
    return this.mediaItem?.audios;
  }

  get subtitlesAvailable(): Array<string> {
    return this.mediaItem?.extraData?.subtitlesAvailable;
  }

  get originalTitle(): string {
    return this.mediaItem?.originalTitle;
  }

  get otherTitle(): string {
    return this.mediaItem?.extraData?.otherTitle;
  }

  get subgenere(): TagResponseApi {
    return this.mediaItem?.subgenre;
  }

  get contentType(): TagResponseApi {
    return this.mediaItem?.contentType;
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
      return false;
    }
    if(this.getSelectedChildNodeId() === 1){
    this.addClassSelected(this.element);
    this.removeClassSelected(this.element2);
    }
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
    this.addClassSelected(this.element2);
    this.removeClassSelected(this.element);
    }
    this.selectNextChildNode();
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onUpKey(): boolean {
    this.removeClassSelected(this.element);
    this.removeClassSelected(this.element2);
    return false;
  }


  concatTags(mediaItem: MediaItem<MediaItemTypes>): void {
    if (this.featureFlagsService.currentFeaturesValue.enableConcatTags) {
      if (mediaItem?.layout?.subgenre) {
        this.tags?.push(...mediaItem?.layout?.subgenre);
      }
    }
    if (mediaItem?.layout?.label) {
      this.tags?.push(...mediaItem?.layout?.label.values());
    }
  }

  getItem(categoryTipeId: number, mediaItem?: MediaItem<MediaItemTypes>): TagResponseApi {
      return mediaItem.layout.other.find(tag => tag.categoryTypeId === categoryTipeId);
  }

  getItems(categoryTypeId: number, mediaItem?: MediaItem<MediaItemTypes>): TagResponseApi[] {
    return mediaItem.layout.other.filter(tag => tag.categoryTypeId === categoryTypeId);
}

  private getContentId() {
    this.route.params.pipe(untilDestroyed(this)).subscribe(
      (params: Params) => {
        this.contentId = params.id;
        // TODO: refactor. Same function in SimilarComponent
        switch(this.router.url.split('/')[2]) {
          case RouteItemType.Collections:
            this.getCollectionDetails();
            break;
          case RouteItemType.Video:
            this.getVideoDetails();
            break;
          case RouteItemType.Series:
            this.getCollectionDetails();
            break;
        }
        this.logger.debug('MoreInformationComponent -> GetContentId(): Success', params);
      },
      (error: ErrorResponseApi) => {
        this.logger.error('MoreInformationComponent -> GetContentId(): Failed', error);
        this.router.navigate([AppRoutes.forbidden]);
      }
    );
  }

  private getCollectionDetails() {
    this.collectionDetailService
    .getCollectionDetails(this.contentId)
    .pipe(untilDestroyed(this))
    .subscribe(
      (response: Collection) => {
        this.mediaItem = response;
        this.collectionType = this.mediaItem.collectionType;
        const longDescriptionText = this.longDescription || '';
        const historicalNoteText = this.historicalNote || '';

        this.textDetails = `${longDescriptionText}\n${historicalNoteText}`;
        this.concatTags(this.mediaItem);

        this.platformCollectionTag = this.getItem(CategoryTypeEnum.platformCollection, this.mediaItem);

        this.countryTags = this.getItems(CategoryTypeEnum.country, this.mediaItem);
        this.countryTags.forEach(countryTag => {
          if(getName(countryTag?.slug, this.languageService?.currentLang)){
            countryTag.value = getName(countryTag?.slug, this.languageService?.currentLang);
          }
        });

        this.logger.debug('MoreInformationComponent -> GetDetails: Success');
      },
      (error: HttpErrorResponse) => {
        this.logger.error(
          'DetailsTabsComponent -> GetDetails: Failed',
          this.contentId,
          error
        );
      }
    );
  }

  private getVideoDetails() {
    this.videoDetailService
    .getVideoDetails(this.contentId)
    .pipe(untilDestroyed(this))
    .subscribe(
      (response: Video) => {
        this.mediaItem = response;
        const longDescriptionText = this.longDescription || '';
        const historicalNoteText = this.historicalNote || '';

        this.textDetails = `${longDescriptionText}\n${historicalNoteText}`;

        this.concatTags(this.mediaItem);

        this.platformCollectionTag = this.getItem(CategoryTypeEnum.platformCollection, this.mediaItem);

        this.countryTags = this.getItems(CategoryTypeEnum.country, this.mediaItem);
        this.countryTags.forEach(countryTag => {
          if(getName(countryTag?.slug, this.languageService?.currentLang)){
            countryTag.value = getName(countryTag?.slug, this.languageService?.currentLang);
          }
        });

        this.rightsOwnershipTag = this.getItem(CategoryTypeEnum.rightsOwnership, this.mediaItem);


        this.logger.debug('MoreInformationComponent -> GetDetails: Success');
      },
      (error: HttpErrorResponse) => {
        this.logger.error(
          'MoreInformationComponent -> GetDetails: Failed',
          this.contentId,
          error
        );
      }
    );
  }

  private getElement(element){
    const leftTab = document.getElementById(element);
    return leftTab;
  }

  private addClassSelected(element){
    element.classList.add('selected-card');
  }

  private removeClassSelected(element){
    element.classList.remove('selected-card');
  }
}

/* eslint-disable @typescript-eslint/no-empty-function */
import { IDriver, IDriverCreateParams, Player } from './driver.interface';
import { PlayerDetailType } from './player-details.interface';

export type PlayerEventFunction = () => void;

export abstract class Driver implements IDriver {
  player: Player;

  onPlay: PlayerEventFunction = () => { };
  onPlaying: PlayerEventFunction = () => { };
  onPause: PlayerEventFunction = () => { };
  onEnded: PlayerEventFunction = () => { };
  onEnterFullScreen: PlayerEventFunction = () => { };
  onExitFullScreen: PlayerEventFunction = () => { };
  onAdsComplete: PlayerEventFunction = () => { };

  registerOnPlay(fn: PlayerEventFunction): void {
    this.onPlay = fn;
  }

  registerOnPlaying(fn: PlayerEventFunction): void {
    this.onPlaying = fn;
  }

  registerOnPause(fn: PlayerEventFunction): void {
    this.onPause = fn;
  }

  registerOnEnded(fn: PlayerEventFunction): void {
    this.onEnded = fn;
  }

  registerOnEnterFullScreen(fn: PlayerEventFunction): void {
    this.onEnterFullScreen = fn;
  }

  registerOnExitFullScreen(fn: PlayerEventFunction): void {
    this.onExitFullScreen = fn;
  }

  registerOnAdsComplete(fn: PlayerEventFunction): void {
    this.onAdsComplete = fn;
  }

  play(): void{
    this.player.play();
  }

  pause(){
    this.player.pause();
  }

  getCurrentTime(): number {
    return this.player.currentTime;
  }

  getDuration(): number {
    if (typeof this.player.duration === 'function') {
      return this.player.duration();
    }

    return this.player.duration;
  }

  getAvailableSubtitles(): TextTrack[] {
    return this.player.textTracks;
  }

  forward(time: number): void { }

  rewind(time: number): void { }

  //only available for shaka-player
  getCaptionsVisibility(): void {}

  setSelectedSubtitle(id: number): void { }

  unselectSubtitles(): void { }
  //End only available for shaka-player

  abstract create(params: IDriverCreateParams): void;

  abstract updateSource(params: PlayerDetailType): void;

  abstract destroy(): void;

  abstract get paused(): boolean;

  abstract get currentTime(): number;

  abstract set currentTime(time: number);

  abstract get live(): boolean;

  abstract get duration(): number;
}

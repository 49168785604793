<div class="quaternary-button"
     appNavigation
     [navigableId]="0"
     (click)="return()">
  <button class="button button-medium">
    <span class="content">
      <span class="text"><i class="gi-lg gi-return-icon"></i>
        {{'buttons.return' | translate}}</span>
    </span>
  </button>
</div>

import { Component } from '@angular/core';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { LanguageService } from '@app/core/services/language.service';
import { SubtitleApi } from '@app/galgo-api/models';
import { PLAYER_UNAVAILABLE_MODAL } from '@app/galgo-player/config/player-unavailable-modal.conf';
import { GalgoPlayerComponent } from '@app/galgo-player/galgo-player.component';
import { Driver, IDriverCreateParams, PlayerDetailType } from '@app/galgo-player/models';
import { GENREIC_ERROR_MODAL_DATA } from '@app/shared/components/modal/config';
import { ModalService } from '@app/shared/components/modal/services/modal.service';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';

declare let shaka: any;


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-shaka-driver',
  template: '',
})


export class ShakaDriverComponent extends Driver {
  video: HTMLVideoElement | HTMLAudioElement;

  constructor(
    private logger: NGXLogger,
    private languageService: LanguageService,
    private galgoPlayerService: GalgoPlayerService,
    private modalService: ModalService,
    private browsingHistoryService: BrowsingHistoryService,
    private galgoPlayerRef: GalgoPlayerComponent
  ) {
    super();
  }

  get audioTracks() {
    const tracks = this.getAudioTracks();

    const mappedAudioTracks = tracks.map(({language, label, active, id}) => ({
      enabled: active,
      id,
      kind: 'audio',
      label,
      language,
      isAudio: true
    }));

    return mappedAudioTracks;
  }

  get paused(): boolean{
    return this.video.paused;
   }

  get currentTime(): number{
    return this.video.currentTime;
  }

  set currentTime(time: number){
    this.video.currentTime = time ;
  }

  get live(): boolean {
    return this.player.isLive();
  }

  get duration(): number {
    return this.video.duration;
  }

  setCurrentTime(seconds: number) {
    this.player.currentTime(seconds);
  }

  getAvailableSubtitles(): TextTrack[] {
    return Array.from(this.player.getTextTracks());
  }

  create(params: IDriverCreateParams): void {
    this.video = params.videoElement;
    shaka.polyfill.installAll();

    this.video.controls = false;  // Disable controls
    this.player = new shaka.Player(params.videoElement);
    //if want custom config use this.player.configure(SHAKA_OPTIONS);
    this.player.configure({
      preferredAudioLanguage: this.languageService.currentLang,
      preferredTextLanguage: this.languageService.currentLang
    });

    this.setDefaultAudioTrack();

    // Inicializar la UI de Shaka Player
    //const ui = new shaka.ui.Overlay(this.player, document.getElementById('video-container'), this.video);
    //const controls = ui.getControls();

    this.captureEvents();
  }

  updateSource(params: PlayerDetailType):  void {
    const protection = {
      drm: {
        servers: {
          'com.widevine.alpha': 'https://drm-widevine-licensing.axprod.net/AcquireLicense',
        },
      },
    };

    this.player.configure(protection);
    this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        request.headers['X-AxDRM-Message'] = params.drm?.token;
      }
    });


    this.player.load(params.src).then(() => {
      params.subtitlesData.forEach(async (element: SubtitleApi) => {
        const track = await this.player.addTextTrackAsync(
          element.url, // URI
          element.language, // Lang code
          'captions', // Kind of track (subtitles, captions, etc.)
          'text/vtt', // MIME type subtitles file
          null, // Codec (optional, could be null)
          element.description, // Label (opcional)
          element.forced //forced
        );
      });

    }).catch(error => {
      console.error('Código de error', error.code, 'objeto', error);
      let modalError = GENREIC_ERROR_MODAL_DATA;

      if (error.code === 1001) {
        modalError = PLAYER_UNAVAILABLE_MODAL;
      }

      this.modalService.open(modalError, this.galgoPlayerRef, () => {
        this.browsingHistoryService.goBack();
      });
    });

    this.galgoPlayerService.setAspectRatio();
  }

  destroy(): void {
    this.logger.debug('ShakaDriverComponent => destroy');
    if (this.player) {
      this.player.destroy();
    }
  }

  captureEvents(): void {
    this.logger.trace('ShakaDriverComponent => captureEvents');
    this.video.addEventListener('playing', () => {
      this.logger.trace('ShakaDriverComponent => captureEvents: onPlaying');
      this.onPlaying();
      this.galgoPlayerService.updateStatus((status) => (status.playing = true));
    });
    this.video.addEventListener('pause', () => {
      this.logger.trace('ShakaDriverComponent => captureEvents: onPause');
      this.onPause();
      this.galgoPlayerService.updateStatus((status) => (status.playing = false));
    });
    this.video.addEventListener('ended', () => {
      this.onEnded();
      this.galgoPlayerService.updateStatus((status) => (status.ended = true));
    });

    this.video.addEventListener('error', (event) => {
      console.error('Error en la reproducción:', event);
    });
  }

  play(): void{
    this.video.play();
  }

  pause(){
    this.video.pause();
  }

  forward(seconds?: number): void {
    this.video.currentTime = this.video.currentTime + seconds;
  }

  rewind(seconds?: number): void {
    this.video.currentTime = this.video.currentTime - seconds;
  }

  getCurrentTime(): number {
    return this.video.currentTime;
  }

  getDuration(): number {

    return this.video.duration;
  }

  getCaptionsVisibility(): boolean {
    return this.player.isTextTrackVisible();
  }

  enableAudioTrack(id?: number): void {
    const variantTracks = this.player.getVariantTracks();
    const selectedTrack = variantTracks.find(track => track.id === id);
    this.player.selectVariantTrack(selectedTrack, true);
  }

  setDefaultAudioTrack(): void {
    const variantTracks = this.player.getVariantTracks();
    const selectedTrack = variantTracks.find(track => track.language === this.languageService.currentLang) ?? variantTracks[0];
    this.player.selectVariantTrack(selectedTrack, true);
  }

  setSelectedSubtitle(id: string |number): void {
    const tracks = this.player.getTextTracks();
    const selectedTrack = tracks.find(track => track.id === id);
    if (selectedTrack) {
      this.player.selectTextTrack(selectedTrack);
      this.video.textTracks[0].mode = 'showing';
    }
  }

  unselectSubtitles(): void {
    this.video.textTracks[0].mode = 'hidden';
  }

  getAudioTracks() {
    const tracks = this.player.getVariantTracks();
    const languages = [];

    for (const {label, language, active, id} of tracks) {
      if (language && !languages.some(track => track.label === label)) {
        languages.push({label, language: shaka.util.LanguageUtils.normalize(language), active, id});
      }
    }

    return languages;
  }
}

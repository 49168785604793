import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';


@Component({
  selector: 'ty-list-selector-icon',
  templateUrl: './list-selector-icon.component.html',
  styleUrls: ['./list-selector-icon.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ListSelectorIconComponent }],
})
export class ListSelectorIconComponent extends NavigationComponent {
  @Output() subtitledChanged = new EventEmitter<number>();
  @Input() itemTracks: any[];
  @Input() iconActive: string;
  @Input() iconDefault: string;
  @Input() typeOfList: string;



  showSubtitleList: boolean;

  constructor(
    public el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(el);
    this.showSubtitleList = false;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onClickBtn(): void {
    this.showSubtitleList = true;
  }

  onChildNodesUpdated(): void {
    if(this.getChildNodeById(1)){
      this.selectChildNodeById(1);
    }
  }

  changeItem(itemId: number): void {
    this.subtitledChanged.emit(itemId);
    this.onBackKey();
  }

  onBackKey(): boolean {
    if(this.showSubtitleList){
      this.showSubtitleList = false;
      this.changeDetectorRef.detectChanges();
      this.selectChildNodeById(0);
      return true;
    }
    return false;
  }

}

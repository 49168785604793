<div class="header-player">
  <ty-return-button *ngIf="showBackButton"
                    class="return-button"
                    [navigableId]="0"></ty-return-button>
  <div class="info-wrapper">
    <span class="info"> {{title}} </span>
    <span class="live"
          *ngIf="isLive">
      <i class="gi-lg gi-live"></i>{{'buttons.live' | translate}}
    </span>
  </div>
</div>

import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IButtonConfig } from './button-config.interface';

@Component({
  selector: 'ty-button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ButtonListComponent }]
})
export class ButtonListComponent extends NavigationComponent{

  @Input() btnList: IButtonConfig[];
  @Output() clickEvent?: EventEmitter<string>;

  constructor(
    public el: ElementRef,
   ) {
    super(el);
    this.clickEvent = new EventEmitter<string>();
  }

  clickButton(button: IButtonConfig){
    this.clickEvent.emit(button.action);
  }

    onRightKey(): boolean {
      const actualIndex = this.getSelectedChildNodeId();

      if (this.btnList.length === 0 || actualIndex === this.btnList.length - 1) {
          return false;
      }
      this.selectNextChildNode();
      return true;
  }

  onLeftKey(): boolean {
    const actualIndex = this.getSelectedChildNodeId();
    if(actualIndex < 1){
      return false;
    }
    this.selectPreviousChildNode();
    return true;
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { EpgModule } from '@app/features/epg/epg.module';
import { GridModule } from '@app/shared/components/grid/grid.module';
import { LoadingSpinnerModule } from '@app/shared/components/loading-spinner/loading-spinner.module';
import { TranslateModule } from '@ngx-translate/core';
import { LivesRoutingModule } from './lives-routing.module';
import { LivesComponent } from './lives/lives.component';

const ANGULAR_MODULES = [ CommonModule ];
const COMPONENTS_MODULES = [
  GridModule,
  LoadingSpinnerModule,
];
const EXTERNAL_MODULES = [ TranslateModule ];
@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ...COMPONENTS_MODULES,
    ...EXTERNAL_MODULES,
    LivesRoutingModule,
    NavigationModule,
    EpgModule
  ],
  declarations: [
    LivesComponent
  ]
})
export class LivesModule { }

import { IEnvironment } from '@core/models/environment.interface';
import { default as appInfo } from '../../package.json';

declare global {
  interface Window {
    __env: any;
  }
}

export const environment: IEnvironment = {
  apiUrl: window?.__env?.apiUrl,
  beatInterval: window?.__env?.beatInterval || 15000,
  addPlayingInterval: window?.__env?.addPlayingInterval || 15000,
  categoriesMaxNum: {
    labels: window?.__env?.categoriesMaxNum?.label || 5,
    thematics: window?.__env?.categoryType?.thematics || 2,
    pegi: window?.__env?.categoryType?.pegi || 3,
  },
  defaultLanguage: window?.__env?.defaultLanguage || 'es',
  features: {
    helpUrl: window?.__env?.features?.helpUrl || false,
    enableBeatAnalytic: window?.__env?.features?.enableBeatAnalytic || true
  },
  firebase: {
    apiKey: window?.__env?.firebase?.apiKey,
    appId: window?.__env?.firebase?.appId,
    authDomain: window?.__env?.firebase?.authDomain,
    measurementId: window?.__env?.firebase?.measurementId,
    messagingSenderId: window?.__env?.firebase?.messagingSenderId,
    projectId: window?.__env?.firebase?.projectId,
    storageBucket: window?.__env?.firebase?.storageBucket,
  },
  mock: window?.__env?.mock || false,
  production: true,
  tokenType: 'Bearer',
  version: appInfo.version + '.603-dev'
};

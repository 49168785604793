import { Injectable } from '@angular/core';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { SubtitleApi } from '@app/galgo-api/models';
import { PlayerName } from '@app/galgo-player/config/player.enum';
import { IDrmConfig } from '@app/galgo-player/models/drm-config.interface';
import { IPlayerDetailsEvents, PlayerDetailType } from '@app/galgo-player/models/player-details.interface';
import { MediaItem } from '@core/models/media-item.model';
import { Video } from '@shared/models/video/video.model';
//import { ImageSizeApi } from '@tyris/galgo-client-sdk';
@Injectable({
  providedIn: 'root',
})
export class PlayerDetailsService {
  constructor(private customerDataService: CustomerDataService) { }

  /**
   * This method builds the playerDetails object for each different player
   *
   * @param mediaItem {@link mediaItem} data needed to build the player details
   * @param  url {@link url} string containing the url of the video
   * @return object {@link PlayerDetailType}
   * @memberof PlayerDetailsService
   */
  generatePlayerDetails(
    mediaItem: MediaItem<Video>,
    url: string,
    events?: IPlayerDetailsEvents,
    subtitles?: SubtitleApi[],
    adsUrl?: string,
    drm?: IDrmConfig
  ): PlayerDetailType {
    let details;
    switch (this.customerDataService.playerName) {
      case PlayerName.brightcove:
       //details = this.setupBrightcoveDetails(mediaItem, events);
        break;

      case PlayerName.theo:
        details = this.setupTheoDetails(mediaItem, url, subtitles, events, adsUrl, drm);
        break;
      case PlayerName.videojs:
        details = this.setupVideojsDetails(mediaItem, url, subtitles, events, adsUrl, drm);
        break;
      case PlayerName.shaka:
        details = this.setupShakaDetails(mediaItem, url, subtitles, events, adsUrl, drm);
    }
    return details;
  }

  private setupShakaDetails(
    mediaItem: MediaItem<Video>,
    url: string,
    subtitles: SubtitleApi[],
    events: IPlayerDetailsEvents,
    adsUrl: string,
    drm: IDrmConfig
  ) {
    const videojsDetails: PlayerDetailType = {
      id: mediaItem?.id,
      drm,
      //fullscreen: mediaItem?.fullscreen,
      fullscreen: true,
      playerName: PlayerName?.shaka,
      type: mediaItem?.type?.toLocaleLowerCase(),
      playerType: mediaItem?.playerType,
      src: url,
      title: mediaItem?.title,
      // eslint-disable-next-line max-len
      //poster: mediaItem?.thumbnail?.landscapes?.find(image => image.size === ImageSizeApi.Original)?.url || mediaItem?.thumbnail?.landscape,
      subtitlesData: subtitles,
      events,
      adsTagUrl: adsUrl,
      isLive: mediaItem.live
    };
    return videojsDetails;
  }

   private setupVideojsDetails(
    mediaItem: MediaItem<Video>,
    url: string,
    subtitles: SubtitleApi[],
    events: IPlayerDetailsEvents,
    adsUrl: string,
    drm: IDrmConfig
  ) {
    const videojsDetails: PlayerDetailType = {
      id: mediaItem?.id,
      drm,
      //fullscreen: mediaItem?.fullscreen,
      fullscreen: true,
      playerName: PlayerName?.videojs,
      type: mediaItem?.type?.toLocaleLowerCase(),
      playerType: mediaItem?.playerType,
      src: url,
      title: mediaItem?.title,
      // eslint-disable-next-line max-len
      //poster: mediaItem?.thumbnail?.landscapes?.find(image => image.size === ImageSizeApi.Original)?.url || mediaItem?.thumbnail?.landscape,
      subtitlesData: subtitles,
      events,
      adsTagUrl: adsUrl,
      isLive: mediaItem.live
    };
    return videojsDetails;
  }

  private setupTheoDetails(
    mediaItem: MediaItem<Video>,
    url: string,
    subtitles: SubtitleApi[],
    events: IPlayerDetailsEvents,
    adsUrl: string,
    drm: IDrmConfig
  ) {
    const details: PlayerDetailType = {
      adsTagUrl: adsUrl,
      drm,
      events,
      id: mediaItem?.id,
      isLive: mediaItem.live,
      license: this.customerDataService.playerCredentials?.license,
      //fullscreen: mediaItem?.fullscreen,
      playerName: PlayerName?.theo,
      // eslint-disable-next-line max-len
      //poster: mediaItem?.thumbnail?.landscapes?.find(image => image.size === ImageSizeApi.Original)?.url || mediaItem?.thumbnail?.landscape,
      subtitlesData: subtitles,
      src: url,
      title: mediaItem?.title,
      type: mediaItem?.type?.toLocaleLowerCase(),
    };
    return details;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { ButtonPlayerModule } from '@app/shared/components/button-player/button-player.module';
import { DogModule } from '@app/shared/components/dog/dog.module';
import { NextChapterModule } from '@app/shared/components/next-chapter/next-chapter.module';
import { PlayerUiModule } from '@app/shared/components/player-ui/player-ui.module';
import { BackgroundThumbnailModule } from '@shared/components/background-thumbnail/background-thumbnail.module';
import { LogoModule } from '@shared/components/logo/logo.module';
import { ReturnButtonModule } from '@shared/components/return-button/return-button.module';
import { GalgoPlayerComponent } from './galgo-player.component';

@NgModule({
  imports: [
    CommonModule,
    BackgroundThumbnailModule,
    LogoModule,
    ReturnButtonModule,
    NavigationModule,
    ButtonPlayerModule,
    PlayerUiModule,
    NextChapterModule,
    DogModule
  ],
  declarations: [
    GalgoPlayerComponent
  ],
  exports: [GalgoPlayerComponent]
})
export class GalgoPlayerModule { }

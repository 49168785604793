<div class="detail-info-wrapper"
     id="detail-info-wrapper"
     appNavigation
     [navigableId]="0">
  <div id="detail-info">

    <div class="detail-item"
         *ngIf="!!tags">
      <div class="detail-item-title">
        <p>{{'details.labels' | translate }}</p>
      </div>
      <div class="tag-item-list">
        <div class="tag-item-list-description detail-item-description"
             *ngFor="let tag of tags; let i = index;  let last = last">
          {{ tag.value }} <span *ngIf="!last">{{this.separator}}</span>
        </div>
      </div>
    </div>
    <div id="detail-item-wrapper">
      <div class="detail-item"
           *ngIf="platformCollection">
        <div class="detail-item-title">
          {{ platformCollection.title }}
        </div>
        <div class="detail-item-description">
          {{ platformCollection.value }}
        </div>
      </div>

      <div class="detail-item"
           *ngIf="producer">
        <div class="detail-item-title">
          {{ producerLabel }}
        </div>
        <div class="detail-item-description">
          {{ producer }}
        </div>
      </div>

      <div class="detail-item"
           *ngFor="let participant of participants; let i=index">
        <div class="detail-item-title">
          {{ participant.personalRole.name }}
        </div>
        <div class="detail-item-description"
             *ngFor="let person of participant.persons">
          {{ person.complete }}
        </div>
      </div>

      <div class="detail-item"
           *ngIf="countries">
        <div class="detail-item-title">
          {{ countries[0].title }}
        </div>
        <div class="detail-item-description"
             *ngFor="let country of countries">
          {{ country.value }}
        </div>
      </div>

      <div class="detail-item"
           *ngIf="audios && audios.length">
        <div class="detail-item-title">
          {{'details.audios' | translate }}
        </div>
        <div class="detail-item-description"
             *ngFor="let audio of audios">
          {{ audio.label }}
        </div>
      </div>

      <div class="detail-item"
           *ngIf="subtitlesAvailable">
        <div class="detail-item-title">
          {{'details.subtitles' | translate }}
        </div>

        <div class="detail-item-description"
             *ngFor="let subtitle of translatedSubtitles; let last = last">
          {{ subtitle | async }}
        </div>


      </div>

      <div class="detail-item"
           *ngIf="rightsOwnership">
        <div class="detail-item-title">
          {{ rightsOwnership.title }}
        </div>
        <div class="detail-item-description">
          {{ rightsOwnership.value }}
        </div>
      </div>

      <div class="detail-item"
           *ngIf="originalTitle">
        <div class="detail-item-title">
          {{'details.originalTitle' | translate }}
        </div>
        <div class="detail-item-description">
          {{ originalTitle }}
        </div>
      </div>

    </div>

  </div>
</div>
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-custom-seekbar',
  templateUrl: './custom-seekbar.component.html',
  styleUrls: ['./custom-seekbar.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: CustomSeekbarComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSeekbarComponent extends NavigationComponent implements AfterViewInit, OnChanges {
  @Input() value = 0;
  @Input() duration = 0;
  @Input() thumbnails = [];
  @Input() outerSeeking = false;
  @Input() storyboard = '';
  @Input() isSeekable = false;
  @Input() isLive = false;


  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() seeking = new EventEmitter<boolean>();
  @Output() onchange = new EventEmitter<number>();

  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('playbackPositionMarker', { static: true }) playbackPositionMarkerElement: ElementRef<HTMLDivElement>;

  // HTML elements
  canvasEl: HTMLCanvasElement;
  seekbarWrapElement: any;
  backdropElement: any;
  playbackPositionElement: any;
  thumbnailImgCanvas: any;
  thumbnailSrc: string;
  imageWidth = 0;
  imageHeight = 0;
  x = 0;
  y = 0;
  w = 0;
  h = 0;

  position = 0;

  isSeeking = false;

  constructor(
    public el: ElementRef,
    ) {
      super(el);
  }

  ngAfterViewInit() {
    this.getElements();
    if (this.seekbarWrapElement && !this.isSeeking && this.isSeekable) {
      this.moveSeekbar(this.value);
    }
    if(!this.isSeekable){
      this.duration = 1;
      this.playbackPositionElement.style.transform = `scaleX(1)`;
    } else if (!this.isLive){
      this.moveSeekbar(0);
    }
    this.setMouseEvents();
    this.setResizeWindowEvent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshSeekbar();
  }

  getElements() {
    this.seekbarWrapElement = document.getElementById('seekbar-wrap');
    this.backdropElement = document.getElementById('seekbar-ui');
    this.playbackPositionElement = document.getElementById('playback-position');
    this.thumbnailImgCanvas = document.getElementById('thumbnail-img-canvas');
  }

  setMouseEvents() {

    fromEvent(this.seekbarWrapElement, 'mousedown')
    .pipe(untilDestroyed(this))
    .subscribe({
      next: (e: any) => {
        this.moveSeekbar(this.getPercentageFromX(this.getXFromPageX(e.pageX)));
        this.isSeeking = true;
        this.seeking.emit(this.isSeeking);
      }
    });

    fromEvent(window, 'mousemove')
    .pipe(untilDestroyed(this))
    .subscribe({
      next: (e: any): boolean => {
        if (this.isSeeking) {
          this.moveSeekbar(this.getPercentageFromX(this.getXFromPageX(e.pageX)));
          e.preventDefault();
          return false;
        }
        return true;
      }
    });

    fromEvent(window, 'mouseup')
    .pipe(untilDestroyed(this))
    .subscribe({
      next: (e: any) => {
        if (this.isSeeking) {
          this.isSeeking = false;
          this.moveSeekbar(this.position);
          this.onchange.emit(this.position);
          this.seeking.emit(this.isSeeking);
        }
      }
    });
  }

  setResizeWindowEvent() {
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (e: any) => {
        this.moveSeekbar(this.position);
      },
      });
  }

  getXFromPageX(pageX: number = 0): number {
    const rect = this.backdropElement.getBoundingClientRect();
    const positionPx = pageX - rect.left;
    return positionPx > rect.width ? rect.width : positionPx < 0 ? 0 : positionPx;
  }

  getPercentageFromX(x: number): number {
    const rect = this.backdropElement.getBoundingClientRect();
    return x / rect.width;
  }

  getXFromPercentage(percentage: number): number {
    const rect = this.backdropElement.getBoundingClientRect();
    return rect.width * percentage;
  }

  moveSeekbar(percentage: number) {
    this.position = percentage;
    this.playbackPositionElement.style.transform = `scaleX(${percentage})`;
    this.playbackPositionMarkerElement.nativeElement.style.transform = `translateX(${this.getXFromPercentage(
      percentage
    )}px)`;
    if (this.isSeeking || this.outerSeeking) {
      let src = this.getThumbnailSrc(this.position * this.duration);
      if (src && this.storyboard) {
        const lastIndex = this.storyboard.lastIndexOf('/');
        const splitUrl = this.storyboard.substring(0, lastIndex + 1);
        src = splitUrl + src;
      }
      this.setThumbnail(src);
      if (src) {
        this.thumbnailImgCanvas.style.display = 'initial';
      }
    } else {
      this.resetThumbnailSrc();
      this.thumbnailImgCanvas.style.display = 'none';
    }
  }

  setThumbnail(src: string): void {
    if (this.thumbnailSrc) {
      return;
    }

    this.canvasEl = this.canvas.nativeElement;
    this.thumbnailSrc = src;

    setTimeout(() => {
      if (!this.isSeeking && !this.outerSeeking) {
        return;
      }

      const match = src.match(/#xywh=(\d+),(\d+),(\d+),(\d+)/);
      if (match) {
        this.setDimensionsFromMatch(match);
      }

      this.drawImageOnCanvas(src);
      this.resetThumbnailSrc();
    }, 100);
  }

  /**
   * Sets the thumbnail dimensions set by a .vtt file.
   * The array must contain the x, y, width and height values in that order.
   * If the image width is less than 272px, set the image width to 272 and the image height to 153px.
   */
  setDimensionsFromMatch(match: RegExpMatchArray): void {
      this.x = +match[1];
      this.y = +match[2];
      this.w = +match[3];
      this.h = +match[4];
      this.imageWidth = this.w;
      this.imageHeight = this.h;

      if (this.imageWidth < 272) {
        this.imageWidth = 272;
        this.imageHeight = 153;
      }
  }

  drawImageOnCanvas(src: string): void {
    const canvasContext = this.canvas.nativeElement.getContext('2d');
    const imagen = new Image();

    imagen.onload = () => {
      canvasContext.drawImage(imagen, this.x, this.y, this.w, this.h, 0, 0, this.imageWidth, this.imageHeight);
    };

    imagen.src = src;
    this.canvasEl.width = this.imageWidth;
    this.canvasEl.height = this.imageHeight;
  }

  resetThumbnailSrc(): void {
    this.thumbnailSrc = '';
  }
  getThumbnailSrc(time: number): string {
    return (
      this.thumbnails?.find(thumbnail => time >= thumbnail.start && time <= thumbnail.end)?.src ||
      ''
    );
  }

  private refreshSeekbar(): void {
    if (this.seekbarWrapElement && !this.isSeeking && this.isSeekable) {
      this.moveSeekbar(this.value);
    }
  }
}

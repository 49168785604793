import { Injectable } from '@angular/core';
import { EpgApi } from '@app/galgo-api/models/epg-api';
import { VideoApiService } from '@app/galgo-api/services';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { EPG_CACHE_TIME } from '../conf/epg-cache.conf';


@Injectable({
  providedIn: 'root'
})
export class EPGService {
  private epg$: Observable<EpgApi[]>;
  private cacheTime: Date;

  constructor(private videoApiService: VideoApiService) {}

  getEPG(): Observable<EpgApi[]> {
    if (!this.epg$ || new Date().getTime() - this.cacheTime.getTime() > EPG_CACHE_TIME) {
      return this.videoApiService.epgControllerFind()
        .pipe(
          tap(() => this.cacheTime = new Date()),
          shareReplay({bufferSize: 1, refCount: true})
        );
    }
    return this.epg$;

  }
}

/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TokenApi } from '../models/token-api';
import { TvCodeApi } from '../models/tv-code-api';

@Injectable({
  providedIn: 'root',
})
export class SmartTvCodeLoginApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userCodeTvControllerUpdateUserCode
   */
  static readonly UserCodeTvControllerUpdateUserCodePath = '/smartTVCode';

  /**
   * update code with user email to use for login
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCodeTvControllerUpdateUserCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCodeTvControllerUpdateUserCode$Response(params: {

    /**
     * code to share
     */
    code: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SmartTvCodeLoginApiService.UserCodeTvControllerUpdateUserCodePath, 'put');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * update code with user email to use for login
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCodeTvControllerUpdateUserCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCodeTvControllerUpdateUserCode(params: {

    /**
     * code to share
     */
    code: string;
  }): Observable<void> {

    return this.userCodeTvControllerUpdateUserCode$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userCodeTvControllerCreateCode
   */
  static readonly UserCodeTvControllerCreateCodePath = '/smartTVCode';

  /**
   * Create code to use for login
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCodeTvControllerCreateCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCodeTvControllerCreateCode$Response(params?: {
  }): Observable<StrictHttpResponse<TvCodeApi>> {

    const rb = new RequestBuilder(this.rootUrl, SmartTvCodeLoginApiService.UserCodeTvControllerCreateCodePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TvCodeApi>;
      })
    );
  }

  /**
   * Create code to use for login
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCodeTvControllerCreateCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCodeTvControllerCreateCode(params?: {
  }): Observable<TvCodeApi> {

    return this.userCodeTvControllerCreateCode$Response(params).pipe(
      map((r: StrictHttpResponse<TvCodeApi>) => r.body as TvCodeApi)
    );
  }

  /**
   * Path part for operation userCodeTvControllerLogin
   */
  static readonly UserCodeTvControllerLoginPath = '/smartTVCode/login';

  /**
   * Login with code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCodeTvControllerLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCodeTvControllerLogin$Response(params: {

    /**
     * code to login
     */
    code: string;
  }): Observable<StrictHttpResponse<TokenApi>> {

    const rb = new RequestBuilder(this.rootUrl, SmartTvCodeLoginApiService.UserCodeTvControllerLoginPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenApi>;
      })
    );
  }

  /**
   * Login with code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userCodeTvControllerLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCodeTvControllerLogin(params: {

    /**
     * code to login
     */
    code: string;
  }): Observable<TokenApi> {

    return this.userCodeTvControllerLogin$Response(params).pipe(
      map((r: StrictHttpResponse<TokenApi>) => r.body as TokenApi)
    );
  }

}

<div class="button-wrapper"
     [id]="selectorId"
     appNavigation
     [navigableId]="0"
     (focus)="focus()"
     (mouseover)="focus()"
     (blur)="blur()"
     (mouseleave)="blur()">
  <button class="button">
    <span class="content">
      <img *ngIf="iconName"
           src="./assets/customer/icons/{{iconName}}"
           alt="{{iconName}} icon"
           class="icon icon--space" />
      <span class="text">{{text | translate}}</span>
    </span>
  </button>
</div>

<div id="collections-menu">
  <h4>{{ title | translate }}</h4>
  <div class="black-gradient-overlay"></div>
  <div class="grid-overflow-hidden"
       #containers>
    <ty-grid class="grid"
             *ngIf="mediaItems?.length && this.isDataReceived"
             [items]="mediaItems"
             [navigableId]="0"
             (updateScroll)="updateScroll()">
    </ty-grid>
  </div>
  <div class="no-purchases-wrapper"
       *ngIf="!mediaItems?.length && this.isDataReceived">
    <span class="title">{{ noItemsTitle | translate }}</span>
    <span class="text">{{ noItemsText | translate }}</span>
  </div>
</div>
<div class="items-list">
  <ul>
    <li *ngIf="!itemTracks[0]?.isAudio"
        class="itemsBtn"
        appNavigation
        [navigableId]="0"
        (click)="changeSubtitle(0, 'none')"
        [ngClass]="{'active': selectedItem === 'none'}">
      {{ 'controls-player.no-subtitles' | translate }}
    </li>
    <li class="itemsBtn"
        *ngFor="let item of itemTracks; let i = index"
        [ngClass]="{'active': item.id === selectedItem}"
        (click)="changeSubtitle(item.id ?? i, item.language ?? item.srclang)"
        appNavigation
        [navigableId]="i + 1">
      {{ item.label !== '' ? item.label :  item.language }}
    </li>
  </ul>
</div>
import { Injectable } from '@angular/core';

interface Ad {
  id: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdsService {
  private ads: Ad[] = [];

  constructor() {}

  async initializeAds(urlVAST: string, adId?: string): Promise<void> {
    try {
      const response = await fetch(urlVAST);
      const xmlText = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlText, 'text/xml');

      const ads: Ad[] = [];
      const adElements = xmlDoc.querySelectorAll('Ad');

      adElements.forEach(adElement => {
        const adIdAttr = adElement.getAttribute('id');
        const mediaFile = adElement.querySelector('MediaFile[type="video/mp4"]');

        if (adIdAttr && mediaFile) {
          const url = mediaFile.textContent?.trim();
          if (url) {
            ads.push({ id: adIdAttr, url });
          }
        }
      });

      this.ads = ads;

      if (adId) {
        this.playAdById(adId);
      } else {
        this.playAllAds();
      }
    } catch (error) {
      console.error('Error al cargar el VAST XML:', error);
    }
  }

  getAds(): Ad[] {
    return this.ads;
  }

  playAdById(adId: string): void {
    const ad = this.ads.find(a => a.id === adId);
    if (ad) {
      this.playAds([ad.url]);
    }
  }

  private playAllAds(): void {
    const videoUrls = this.ads.map(ad => ad.url);
    if (videoUrls.length > 0) {
      this.playAds(videoUrls);
    }
  }

  private playAds(videoUrls: string[]): void {
    const videoElement = document.createElement('video');
    videoElement.style.position = 'fixed';
    videoElement.style.top = '0';
    videoElement.style.left = '0';
    videoElement.style.width = '100vw';
    videoElement.style.height = '100vh';
    videoElement.style.objectFit = 'cover';
    videoElement.style.backgroundColor = 'black';
    videoElement.style.zIndex = '1000';
    videoElement.autoplay = true;
    videoElement.controls = false;
    videoElement.muted = false;

    document.body.appendChild(videoElement);

    let index = 0;

    const playNextAd = () => {
      if (index < videoUrls.length) {
        videoElement.src = videoUrls[index];
        videoElement.play().catch(error => console.error('Error al reproducir video:', error));
        index++;
      } else {
        videoElement.remove();
      }
    };

    videoElement.addEventListener('ended', playNextAd);
    playNextAd();
  }
}

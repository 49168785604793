<div id="seekbar-wrap"
     [class.live]="isLive"
     class="vod">
  <div id="seekbar-ui">
    <div id="playback-position"></div>
    <div id="playback-position-marker"
         [class.hidden]="!isSeekable"
         #playbackPositionMarker>
      <div class="thumbnail">
        <canvas #canvas
                id="thumbnail-img-canvas"
                width="120"
                height="68"
                class="contenedor-imagen"></canvas>
      </div>
    </div>
  </div>
</div>
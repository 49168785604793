import { Component } from '@angular/core';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { SubtitleApi } from '@app/galgo-api/models';
import { PLAYER_UNAVAILABLE_MODAL } from '@app/galgo-player/config/player-unavailable-modal.conf';
import { GalgoPlayerComponent } from '@app/galgo-player/galgo-player.component';
import { Driver, IDriverCreateParams, PlayerDetailType } from '@app/galgo-player/models';
import { ModalService } from '@app/shared/components/modal/services/modal.service';
import { AudioTrack } from '@app/shared/models/audioTrack.interface';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import videojs from 'video.js';
import 'videojs-contrib-eme';
import { VIDEOJS_DEFAULT_OPTIONS } from './videojs-default-options';
import { VideoSource } from './videojs-driver.interface';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-videojs-driver',
  template: '',
})


export class VideojsDriverComponent extends Driver {
    constructor(
    private logger: NGXLogger,
    private galgoPlayerService: GalgoPlayerService,
    private modalService: ModalService,
    private browsingHistoryService: BrowsingHistoryService,
    private galgoPlayerRef: GalgoPlayerComponent
  ) {
    super();
  }

  get paused(): boolean{
    return this.player.paused();
  }

   get audioTracks(): AudioTrack[] {
        return Array.from(this.player.audioTracks() as any[])
      .filter(track => !track.label.startsWith('Sound'))
      .map(track => ({
        enabled: track.enabled,
        id: track.id,
        kind: track.kind,
        label: track.label,
        language: track.language,
        isAudio: true
      }));
  }

  get currentTime(): number{
    return this.player.currentTime();
  }

  set currentTime(time: number){
    this.player.currentTime(time);
  }

  get live(): boolean {
    return this.player.duration() === Infinity;
  }

  get duration(): number {
    return this.player.duration();
  }

  enableAudioTrack(id: number): void {
    const selectedAudioTrack = this.player.audioTracks().tracks_.find(track => track.id === id);
    if (selectedAudioTrack) {
      selectedAudioTrack.enabled = true;
    }
  }

  setCurrentTime(seconds: number): void {
    this.player.currentTime(seconds);
  }

  getAvailableSubtitles(): TextTrack[] {
    return Array.from(this.player.remoteTextTracks());
  }

  create(params: IDriverCreateParams): void {

    this.player = videojs(params.videoElement, VIDEOJS_DEFAULT_OPTIONS);
    this.logger.trace('VideojsDriverComponent=>create:params', params);
    if (params.fullScreen) {
      this.checkFullscreenMode();
    }
    this.captureEvents();
  }

  destroy(): void {
    this.logger.debug('VideojsDriverComponent => destroy');
    this.player.dispose();
  }
  captureEvents(): void {
    this.logger.trace('VideojsDriverComponent => captureEvents');
    // TODO: map events from Vodeojs to standard
    this.player.on('playing', () => {
      this.logger.trace('VideojsDriverComponent => captureEvents: onPlaying');
      this.onPlaying();
      this.galgoPlayerService.updateStatus((status) => (status.playing = true));
    });
    this.player.on('pause', () => {
      this.logger.trace('VideojsDriverComponent => captureEvents: onPause');
      this.onPause();
      this.galgoPlayerService.updateStatus((status) => (status.playing = false));
    });
    this.player.on('ended', () => {
      this.onEnded();
      this.galgoPlayerService.updateStatus((status) => (status.ended = true));
    });

    //this.player.on('adscomplete', () => this.onAdsComplete());

    this.player.on('error', (event) => {
      console.error('Error in reproduction:', event.error);
      this.modalService.open(PLAYER_UNAVAILABLE_MODAL, this.galgoPlayerRef, () => {
        this.browsingHistoryService.goBack();
      });
    });
  }

updateSource(params: PlayerDetailType): void {
  const source: VideoSource = {
    src: this.filterEndHastagNumbers(params.src),
    contentProtection: params.drm,
    keySystems: {},
    poster: params.poster,
    metadata: {
      title: params.title.toString()
    }
  };

  if (params.drm) {
    source.keySystems['com.widevine.alpha'] =  {
      url: params.drm?.widevine.licenseAcquisitionURL,
      licenseHeaders: {
        'X-AxDRM-Message': params.drm?.token
      },
    };
  }

  if (params.adsTagUrl) {
    source.ads = [{
      sources: params.adsTagUrl
    }];
  }

  this.player.eme();
  this.logger.trace('VideoJSDriverComponent => updateSource params are', params);
  this.player.src(source);
  this.logger.trace('TheoDriverComponent => updateSource: source', params.src);
  this.player.poster(params.poster);
  this.player.play();

  if (params?.subtitlesData) {
    params.subtitlesData.forEach((element: SubtitleApi) => {
      this.player.addRemoteTextTrack({
        kind: 'subtitles',
        /**  ToDo review tag languages ​​in subtitles. Now we are receiving the label in the language of the label.
         *   We might expect to receive it in the user's or application's language.
         *   Review it if any changes are applied from behind and we receive more than one language
         *   {@link https://bitbucket.org/damonfer/tyris_tv_smarttv/pull-requests/611/overview}
         */
        label: element.description,
        srclang: element.language,
        src: element.url,
      }, false);
    });
  }

  this.galgoPlayerService.setAspectRatio();

}

  // the src returned from saluki when some video is procesing has an url ended in .mp4#113562217
  // this function erase the hastag and numbers if they are in the end of the string
  filterEndHastagNumbers(src: string): string {
    const regex = /#(\d+)$/;
    return src?.replace(regex, '');
}

  forward(seconds?: number): void {
    this.logger.trace('TheoDriverComponent => forward: currentTime', seconds, this.player);
    this.player.currentTime(this.player.currentTime() + seconds);
  }

  rewind(seconds?: number): void {
    this.logger.trace('TheoDriverComponent => rewind: currentTime', seconds, this.player);
    this.player.currentTime(this.player.currentTime() - seconds);
  }


  getCurrentTime(): number {
    return this.player.currentTime();
  }


  checkFullscreenMode() {
    if (this.player.supportsFullScreen()) {
      try {
        this.player.requestFullscreen();
      } catch {
        this.logger.log('NO FULLSCREEN ALLOWED');
      }
    } else {
      this.player.playsinline(true);
    }
  }
}

/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef
} from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { PlayerDrivers } from '@app/galgo-player/models';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { liveThreshold } from '../configuration/player-general.config';
import { VttParser } from './utils/vtt';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-seekbar',
  templateUrl: './seekbar.component.html',
  styleUrls: ['./seekbar.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: SeekbarComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeekbarComponent extends NavigationComponent implements AfterViewInit {
  video: any;
  driver: PlayerDrivers;
  timeString = '0:00';
  durationString = '0:00';
  time = 0;
  duration = 1;

  seekTime = 0;
  wasPlaying = true;
  isSeeking = false;
  isLive = false;
  isSeekable = false;
  liveThreshold = liveThreshold;

  thumbnails: any[];
  thumbnailsString: string;
  storyboard: string;
  timeoutId: number;
  isSeeking$: Observable<boolean>;

  private movePlaybackSource = new Subject<number>();
  private movePlaybackChanges = this.movePlaybackSource.asObservable();

  get showProgress(): boolean {
    return !this.isLive || this.isSeekable && this.driver.duration - this.time > this.liveThreshold;
  }

  constructor(
    public el: ElementRef,
    private galgoPlayerService: GalgoPlayerService,
    private changeDetector: ChangeDetectorRef,
    private videoDetailService: VideoDetailService,
    public logger: NGXLogger,
    private http: HttpClient

    ) {
      super(el);
      this.driver = this.galgoPlayerService.getDriver();
      this.isLive = this.galgoPlayerService.live;
      this.isSeekable = this.galgoPlayerService.isSeekable;
      this.duration = this.galgoPlayerService.duration ?? 0;
      this.getStoryboard();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.startEvents();
    this.isSeeking$ = this.galgoPlayerService.getSeeking();
  }

  getStoryboard(){
    if(this.galgoPlayerService.status.metas?.id){
      this.videoDetailService.getVideoURI(this.galgoPlayerService.status.metas?.id).pipe(untilDestroyed(this)).subscribe(
        data => {
          this.storyboard=data.uris?.storyboard || '';
          this.processThumbnails(this.storyboard);
        },
        error => {
          this.logger.error(
            ' SeekbarComponent-> getStoryboard(): Failed',
            error
          );
        }
      );
    }

  }
  getData(url: string) {
    return this.http.get(url, { responseType: 'text' });
  }

  processThumbnails(src: string): void {
    if (src) {
      this.getData(src).pipe(untilDestroyed(this)).subscribe(
        (data) => {
          this.thumbnailsString = data;
          this.thumbnails = VttParser.parseVttThumbnails(this.thumbnailsString);
        },
        (error) => {
          this.logger.error(
            ' SeekbarComponent-> processThumbnails(src: string): Failed',
            error
          );
        }
      );
    }
  }

  startEvents() {

    if(this.isSeekable){
      this.durationString = this.formatDurationFromSeconds(this.duration);
      this.galgoPlayerService.currentTime$.pipe(
        filter(() => !this.isSeeking),
        untilDestroyed(this)
      )
      .subscribe({
        next: (time) => {
            this.time = time;
            this.seekTime = this.time / this.driver.duration;
            const formattedTime = Math.floor(this.time);
            this.timeString = this.formatDurationFromSeconds(formattedTime);
            this.changeDetector.detectChanges();
          }
        });


      this.movePlaybackChanges.pipe(debounceTime(1000), untilDestroyed(this)).subscribe({
        next: (seekTime )=> {
          this.isSeeking = false;
          this.videoSeek(seekTime);
        },
      });
    }else{
      this.timeString = this.formatDurationFromSeconds(this.driver.getCurrentTime());
      this.changeDetector.detectChanges();
    }
  }

  formatDurationFromSeconds(seconds: number): string {
    if (this.isLive) {
      seconds = this.driver.duration - seconds;
    }
    try {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.round(seconds % 60);
      return (this.isLive ? '-' : '') + [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter(Boolean).join(':');
    } catch (error) {
      return '';
    }
  }

  setSeeking(seeking: boolean) {
    this.galgoPlayerService.driver.player = this.galgoPlayerService.getDriver().player;

    if (seeking) {
      if (!this.galgoPlayerService.driver.player.paused) {
        this.galgoPlayerService.driver?.pause();
        this.wasPlaying = true;
      }
    } else if (this.wasPlaying) {
      this.galgoPlayerService.driver?.play();
      this.wasPlaying = false;
    }
    this.isSeeking = seeking;
  }

  videoSeek(seek: number) {
    this.time = this.driver.duration * seek;
    this.seekTime = this.time / this.driver.duration;
    this.timeString = this.formatDurationFromSeconds(Math.floor(this.time));
    this.driver.currentTime = this.time;
    this.galgoPlayerService.setSeeking(false);
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

 onRightKey(): boolean {
    if (!this.isSeekable) {
        return false;
    }
    this.resetTimer();
    this.rightLeftSeek(true);
    return true;
}

  onLeftKey(): boolean {
    if (!this.isSeekable) {
      return false;
    }
    this.resetTimer();
    this.rightLeftSeek(false);
    return true;
  }

  rightLeftSeek(right: boolean = true) {
    if (this.isSeekable) {
      this.galgoPlayerService.setSeeking(true);
      this.isSeeking = true;
      this.resetTimer();
      if (!this.galgoPlayerService.driver.player.paused) {
        this.wasPlaying = true;
        this.galgoPlayerService.driver?.pause();
        this.galgoPlayerService.updateStatus((status) => (status.playing = false));
      }
      // Left or right time
      // this.time = right ? this.time + this.driver.duration / 100 : this.time - this.driver.duration / 100;
      this.time = right ? this.time + 10 : this.time -10;
      // Time inside duration
      this.time = this.time > this.driver.duration ? this.driver.duration : this.time < 0 ? 0 : this.time;

      this.seekTime = this.time / this.duration;
      this.timeString = this.formatDurationFromSeconds(Math.floor(this.time));
      this.changeDetector.detectChanges();
      this.movePlaybackSource.next(this.seekTime);

    }
  }

  onDeactivate(): void {
    if (this.wasPlaying) {
      this.galgoPlayerService.driver?.play();
      this.galgoPlayerService.updateStatus((status) => (status.playing = true));
      this.wasPlaying = true;
    }
  }

  resetTimer(): void {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(this.onDeactivate.bind(this), 3 * 1000);
  }

  seekbarClick(): void {
    if (this.wasPlaying) {
      this.galgoPlayerService.driver?.pause();
      this.galgoPlayerService.updateStatus((status) => (status.playing = false));
      this.wasPlaying = false;
    }else{
      this.galgoPlayerService.driver?.play();
      this.galgoPlayerService.updateStatus((status) => (status.playing = true));
      this.wasPlaying = true;
    }
  }

}

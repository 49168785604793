import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { PlayerName } from '@app/galgo-player/config';
import { PlayerDrivers } from '@app/galgo-player/models';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';

@Component({
  selector: 'ty-list-selector',
  templateUrl: './list-selector.component.html',
  styleUrls: ['./list-selector.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ListSelectorComponent }],
})
export class ListSelectorComponent extends NavigationComponent implements OnInit {
  @Output() subtitledChanged = new EventEmitter<number>();
  @Input() itemTracks: any[];
  driver: PlayerDrivers;
  playerName: PlayerName;
  selectedItem: string | number | null = 'none';
  subtitleIndex: boolean;

  constructor(
    public el: ElementRef,
    private customerDataService: CustomerDataService,
    private galgoPlayerService: GalgoPlayerService
  ) {
    super(el);
    this.driver = this.galgoPlayerService.getDriver();
    this.playerName = this.customerDataService.playerName;
   }

  ngOnInit(): void {
    if (this.itemTracks?.length > 0) {
      //Only need check this.driver.getCaptionsVisibility() in shakaplayer
      const captionsVisibility = this.playerName === 'shaka' ? this.driver.getCaptionsVisibility() : true;

      if (captionsVisibility || this.isAudioTrack()) {
        //item.enabled for theo itemtracks
        //item.mode for videojs itemtracks
        //item.active for shaka itemtracks
        const selectedItem = this.itemTracks.find(item => item.enabled || item.mode === 'showing' || item.active);

        if (selectedItem) {
            this.selectedItem = selectedItem.id;
            this.setDefaultActiveChildNodeId(selectedItem.id + 1);

        }
      }
    }
  }

  isAudioTrack(): boolean {
    return this.itemTracks.some(track => track.kind === 'audio');
  }

  changeSubtitle(subtitleId: number, subtitle?: string) {
    this.selectedItem = subtitleId;
    this.subtitledChanged.emit(subtitleId);
  }

  onUpKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onLeftKey(): boolean {
    return true;
  }

  onRightKey(): boolean {
    return true;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }
}

import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { IMenuOption } from '@app/layout/models/menu-option.interface';
import { IconList } from '@core/models/icon-list.enum';

export const itemsConfig: IMenuOption[] = [
  {
    id: 'home',
    name: 'menu.home',
    routerLink: AppRoutes.home,
    minimized: false,
    icon: 'gi-home',
    position: 0
  },
  {
    id: 'search',
    name: 'menu.searches',
    routerLink: AppRoutes.search,
    minimized: true,
    icon: 'gi-search',
    position: 1
  },
  {
    id: 'collections-menu',
    name: 'menu.collection',
    routerLink: AppRoutes.collectionsMenu,
    minimized: true,
    icon: 'gi-collections-menu',
    position: 2
  },
  {
    id: 'genres',
    name: 'menu.genres',
    routerLink: AppRoutes.genres,
    minimized: true,
    icon: 'gi-genres',
    position: 3
  },
  {
    id: 'favorites',
    name: 'menu.favourites',
    routerLink:  AppRoutes.mySpace,
    minimized: true,
    icon: 'gi-my-favorites',
    position: 4
  },
  {
    id: 'live',
    name: 'menu.live',
    routerLink:  AppRoutes.lives,
    minimized: false,
    icon: 'gi-live',
    position: 5
  },
  {
    id: 'purchases',
    name: 'menu.purchases',
    routerLink:  AppRoutes.purchases ,
    minimized: true,
    icon: 'gi-purchases',
    position: 6
  },
  {
    id: 'settings',
    name: 'menu.settings',
    routerLink: AppRoutes.settings,
    minimized: true,
    icon: 'gi-configuracion',
    position: 7
  },


];

export const logoConfig = {
  image: IconList.appLogo,
  showTitle: true
};

<ng-container *ngIf="data">
  <section class="open">
    <div class="modal-container">
      <div class="modal-container-inner">
        <div class="icon"
             *ngIf="data.icon">
          <img src="./assets/customer/icons/{{data.icon}}.svg"
               alt="Icono candado registro"
               class="icon" />
        </div>
        <div class="title">
          {{ data.title | translate }}
        </div>
        <p class="content"
           *ngIf="data.content">
          {{ data.content | translate }}
        </p>
        <div class="buttons-container">
          <ng-container *ngFor="let btn of data.buttons; let i = index">
            <div class="primary-button"
                 appNavigation
                 [navigableId]="i"
                 (click)="actionClicked(btn.action)">
              <button class="button button-medium">
                {{ btn.text | translate }}</button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</ng-container>

import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { IAuthToken } from '@app/auth/models/token.model';
import { User } from '@app/auth/models/user.model';
import { AuthService } from '@app/auth/services/auth-custom/auth.service';
import { UserService } from '@app/auth/services/user/user.service';
import { IconList } from '@app/core/models/icon-list.enum';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { KeyCodesNames } from '@app/core/navigation/config/keycodes.config';
import { platforms } from '@app/core/navigation/models/platforms.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { PlatformService } from '@app/core/navigation/services/platform.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { LanguageService } from '@app/core/services/language.service';
import { CustomErrorApi, UserLoginApi } from '@app/galgo-api/models';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { ModalActions } from '@app/shared/components/modal/config';
import { PASSWORD_CHANGE_REQUIRED_MODAL_DATA } from '@app/shared/components/modal/config/password-change-required.config';
import { ModalService } from '@app/shared/components/modal/services/modal.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as CryptoJS from 'crypto-js';
import { NGXLogger } from 'ngx-logger';
import { throwError } from 'rxjs';
import { CacheService } from '../../../../shared/services/cache.service';

@UntilDestroy()
@Component({
  selector: 'ty-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LoginFormComponent }]
})
export class LoginFormComponent extends NavigationComponent implements OnInit, AfterViewInit {


  @Output() isVirtualKeyboardVisible?: EventEmitter<boolean>;
  @ViewChild('email') email: ElementRef;
  @ViewChild('password') password: ElementRef;

  SSOMode: boolean;
  error: HttpErrorResponse;
  errorStatus: boolean;
  loginForm: FormGroup;
  rememberPassword: boolean;
  submitted: boolean;
  isKeyboardVisible: boolean;
  isLoading: boolean;
  hidePassword: boolean;

  errorInForm: boolean;

  get alertIconUrl(): string {
    return `./assets/icons/${IconList.alertIcon}#icon`;
  }

  get crossIconUrl(): string {
    return `./assets/icons/${IconList.crossIcon}#icon`;
  }

  get invalidEmail(): boolean {
    return !this.loginForm.get('email').valid && this.loginForm.get('email').dirty && this.loginForm.get('email').touched;
  }

  get loginFormControls() {
    return this.loginForm.controls;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private browsingHistoryService: BrowsingHistoryService,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private router: Router,
    private userService: UserService,
    private cacheService: CacheService,
    private customerDataService: CustomerDataService,
    private platformService: PlatformService,
    private modalService: ModalService,
    public el: ElementRef,
  ) {
    super(el);

    this.errorStatus = false;
    this.rememberPassword = false;
    this.submitted = false;
    this.hidePassword = true;
    this.SSOMode = !this.customerDataService?.emailLoginEnabled;

    this.isVirtualKeyboardVisible = new EventEmitter<boolean>();
    this.setDefaultActiveChildNodeId(0);

  }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    if (localStorage.getItem('email') && localStorage.getItem('password')) {
      const decrypt = CryptoJS.AES.decrypt(
        localStorage.getItem('password'),
        localStorage.getItem('email')
      ).toString(CryptoJS.enc.Utf8);

      this.loginForm.setValue({
        email: localStorage.getItem('email'),
        password: decrypt,
      });
      this.rememberPassword = true;
    }

  }

  clickInput(oField) {
    oField.setSelectionRange(oField.value.length, oField.value.length);
    if (oField.value.split('').length > 0) {
      this.isVirtualKeyboardVisible.emit(true);
    } else if (oField.value.split('').length === 0) {
      this.isVirtualKeyboardVisible.emit(false);
    }
  }

  checkForm() {
    if (this.errorStatus) {
      this.errorStatus = false;
      this.errorInForm = false;
    }
  }

  sendForm(): void {
    this.isVirtualKeyboardVisible.emit(false);
    this.submitted = true;
    this.errorStatus = false;
    if (this.loginForm.invalid) {
      this.submitted = false;
      return;
    }
    this.login();
  }

  resetForm(): void {
    this.submitted = false;
    this.errorStatus = false;
    this.errorInForm = false;
  }

  login(): void {
    this.deactivate();
    const loginData: UserLoginApi = {
      username: this.loginFormControls.email.value.toLowerCase(),
      password: this.loginFormControls.password.value,
    };

    this.authService.login(loginData, this.SSOMode).pipe(untilDestroyed(this)).subscribe(
      (token: IAuthToken) => {
        this.logger.debug('LoginForm -> Login: Success', token);
        this.initUserData();
      },
      (error: HttpErrorResponse) => {
        this.activate();
        if(error.error.error === CustomErrorApi.$4016){
          this.modalService.open(
            PASSWORD_CHANGE_REQUIRED_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction)
                      );
        }else{
          this.errorStatus = true;
          this.errorInForm = true;
          this.error = error;
          this.logger.error('LoginForm -> Login: Failed', error);
          this.selectChildNodeById(3);
        }
      }
    );
  }

  clickEmail(): void {
    this.selectChildNodeById(0);
  }

  clickPassword(): void {
    this.selectChildNodeById(1);
  }

  onEnterKey(): boolean {
    if (this.platformService.isWebOs() || this.platformService.platform === platforms.browser){
      if(this.getSelectedChildNodeId() === 0 || this.getSelectedChildNodeId() === 1){
        this.onDownKey();
      }
    }
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  /**
   * Handles the key press event.
   *
   * @param {} code - The key code of the pressed key.
   * @returns {} - Returns true to indicate the event has been handled.
   *
   * This function is specifically designed for Tizen platform. It maps the key codes to numbers and
   * updates the value of the selected form control (either 'email' or 'password') by inserting the
   * corresponding number at the cursor's position. If the 'intro' key is pressed, it triggers the
   * 'onDownKey' function.
   *
   * After updating the value, it sets the cursor back to its original position.
   */
   onKeyPress(code: string | number): boolean {
      if (this.platformService.isTizen()){
        const keyCodesToNumbers = {
          [KeyCodesNames.zero]: '0',
          [KeyCodesNames.one]: '1',
          [KeyCodesNames.two]: '2',
          [KeyCodesNames.three]: '3',
          [KeyCodesNames.four]: '4',
          [KeyCodesNames.five]: '5',
          [KeyCodesNames.six]: '6',
          [KeyCodesNames.seven]: '7',
          [KeyCodesNames.eight]: '8',
          [KeyCodesNames.nine]: '9',
        };

        if (code in keyCodesToNumbers) {
          const control = this.getSelectedChildNodeId() === 0 ? 'email' : 'password';
          const controlElement = document.getElementById(control) as HTMLInputElement;
          const currentVal = this.loginForm.controls[control].value;
          const cursorPosition = controlElement.selectionStart;
          const newVal = currentVal.slice(0, cursorPosition) + keyCodesToNumbers[code] + currentVal.slice(cursorPosition);
          this.loginForm.controls[control].patchValue(newVal);
          // Set the cursor back to its original position after updating the value
          setTimeout(() => controlElement.setSelectionRange(cursorPosition + 1, cursorPosition + 1), 0);
        } else if (code === KeyCodesNames.intro) {
          this.onDownKey();
        }
      }
      return true;
    }

  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
    return false;
    }

    if (this.getSelectedChildNodeId() === 3) {
      //In send btn (3), press up to hide errors
      this.errorStatus = false;
    }

    this.selectPreviousChildNode();
  //set cursor position to end of the input
  const control = this.getSelectedChildNodeId() === 0 ? 'email' : 'password';
  const controlElement = document.getElementById(control) as HTMLInputElement;
  const currentVal = this.loginForm.controls[control].value;
  setTimeout(() => {
    controlElement.setSelectionRange(currentVal.length, currentVal.length); // Set cursor position
    controlElement.scrollLeft = controlElement.scrollWidth; // Scroll to end
  }, 0);

    return true;
  }

  onRightKey(): boolean {
    const selectedNodeId = this.getSelectedChildNodeId();
    const inputElement = selectedNodeId === 0 ? this.email.nativeElement : selectedNodeId === 1 ? this.password.nativeElement : null;

    if (inputElement) {
      const currentPosition = inputElement.selectionStart;
      if (currentPosition < inputElement.value.length) {
        inputElement.scrollLeft += 10;
        return true;
      }
    }
    return false;
  }

  onDownKey(): boolean {
    if (this.getSelectedChildNodeId() === 1) {
      this.selectChildNodeById(3);
      return true;
    }
    this.selectNextChildNode();
    //set cursor position to end of the input
    const control = this.getSelectedChildNodeId() === 0 ? 'email' : 'password';
    const controlElement = document.getElementById(control) as HTMLInputElement;
    const currentVal = this.loginForm.controls[control].value;
    setTimeout(() => {
      controlElement.setSelectionRange(currentVal.length, currentVal.length); // Set cursor position
      controlElement.scrollLeft = controlElement.scrollWidth; // Scroll to end
    }, 0);
    return true;
  }

  onLeftKey(): boolean {
    const selectedNodeId = this.getSelectedChildNodeId();
    const inputElement = selectedNodeId === 0 ? this.email.nativeElement : selectedNodeId === 1 ? this.password.nativeElement : null;

    if (inputElement) {
      const currentPosition = inputElement.selectionStart;
      if (currentPosition > 0) {
        inputElement.scrollLeft -= 10;
        return true;
      }
    }

    return false;
  }

    private manageModalAction(action: ModalActions): void {
      switch(action) {
        case ModalActions.close:
        default:
          this.activate();
          this.selectChildNodeById(3);
          break;
      }
    }

   private initUserData(): void {
    this.userService.getUserDataFromServer().subscribe({
      next: (user: User) => {
        this.logger.debug('LoginForm -> GetUserData: Success', user);
        this.analyticsService.logEvent(GalgoEvent.userLogin);
        this.setLanguage(user.favoriteLanguageCode);
        this.browsingHistoryService.removeAllFocusData();
        this.cacheService.clearCache();
        this.router.navigate([AppRoutes.home]);
      },
      error: (error: HttpErrorResponse) => {
        this.logger.error('LoginForm -> GetUserData: Failed', error);
        return throwError(() => new HttpErrorResponse(error));
      },
    });
  }

  //TODO: implement language service
  private setLanguage(id: string) {
    this.languageService.use(id);
    this.languageService.currentLang = id;
    this.document.documentElement.lang = id;
    this.settingsService.languageSet$.next();
  }


}

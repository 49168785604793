import { DOCUMENT } from '@angular/common';
import { AfterContentChecked, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { NavigationModeService } from '@app/core/navigation/services/navigation-mode.service';
import { SideMenuService } from '@app/core/navigation/services/sidemenu.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { IMenuOption } from '@app/layout/models/menu-option.interface';
import { CustomerDataService } from '@core/services/customer-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import disableLogoPages from './disable-logo-pages.config.json';
import { itemsConfig } from './menu-conf.config';
import { MenuItems } from './menu-items.enum';

@UntilDestroy()
@Component({
  selector: 'ty-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: SideMenuComponent }]
})
export class SideMenuComponent extends NavigationComponent implements OnInit, AfterContentChecked {

  items: IMenuOption[] = itemsConfig;
  routeActive: string;
  isSideMenuOpenedCursor = false;
  isCursorMode: boolean;
  isMouseOver = false;
  collectionMenuItem: boolean;
  genrerMenuItem: boolean;
  purchasesMenuItem: boolean;
  channelsMenuItem: boolean;
  liveMenuItem: boolean;

  get appLogo(): string {
    return this.customerDataService.anagramFile;
  }

  get isMinimized(): boolean {
    const currentItem = this.getCurrentItem();
    return currentItem?.minimized && (!this.isSideMenuOpened || this.isCursorMode);
  }

  get hideLogo(): boolean {
    const currentItem = this.getCurrentItem();
    if (this.featureFlagsService.currentFeaturesValue.hideLogoInSearchMyspace) {
      if (currentItem?.id && disableLogoPages.pages.includes(currentItem.id) || currentItem?.id === undefined) {
        return true;
      }
    }
    return false;
  }

  get showTitle(): boolean {
    return this.featureFlagsService.currentFeaturesValue.showTitleInMenu;
  }

  get isSideMenuOpened(): boolean {
    if (this.isCursorMode) {
      return this.isSideMenuOpenedCursor;
    } else {
      return !(this.document.querySelectorAll('ty-menu-item:focus').length === 0);
    }
  }

  constructor(
    private customerDataService: CustomerDataService,
    private router: Router,
    private navigationModeService: NavigationModeService,
    private featureFlagsService: FeatureFlagsService,
    private sideMenuService: SideMenuService,
    private logger: NGXLogger,
    @Inject(DOCUMENT) private document: Document,
    public el: ElementRef
  ) {
    super(el);
    this.collectionMenuItem = this.featureFlagsService?.currentFeaturesValue?.menuItemCollections;
    this.genrerMenuItem = this.featureFlagsService?.currentFeaturesValue?.menuItemGenres;
    this.purchasesMenuItem = this.featureFlagsService?.currentFeaturesValue?.menuItemPurchases;
    this.channelsMenuItem = this.featureFlagsService?.currentFeaturesValue?.menuItemChannels;
    this.liveMenuItem = this.featureFlagsService?.currentFeaturesValue?.menuItemLive;
    this.listenMenuItemSelection();
    }

  ngOnInit(): void {
    this.navigationModeService.cursor.pipe(untilDestroyed(this)).subscribe({
      next: (isCursorMode: boolean) => {
        this.isCursorMode = isCursorMode;
      },
      error: (error) => {
        this.isCursorMode = false;
      }
    });
  }

  ngAfterContentChecked(): void {
    this.routeActive = this.router.url;
    const currentItemPosition = this.getCurrentItem()?.position;
    if (typeof currentItemPosition === 'number' ) {
      this.setDefaultActiveChildNodeId(currentItemPosition);
    } else {
      this.setDefaultActiveChildNodeId(0);
    }
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return false;
  }

  onDownKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onUpKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    return false;
  }

  openMenu(): void {
    this.isSideMenuOpenedCursor = true;
  }

  closeMenu(): void {
    this.isMouseOver = false;
    if (this.isSideMenuOpenedCursor) {
      setTimeout(() => {
        this.isSideMenuOpenedCursor = this.isMouseOver;
      }, 500);
    }
  }

  getCurrentItem(): IMenuOption {
    return this.items.find(
      (item: IMenuOption) => this.routeActive === item.routerLink
    );
  }

  shouldDisplayItem(item) {
    if (!this.collectionMenuItem && item.name === MenuItems.collectionsMenu) {
        return false;
    }
    if (!this.genrerMenuItem && item.name === MenuItems.genres) {
        return false;
    }
    if (!this.purchasesMenuItem && item.name === MenuItems.purchases) {
        return false;
    }
    if (!this.liveMenuItem && item.name === MenuItems.live) {
        return false;
    }
    if (!this.channelsMenuItem && item.name === MenuItems.channels) {
        return false;
    }
    return true;
  }

  private listenMenuItemSelection() {
    this.sideMenuService.menuItemSelected$.pipe(untilDestroyed(this)).subscribe({
      next: (id: string) => {
        const found = this.items.find(item => item.id === id);
        if (found && this.shouldDisplayItem(found)) {
          this.selectChildNodeById(found.position);
          return;
        }

        this.logger.warn('SideMenuComponent -> listenMenuItemSelection(): Node does not exist, selecting node 0', id);
        const firstItem = this.items.find(item => item.id === 'home');
        this.selectChildNodeById(firstItem.position);
      }
    });
  }

}

import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { IModalData } from '@app/shared/models/modal-data.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { ModalActions } from '../config';
import { ModalComponent } from '../modal.component';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private componentRef!: ComponentRef<ModalComponent>;
  private closeCallback: ((action: ModalActions) => void) | null = null;
  private parent: NavigationComponent;
  private closeModalSubject = new Subject<void>();

  get closeModal$(): Observable<void> {
    return this.closeModalSubject.asObservable();
  }


  constructor(
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private logger: NGXLogger
    ) {
      this.parent = null;
    }
    emitCloseModalEvent(): void{
      this.closeModalSubject.next();
    }

  open(data: IModalData, parent: NavigationComponent, closeCallback?: (action: ModalActions) => void): void {
    this.parent = parent;
    //Create component Ref
    const factory = this.resolver.resolveComponentFactory(ModalComponent);
    const componentRef = factory.create(this.injector);
    const navigableId = 100;

    //Assign data to component
    componentRef.instance.data = data;
    componentRef.instance.closeModal.pipe(untilDestroyed(this)).subscribe(
      (action: ModalActions) => this.close(action),
      (error) => this.logger.error('ModalService -> open(): Failed', error)
    );

    //Attach view
    this.appRef.attachView(componentRef.hostView);
    const modalContainer = document.createElement('div');
    modalContainer.appendChild(componentRef.location.nativeElement);

    parent.el.nativeElement.appendChild(modalContainer);


    this.componentRef = componentRef;
    this.componentRef.instance.navigableId = navigableId;
    this.componentRef.instance.activate();

    parent.appendChildNode(this.componentRef.instance);
    parent.selectChildNodeById(navigableId);
    this.closeCallback = closeCallback || null;
  }

  close(action: ModalActions): void {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.instance.deactivate();
      this.componentRef.destroy();
      this.componentRef = null;

      if (this.closeCallback) {
        this.closeCallback(action);
        this.closeCallback = null;
      }
      this.parent.popChildNode();
    }
    this.parent = null;
  }
}

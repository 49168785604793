import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  CustomErrorApi,
  ErrorResponseApi,
  ImageSizeApi,
  PaidItemsResponseApi,
  SubscriptionInfoApi,
  SubtitleApi,
  UserFrontApi,
  VideoAccessApi
} from '@api/models';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { ISubscriptionResult } from '@app/auth/models/check-subscription.interface';
import { SubscriptionResults } from '@app/auth/models/subscription-results.enum';
import { CheckSubscriptionService } from '@app/auth/services/subscription/check-subscription.service';
import { UserService } from '@app/auth/services/user/user.service';
import { ActionList } from '@app/core/models/action-list.enum';
import { Container } from '@app/core/models/container.model';
import { ILayoutPayment } from '@app/core/models/layout-payment.interface';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { PlayingService } from '@app/core/services/playing.service';
import { headerTrailerOptions } from '@app/galgo-player/config/galgo-player.config';
import { IGalgoPlayerOptions, PlayerDetailType } from '@app/galgo-player/models';
import { IDrmConfig } from '@app/galgo-player/models/drm-config.interface';
import {
  GENREIC_ERROR_MODAL_DATA,
  ModalActions,
  REGISTER_MODAL_DATA
} from '@app/shared/components/modal/config';
import { TabContentSelector } from '@app/shared/components/tabs/models/tab-label.enum';
import { Collection } from '@app/shared/models/collection/collection.model';
import { ItemTypes } from '@app/shared/models/item-types.enum';
import { PlayerStatus } from '@app/shared/models/player-status.model';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { PlayerDetailsService } from '@app/shared/services/player-details.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IButtonConfig } from '@shared/components/button-list/button-config.interface';
import { IDetailConfig } from '@shared/components/detail/detail-config';
import { ModalService } from '@shared/components/modal/services/modal.service';
import { ITab } from '@shared/components/tabs/models/tabs.interface';
import { TabsService } from '@shared/components/tabs/services/tabs.service';
import { Video } from '@shared/models/video/video.model';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { videoPlayerEvents } from '../../player/player/config/video-details.config';
import { SettingsService } from '../../settings/services/settings.service';
import {
  TabsConfigurationCollections,
  TabsConfigurationMicrosite,
  TabsConfigurationSerie,
  TabsConfigurationVideo
} from '../configuration/tabs.config';
import { subscriptionConfig } from '../configuration/video-detail.config';
import { CollectionDetailService } from '../services/collection-detail.service';
import { VideoDetailService } from '../services/video-detail.service';
import {
  rentButtonConfig,
  subscriptionButtonConfig,
  videoDetailConfig
} from './media-detail.config';

@UntilDestroy()
@Component({
  selector: 'ty-media-detail',
  templateUrl: './media-detail.component.html',
  styleUrls: ['./media-detail.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: MediaDetailComponent },
  ],
})
export class MediaDetailComponent
  extends NavigationComponent
  implements OnInit, AfterViewInit {
  @HostBinding('style.backgroundColor') backgroundColor: string;

  actionClickEvent: string;
  adsUrl: string;
  suscriptionAlternativeText: boolean;
  rentAlternativeText: boolean;
  blockVideoResult: ISubscriptionResult;
  drmConfig: IDrmConfig;
  itemType: ItemTypes;
  containerType: string;
  isHeaderHidden: boolean;
  isKeyboardVisible: boolean;
  isLoading: boolean;
  isModalOpen: boolean;
  isTrailer: boolean;
  mediaItem: Video;
  options: IGalgoPlayerOptions;
  playerDetails: PlayerDetailType;
  price: string;
  purchases: PaidItemsResponseApi[];
  seasons: Container[];
  selectedTab: string;
  showBackButton: boolean;
  showButtonText = true;
  showConfirm: boolean;
  showTrailer: boolean;
  showPpvModal = false;
  subscriptionIds: string[] = [];
  trailer: string;
  trailerFlag: boolean;
  trailerStart: boolean;
  videoDetailBtnListConfig: IButtonConfig[];
  videoDetailConfig: IDetailConfig;
  videoSubtitles: SubtitleApi[];
  currentUrl: string;
  returnSelectedTab: ITab;
  mediaItems: MediaItem<MediaItemTypes>[];
  actualFocus: number;
  purchaseEnd: string;
  showSubscriptionMessage: boolean;
  showPPVMessage: boolean;
  mediaId: string;
  mediaMetas: Video | Collection;
  userPurchases: SubscriptionInfoApi;
  private _ppvSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get ppv(): string {
    return this._ppvSubject.value;
  }

  set ppv(value: string) {
    this._ppvSubject.next(value);
  }

  get ppv$() {
    return this._ppvSubject.asObservable();
  }

  get blockVideoResultReason(): SubscriptionResults {
    return this.blockVideoResult.reason;
  }

  get blockNoUserAuthenticated(): boolean {
    return (
      this.blockVideoResult?.reason === SubscriptionResults.noUserAuthenticated
    );
  }

  get blockNoUserSubscriptions(): boolean {
    return (
      this.blockVideoResult?.reason === SubscriptionResults.noUserSubscriptions
    );
  }

  get blockSubscriptionError(): boolean {
    return (
      this.blockVideoResult?.reason === SubscriptionResults.subscriptionError
    );
  }

  get blockPpvError(): boolean {
    return this.blockVideoResult?.reason === SubscriptionResults.ppvError;
  }

  get blockNoUserSubscriptionsAndPpv(): boolean {
    return (
      this.blockVideoResult?.reason ===
      SubscriptionResults.noUserSubscriptionsAndPpv
    );
  }

  get payPerView(): ILayoutPayment {
    return this.mediaMetas?.layout?.ppv;
  }

  get products(): string[] {
    return this.mediaItem?.products;
  }

  get subscription(): ILayoutPayment {
    return this.mediaMetas?.layout?.subscription;
  }

  get tabs(): ITab[] {
    return this.tabsService.tabs;
  }

  get thumbnail(): string {
    const backdrops = this.mediaMetas?.thumbnail.backdrops;
    const landscapes = this.mediaMetas?.thumbnail?.landscapes;
    const landscape = this.mediaMetas?.thumbnail?.landscape;
    const preferenceOrder = [ImageSizeApi.Medium, ImageSizeApi.High, ImageSizeApi.Original];

    if (backdrops?.length > 0) {
      const found = preferenceOrder.map(preference => backdrops.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultThumbnail;
    }

    if (landscapes?.length > 0) {
      const found = preferenceOrder.map(preference => landscapes.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultThumbnail;
    }
    return landscape ?? this.defaultThumbnail;
  }

  get defaultThumbnail(): string {
    return this.customerDataService.defaultLandscape;
  }


  get videoTitle(): string {
    return this.mediaMetas?.title;
  }

  get videoId(): string {
    return this.mediaMetas?.id;
  }

  get videoSlug(): string {
    return this.mediaMetas?.slug ?? this.videoId;
  }

  get categorySlug(): string {
    return this.mediaMetas?.layout?.topic?.[0]?.slug ?? 'purchases';
  }

  constructor(
    public logger: NGXLogger,
    public el: ElementRef,
    private analyticsService: AnalyticsService,
    private browserHistoryService: BrowsingHistoryService,
    private changeDetector: ChangeDetectorRef,
    private checkSubscriptionService: CheckSubscriptionService,
    private collectionDetailService: CollectionDetailService,
    private customerDataService: CustomerDataService,
    private featureFlagsService: FeatureFlagsService,
    private galgoPlayerService: GalgoPlayerService,
    private location: Location,
    private modalService: ModalService,
    private playerDetailsService: PlayerDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private tabsService: TabsService,
    private userService: UserService,
    private videoDetailService: VideoDetailService,
    private browsingHistoryService: BrowsingHistoryService,
    private playingService: PlayingService
  ) {
    super(el);
    this.suscriptionAlternativeText = false;
    this.rentAlternativeText = false;
    this.isHeaderHidden = false;
    this.isKeyboardVisible = false;
    this.isModalOpen = false;
    this.isTrailer = false;
    this.options = headerTrailerOptions;
    this.settingsService.lastView$ = 'detail-info';
    this.showBackButton = this.featureFlagsService?.currentFeaturesValue?.showBackButton;
    this.showConfirm = false;
    this.showSubscriptionMessage = false;
    this.showTrailer = true;
    this.trailerFlag = this.featureFlagsService?.currentFeaturesValue?.enableTrailers;
    this.trailerStart = false;
    this.currentUrl = '';
    this.backgroundColor = '';
    this.videoDetailBtnListConfig = [];
    this.videoDetailConfig = videoDetailConfig;
    this.blockVideoResult = subscriptionConfig;
    this.setDefaultActiveChildNodeId(2);
    this.getUserProducts();
  }

  ngOnInit(): void {
    this.galgoPlayerService.reset();
    this.tabsService.resetTabsActive();
    this.urlMediaType();
    this.getMediaIdFromUrl();
    this.selectTabsConfig();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.getPlayerEvents();
  }

  urlMediaType(): void {
    const urlActual = this.location.path();
    const types = /(\/video|\/series|\/microsite|\/collection)/;
    const matches = urlActual.match(types);

    if (matches && matches.length > 0) {
      const lowerItemType = matches[0].substring(1);
      this.containerType = lowerItemType.charAt(0).toUpperCase() + lowerItemType.slice(1);
    }
  }

  selectTabsConfig(): void {
    switch (this.containerType) {
      case 'Video':
        this.tabsService.setTabsConfig(TabsConfigurationVideo);
        break;
      case 'Series':
        this.tabsService.setTabsConfig(TabsConfigurationSerie);
        break;
      case 'Collection':
        this.tabsService.setTabsConfig(TabsConfigurationCollections);
        break;
      case 'Microsite':
        this.tabsService.setTabsConfig(TabsConfigurationMicrosite);
        break;
      default:
        this.logger.debug('MediaDetailComponent => Error in selectTabsConfig()');
        break;
    }
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onUpKey(): boolean {
    const selectedChildNodeId = this.getSelectedChildNodeId();
    const showBackButton = this.featureFlagsService.currentFeaturesValue.showBackButton;

    if (selectedChildNodeId > 0) {
      if (showBackButton && selectedChildNodeId === 2) {
        this.selectChildNodeById(0);
      } else if (!showBackButton && selectedChildNodeId <= 2) {
        return true;
      } else {
        this.selectPreviousChildNode();
      }
    }

    return true;
  }

  onDownKey(): boolean {
    if (
      this.getSelectedChildNodeId() === 1 ||
      this.getSelectedChildNodeId() === 2
    ) {
      this.isHeaderHidden = true;
      this.backgroundColor = this.getScrimColor();
      this.selectChildNodeById(3);
    }

    this.selectNextChildNode();
    return true;
  }

  onRightKey(): boolean {
    if (this.getSelectedChildNodeId() === 1) {
      this.selectNextChildNode();
    }
    return true;
  }

  onLeftKey(): boolean {
      if (this.getSelectedChildNodeId() === 2) {
        this.selectPreviousChildNode();
        return true;
      } else {
        this.tabsService.resetTabsActive();
        this.actualFocus = this.getSelectedChildNodeId();
        return false;
      }
  }

  onBackKey(): boolean {

    const tabContents = [
      TabContentSelector.similar,
      TabContentSelector.chapters,
      TabContentSelector.moreInformation,
      TabContentSelector.details
    ];

    if (this.isHeaderHidden || tabContents.includes(this.settingsService.lastView$ as TabContentSelector)) {
    this.closeTabs();
    this.settingsService.lastView$ = '';
    } else {
      this.browserHistoryService.goBack();
    }

    return true;
  }

  onActivate(): void {
    if (this.actualFocus > 2) {
      this.selectChildNodeById(3);
    }else{
      this.selectChildNodeById(1);
    }
  }

  closeTabs() {
    this.isHeaderHidden = false;
    this.backgroundColor = '';
    this.settingsService.lastView$ = '';
    this.tabsService.resetTabsActive();
    setTimeout(() => {
      this.selectChildNodeById(1);
    }, 0);
  }

  selectTab(selectedTab: ITab) {
    this.selectedTab = selectedTab.content_selector;
    this.isHeaderHidden = true;
    this.backgroundColor = this.getScrimColor();
  }

  getVideoMetas(): void {
    this.showTrailer = false;

    this.videoDetailService
      .getVideoDetails(this.mediaId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (videoMetas: Video) => {
          this.mediaMetas = videoMetas;
          this.mediaItem = videoMetas;
          this.itemType = ItemTypes.Video;
          this.trailer = this.mediaMetas.trailer || '';
          if(this.trailer){
            this.isTrailer = true;
          }
          this.drmConfig =  undefined;
          this.videoSubtitles = [];
          if (this.mediaMetas instanceof Video) {
            this.generatePlayerDetails(this.mediaItem);
            this.galgoPlayerService.updateStatus((status) => {
              status.url = (this.mediaMetas as Video).trailer;
              this.showTrailer = true;
              this.getPlayerEvents();
            });
          }
          this.logger.debug(
            'VideoDetailComponent -> GetVideoMetas(): Success',
            videoMetas
          );
          this.analyticsService.logEvent(GalgoEvent.videoDetail, {
            video: this.mediaMetas,
          });
          setTimeout(() => {
            this.selectChildNodeById(1);
            this.lastVisitedTab();
          }, 200);
        },
        complete: () => {
          this.showButtons();
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error(
            'VideoDetailComponent -> GetVideoMetas(): Failed',
            error
          );
          if (error?.error?.error === CustomErrorApi.$4042) {
            this.router.navigate([AppRoutes.home]);
          }
        }
      });
  }

  getCollectionMetas() {
    this.collectionDetailService
      .getCollectionDetails(this.mediaId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (collectionMetas: Collection) => {
          this.mediaMetas = collectionMetas;
          this.itemType = ItemTypes.Collection;
          this.logger.debug(
            'CollectionDetailComponent -> GetCollectionMetas(): Success',
            collectionMetas
          );
          this.changeDetector.detectChanges();
          setTimeout(() => {
            this.selectChildNodeById(1);
            this.lastVisitedTab();
          }, 200);
        },
        complete: () => {
          this.showButtons();
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error(
            'CollectionDetailComponent -> GetCollectionMetas(): Failed',
            error
          );
          if (error?.error?.error === CustomErrorApi.$4043) {
            this.router.navigate([AppRoutes.home]);
          }
        }
      });
  }

  handleFocusData(focusData, tabContentSelector): void {
    const tab = focusData?.[tabContentSelector];
    if(tab){
      this.returnSelectedTab = this.tabsService.selectTab(TabContentSelector[tabContentSelector]);
      this.isHeaderHidden = true;
      this.backgroundColor = this.getScrimColor();
      this.selectTab(this.returnSelectedTab);
      setTimeout(() => {
        this.selectChildNodeById(4);
      }, 200);
    }
  }

  lastVisitedTab(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    //check the last visited tab
    if (focusData?.visitedTab?.similarTab) {
      this.handleFocusData(focusData, 'similar');
    }
    if (focusData?.visitedTab?.chaptersTab) {
      this.handleFocusData(focusData, 'chapters');
    }
  }

  clickEvent(action: string): void {
    this.actionClickEvent = action;
    if (this.userService.isLoggedIn()){
      this.userService.setNewUserInfo();
    }
    this.checkSubscription();

    if (!this.mediaMetas?.id) {
      this.router.navigate([AppRoutes.home]);
      return;
    }

    switch (this.actionClickEvent) {
      case ActionList.play:
        this.handlePlayAction();
        break;
      case ActionList.rent:
        this.ppvPrice();
        this.handleRentAction();
        break;
      default:
        this.router.navigate([AppRoutes.home]);
        break;
    }
  }

  private handlePlayAction(): void {
    if (this.blockVideoResult.result) {
      this.handleSubscriptionResult(this.blockVideoResult.reason);
      } else {
       this.handleContainerNavigation();
    }
  }

  private handleRentAction(): void {
    if (this.blockVideoResult.result) {
      this.handleRentResult(this.blockVideoResult.reason);
    } else {
      this.handleContainerNavigation();
    }
  }

  private handleRentResult(reason: SubscriptionResults): void {
    switch (reason) {
        case SubscriptionResults.noUserAuthenticated:
            this.openModal(REGISTER_MODAL_DATA);
            break;
        case SubscriptionResults.ppvError:
            this.showModalWithAction(this.ppv, 10, () => this.showPpvModal = false);
            break;
        case SubscriptionResults.noUserSubscriptionsAndPpv:
            this.showModalWithAction(this.ppv, 10, () => this.showPpvModal = false);
            break;
        default:
            this.openModal(GENREIC_ERROR_MODAL_DATA);
            break;
    }
  }

  private handleSubscriptionResult(reason: SubscriptionResults): void {
      switch (reason) {
          case SubscriptionResults.noUserAuthenticated:
              this.openModal(REGISTER_MODAL_DATA);
              break;
          case SubscriptionResults.noUserSubscriptions:
          case SubscriptionResults.noUserSubscriptionsAndPpv:
          case SubscriptionResults.subscriptionError:
              this.showModalWithAction('', 10, () => this.showPpvModal = false);
              break;
          default:
              this.openModal(GENREIC_ERROR_MODAL_DATA);
              break;
      }
  }

  private handleContainerNavigation(): void {
      switch (this.containerType) {
          case 'Video':
              this.analyticsService.logEvent(GalgoEvent.videoFromDetail, { video: this.mediaMetas });
              this.router.navigate([AppRoutes.player, this.mediaId]);
              break;
          case 'Series':
              if (this.isRegistrationRequiredForSeries()) {
                  this.openModal(REGISTER_MODAL_DATA);
              } else {
                  this.playLastChapter();
              }
              break;
          case 'Collection':
          case 'Microsite':
              break;
          default:
              this.logger.debug('MediaDetailComponent => Error in clickEvent()');
              this.router.navigate([AppRoutes.home]);
              break;
      }
  }

  private isRegistrationRequiredForSeries(): boolean {
      return (this.mediaMetas as Collection)?.subContainers[0]?.videos[0]?.access === VideoAccessApi.Register
          && !this.userService.isLoggedIn();
  }

  private openModal(modalData: any): void {
      this.modalService.open(modalData, this, (modalAction: ModalActions) => this.manageModalAction(modalAction));
  }

  private showModalWithAction(price: string, nodeId: number, onClose: () => void): void {
    this.price = price;
    this.showPpvModal = true;
    setTimeout(() => this.selectChildNodeById(nodeId), 0);
      this.modalService.closeModal$.pipe(untilDestroyed(this)).subscribe({
          next: () => {
              onClose();
              this.retryCheckSubscription();
              this.showButtons();
              this.selectChildNodeById(1);
          },
          error: (error) => {
              this.logger.error('modalService -> closeModal: Error CloseModal$', error);
              throw error;
          }
    });
  }

  private playLastChapter() {
    const params = {
      language: this.userService.currentUserLanguage
    };

    this.playingService.meControllerGetMe(params).pipe(untilDestroyed(this)).subscribe({
      next: (response) => {
        const videoId = this.checkIdsInCollection(response);
        this.analyticsService.logEvent(GalgoEvent.videoFromContainer, {
          containerId: this.mediaId,
        });
        this.router.navigate([AppRoutes.player, videoId || (this.mediaMetas as Collection)?.subContainers[0]?.videos[0]?._id,]);
      },
      error: (error) => {
        this.logger.error('PlayingService -> getPlayingVideos: Error', error);
      }
    });
  }

  private getUserProducts(): void {
    this.userService.getUserProducts().pipe(untilDestroyed(this)).subscribe({
      next: (response) => {
        this.userPurchases = response;
      },
      complete: () => {
        this.calculatePpvEndDate();
      },
      error: (error) => {
        this.logger.error('MediaDetailComponent -> getUserProducts(): Failed', error);
      }
    });
  }

  private checkIdsInCollection(userData: UserFrontApi): string | null {
      const foundIds = userData?.playings
        .map(playing => (this.mediaMetas as Collection)?.subContainers
          .map(subContainer => subContainer?.videos)
          .reduce((acc, videos) => acc.concat(videos), [])
          .find(video => video._id === playing?.id)?._id)
        .filter(id => id !== undefined);

      return foundIds?.length > 0 ? foundIds[foundIds.length - 1] : null;
  }

  private generatePlayerDetails(mediaItem: MediaItem<Video>) {
    this.playerDetails = this.playerDetailsService.generatePlayerDetails(
      mediaItem,
      this.trailer,
      videoPlayerEvents,
      this.videoSubtitles,
      this.adsUrl,
      this.drmConfig
    );
    this.logger.info('VideoDetailsComponent => generatePlayerDetails: details', this.playerDetails);

  }

  private manageModalAction(action: ModalActions): void {
    switch(action) {
      case ModalActions.identify:
        this.router.navigate([AppRoutes.login]);
        break;
      case ModalActions.retry:
        this.clickEvent(this.actionClickEvent);
        break;
      case ModalActions.close:
      default:
        this.selectChildNodeById(1);
        break;
    }
  }

  private getPlayerEvents() {
    this.galgoPlayerService
      .select((status) => status.ended)
      .pipe(untilDestroyed(this))
      .subscribe(
        (status: PlayerStatus) => {
          if (status.ended) {
            this.trailerStart = false;
            setTimeout(() => {
              this.showTrailer = false;
            }, 600);
          }
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'VideoDetailComponent -> EndedChanges(): Failed',
            error
          );
        }
      );
    this.galgoPlayerService
      .select((status) => status.playing)
      .pipe(untilDestroyed(this))
      .subscribe(
        (status: PlayerStatus) => {
          if (status.playing) {
            this.trailerStart = true;
          }
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'VideoDetailComponent -> PlayingChanges(): Failed',
            error
          );
        }
      );
  }

  private getMediaIdFromUrl(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe({
      next: (params: Params) => {
        this.isHeaderHidden = false;
        this.backgroundColor = '';
        this.tabsService.resetTabsActive();
        this.mediaId = params.id;

        if(this.containerType === 'Video'){
          this.getVideoMetas();
        } else {
          this.getCollectionMetas();
        }
        this.logger.debug(
          'VideoDetailComponent -> GetVideoIdFromUrl(): Success',
          params);
        },
        error: (error: ErrorResponseApi) => {
          this.logger.error(
            'VideoDetailComponent -> GetVideoIdFromUrl(): Failed',
            error
            );
            this.router.navigate([AppRoutes.home]);
          }
    });
  }

  private ppvPrice(): void {
    if (this.mediaItem?.products) {
      const matchingUserPurchases = this.userPurchases?.products.filter(userProduct =>
        this.mediaItem?.products.some(mediaProduct => mediaProduct === userProduct._id)
      );
      if (matchingUserPurchases) {
        const productIndex = matchingUserPurchases.findIndex(userProduct => userProduct.type === 'Product');
        if (productIndex !== -1) {
          this.ppv = (matchingUserPurchases[productIndex].prices[0].value / 100).toString();
        }else{
          this.ppv = '';
        }
      }
    }
  }

  private checkSubscription() {
    if (this.mediaMetas) {
      this.blockVideoResult = this.checkSubscriptionService.blockMedia(
        this.mediaMetas,
        this.purchases,
      );
    }
  }

  private async showButtons(): Promise<void> {

    this.videoDetailBtnListConfig = [];
    this.subscriptionIds = [];
    this.showSubscriptionMessage = false;
    this.showPPVMessage = false;

    if (this.userService.isLoggedIn()) {
      try {
        this.purchases = await this.checkSubscriptionService.getPurchases();
      } catch {
        this.logger.error('MediaDetailComponent -> showButtons(): Failed');
      }
    }


    const userProducts = this.userService.userProducts;
    if (userProducts && Array.isArray(userProducts.subscriptions)) {
        userProducts.subscriptions.forEach(subscription => {
            this.subscriptionIds.push(subscription.id);
        });
    }

    if (!this.userService.isLoggedIn()) {
        this.applyDefaultButtonLogic();
        this.selectChildNodeById(1);
        return;
    }
    const subscriptionList = this.mediaItem?.products?.map(product => product) || [];
    const hasUserSubscription = this.userService.userProducts?.subscriptions
    ? this.userService.userProducts?.subscriptions?.some(subscription => subscriptionList.includes(subscription?.id))
    : false;
    const isPurchased = this.purchases?.some(purchase => purchase?._id === this.mediaItem?.id);
    const requiresSubscription = !!this.mediaItem?.layout?.subscription?._id;

    if (isPurchased && requiresSubscription && !hasUserSubscription) {
        this.addPPVButton();
        this.updateSecondaryText('rent', true);
        this.addSubscriptionButton();
        this.updateSecondaryText('subscription', false);
        this.selectChildNodeById(1);
        this.showPPVMessage = true;

    } else if (hasUserSubscription) {
        this.addSubscriptionButton();
        this.updateSecondaryText('subscription', true);
        this.selectChildNodeById(1);

    } else if (isPurchased) {
        this.addPPVButton();
        this.updateSecondaryText('rent', true);
        this.selectChildNodeById(1);
        this.showPPVMessage = true;

    } else {
        this.applyDefaultButtonLogic();
        this.selectChildNodeById(1);
    }
  }

  private calculatePpvEndDate(): void {

    if (!this.userPurchases || !this.products) {
        return;
    }

    const matchingProduct = this.userPurchases.products.find((purchase) =>
        this.products.includes(purchase._id)
    );

    if (matchingProduct && matchingProduct.endDate) {
      this.purchaseEnd = this.formatDate(new Date(matchingProduct.endDate));
    }
  }
  private formatDate(date: Date): string {
    return new Intl.DateTimeFormat('es-ES', {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date);
  }

  private applyDefaultButtonLogic(): void {
    const hasSubscription = !!this.subscription?.name?.value;
    const hasPPV = !!this.payPerView?.price_id;

    if (hasSubscription && hasPPV) {
      this.addSubscriptionButton();
      this.addPPVButton();
      this.updateSecondaryText('rent', false);
      this.updateSecondaryText('subscription', false);

    } else if (hasSubscription) {
      this.addSubscriptionButton();
      this.updateSecondaryText('subscription', false);

    } else if (hasPPV) {
      this.addPPVButton();
      this.updateSecondaryText('rent', false);

    } else {
      this.addSubscriptionButton();
      this.updateSecondaryText('subscription', true);
    }
  }

  private updateSecondaryText(buttonId: string, secondaryText: boolean): void {
    const index = this.videoDetailBtnListConfig.findIndex(btn => btn.id === buttonId);
    if (index !== -1) {
      this.videoDetailBtnListConfig[index].secondaryText = secondaryText;
    }
  }

  private addSubscriptionButton(): void {
    const buttonExists = this.videoDetailBtnListConfig.some(btn => btn === subscriptionButtonConfig);
    if (!buttonExists) {
      this.videoDetailBtnListConfig.push(subscriptionButtonConfig);
    }
  }

  private addPPVButton(): void {
    const buttonExists = this.videoDetailBtnListConfig.some(btn => btn === rentButtonConfig);
    if (!buttonExists) {
      this.videoDetailBtnListConfig.push(rentButtonConfig);
    }
  }

  private async retryCheckSubscription(): Promise<void>  {
    try {
      this.purchases = await this.checkSubscriptionService.getPurchases();
    } catch {
      this.logger.error('MediaDetailComponent -> retryCheckSubscription(): Failed');
    }

    this.checkSubscription();
  }

  private getScrimColor(): string {
    const opacityDecimal = 0.7; // Opacity
    const scrimColor = getComputedStyle(this.el.nativeElement).getPropertyValue('--scrim-color').trim();
    const r = parseInt(scrimColor.substring(1, 3), 16);
    const g = parseInt(scrimColor.substring(3, 5), 16);
    const b = parseInt(scrimColor.substring(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacityDecimal})`;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ty-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit {
  @ViewChild('loader') loader!: ElementRef;
  loading: boolean;

  get currentUrl(): string {
    return this.browsingHistoryService.currentURL;
  }

  get isSearchPage(): boolean {
    return this.currentUrl === AppRoutes.search;
  }

  constructor(private changeDetector: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private browsingHistoryService: BrowsingHistoryService
  ) {
    this.loading = false;
  }

  ngOnInit(): void {
    this.spinnerService
    .isLoading()
    .pipe(untilDestroyed(this))
    // eslint-disable-next-line rxjs/no-ignored-error
    .subscribe(loading => {
      if (loading !== this.loading) {
        this.loading = loading;
        this.changeDetector.detectChanges();
      }
      if (this.loading) {
        this.animate();
      }
    });
  }

  animate(): void {
    if (this.loader && this.loader.nativeElement?.animate) {
      this.loader.nativeElement.animate(
        {
          transform: ['rotate(0deg)', 'rotate(360deg)'],
        },
        {
          duration: 1500,
          iterations: Infinity,
        }
      );
    }
  }

}

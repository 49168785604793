import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { MenuItem } from '../../models/menu-item';

@Component({
  selector: 'ty-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: DaySelectorComponent }]
})
export class DaySelectorComponent extends NavigationComponent implements OnInit{
  @Input() days: MenuItem[];
  @Input() daySelected: MenuItem;

  @Output() daySelectedChange = new EventEmitter<MenuItem>();

  ngOnInit(): void {
      this.setDefaultActiveChildNodeId(0);
  }

  onDayClicked(day: MenuItem): void {
    this.daySelected = day;
    this.daySelectedChange.emit(day);
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onLeftKey(): boolean {
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onActivate(): void {
    const itemIndex = this.days.findIndex(item => item.id === this.daySelected?.id);
    if (itemIndex > -1) {
      this.selectChildNodeById(itemIndex);
      return;
    }
    this.selectChildNodeById(0);
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { TranslateModule } from '@ngx-translate/core';
import { LiveButtonComponent } from './live-button.component';

@NgModule({
  declarations: [
    LiveButtonComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NavigationModule
  ],
  exports: [
    LiveButtonComponent
  ]
})
export class LiveButtonModule { }

/* eslint-disable eqeqeq */
import { Injectable } from '@angular/core';
import { AudioTrack } from '@app/shared/models/audioTrack.interface';

@Injectable({
  providedIn: 'root'
})
export class PlayerConfigUserService {

  private subtitlesKey = 'selectedSubtitles';
  private audioKey = 'selectedAudio';

  constructor() {}

  saveSelectedSubtitles(subtitleID: string | number, listSubtitles: TextTrack[]): void {
    const selectedSubtitle = listSubtitles.find(subtitle => subtitle.id == String(subtitleID));
    if (selectedSubtitle) {
      localStorage.setItem(this.subtitlesKey, JSON.stringify(selectedSubtitle));
    }
  }

  getSelectedSubtitles(listSubtitles: TextTrack[]): string | null {
    const storedSubtitle = localStorage.getItem(this.subtitlesKey);
    if (storedSubtitle) {
      const storedSubtitleObj = JSON.parse(storedSubtitle) as TextTrack;
      const matchingSubtitle = listSubtitles.find(subtitle => subtitle.language == storedSubtitleObj.language);
      if(matchingSubtitle) {
        return matchingSubtitle ? matchingSubtitle.id : null;
      } else {
        this.removeSelectedAudio();
        this.removeSelectedSubtitles();
      }
    }
    return null;
  }

  saveSelectedAudio(audioId: number, listAudios: AudioTrack[]): void {
    const selectedAudio = listAudios.find(audio => audio.id == String(audioId));
    if (selectedAudio) {
      localStorage.setItem(this.audioKey, JSON.stringify(selectedAudio));
    }
  }

  getSelectedAudio(listAudios: AudioTrack[]): string | null {
    const storedAudio = localStorage.getItem(this.audioKey);
    if (storedAudio) {
      const storedAudioObj = JSON.parse(storedAudio) as AudioTrack;
      const matchingAudio = listAudios.find(audio => audio.language == storedAudioObj.language);
      if(matchingAudio){
        return matchingAudio ? matchingAudio.id : null;
      } else {
        this.removeSelectedAudio();
        this.removeSelectedSubtitles();
      }
    }
    return null;
  }

  removeSelectedSubtitles(): void {
    localStorage.removeItem(this.subtitlesKey);
  }

  removeSelectedAudio(): void {
    localStorage.removeItem(this.audioKey);
  }
}

import { IGalgoPlayerOptions } from '../models/galgo-player-options.interface';

export const playerOptions: IGalgoPlayerOptions = {
  autoplay: true,
  // NOTE: Order here is the order shown in player, please DON'T ORDER ALPHABETICALLY
  controls: [ ],
  captions: {
    active: true,
    update: true,
  },
  enableKeepPlaying: true,
};

export const liveOptions: IGalgoPlayerOptions = {
  autoplay: true,
  // NOTE: Order here is the order shown in player, please DON'T ORDER ALPHABETICALLY
  controls: [ ],
  captions: {
    active: true,
    update: true,
  },
  enableKeepPlaying: true,

};

export const headerTrailerOptions: IGalgoPlayerOptions = {
  autoplay: true,
  enableKeepPlaying: false,
  muted: true
};

export const trailerOptions: IGalgoPlayerOptions = {
  autoplay: false,
  controls: [
    'play-large',
    'current-time',
    'duration',
    'fullscreen',
    'mute',
    'volume',
    'play',
    'progress',
    'restart',
    'rewind',
    'captions',
    'settings',
  ],
  captions: {
    active: true,
    update: true,
  },
  enableKeepPlaying: false,
};

export const durationInterval = 1000;

/* tslint:disable */
/* eslint-disable */

/**
 * HTTP Status code extended
 */
export enum CustomErrorApi {
  $4001 = '400.1',
  $4002 = '400.2',
  $4003 = '400.3',
  $4004 = '400.4',
  $4005 = '400.5',
  $4006 = '400.6',
  $4007 = '400.7',
  $4008 = '400.8',
  $4009 = '400.9',
  $40010 = '400.10',
  $40011 = '400.11',
  $40012 = '400.12',
  $40013 = '400.13',
  $40014 = '400.14',
  $40018 = '400.18',
  $40019 = '400.19',
  $4011 = '401.1',
  $4012 = '401.2',
  $4013 = '401.3',
  $4015 = '401.5',
  $4016 = '401.6',
  $4021 = '402.1',
  $4031 = '403.1',
  $4032 = '403.2',
  $4033 = '403.3',
  $4034 = '403.4',
  $4035 = '403.5',
  $4036 = '403.6',
  $4041 = '404.1',
  $4042 = '404.2',
  $4043 = '404.3',
  $4044 = '404.4',
  $4045 = '404.5',
  $4046 = '404.6',
  $4061 = '406.1',
  $4091 = '409.1',
  $4092 = '409.2',
  $4093 = '409.3',
  $4094 = '409.4',
  $4095 = '409.5',
  $4096 = '409.6',
  FileCannotPublish = 'FILE_CANNOT_PUBLISH',
  FileCannotUpload = 'FILE_CANNOT_UPLOAD',
  FileTooSmall = 'FILE_TOO_SMALL'
}

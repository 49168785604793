
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { Carousel } from '@shared/features/carousel/model/carousel.model';
import { NGXLogger } from 'ngx-logger';
import { VirtualScrollItem } from '../virtual-scroll/virtual-scroll-item.interface';
import { VirtualScrollComponent } from '../virtual-scroll/virtual-scroll.component';

@Component({
  selector: 'ty-view-aggregator',
  templateUrl: './view-aggregator.component.html',
  styleUrls: ['./view-aggregator.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ViewAggregatorComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ViewAggregatorComponent extends NavigationComponent implements OnInit, OnDestroy{

  @Input() set data(value: Carousel[]) {
    if (value.length) {
      this.customContainers = value;
    }
  }
  @Input() firstSelectedChildNodeId: number;
  @Output() selectedItem: EventEmitter<MediaItem<MediaItemTypes>>;
  @ViewChild('aggregator') aggregator!: ElementRef;
  @ViewChild('virtualScroll') virtualScrollComponent!: VirtualScrollComponent;


  customContainers: Carousel[];
  scrollTop: number;
  virtualScrollItems: VirtualScrollItem[];
  currentUrl: string;
  firstSelectedChildNodeIdRow: number;



  constructor(
    public logger: NGXLogger,
    public el: ElementRef,
    private customerDataService: CustomerDataService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private browsingHistoryService: BrowsingHistoryService

  ) {
    super(el);
    this.scrollTop = 50;
    this.selectedItem = new EventEmitter<MediaItem<MediaItemTypes>>();
    this.virtualScrollItems = [];
    this.firstSelectedChildNodeId = 0;
    this.firstSelectedChildNodeIdRow = 0;
    this.currentUrl = '';
  }

  ngOnInit() {
    this.currentUrl = this.router.url;
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData?.viewAggregator) {
      this.virtualScrollItems = focusData.viewAggregator.virtualScrollItems;
      this.firstSelectedChildNodeId = focusData.viewAggregator.firstSelectedChildNodeId;
      this.firstSelectedChildNodeIdRow = focusData.viewAggregator.firstSelectedChildNodeIdRow;
      this.selectChildNodeById(this.firstSelectedChildNodeId);
    } else {
      this.processItems();
    }
  }

  ngOnDestroy(): void {
    if (this.currentUrl === AppRoutes.home) {
      this.saveChildrenLastPosition();
      const focusData = this.browsingHistoryService.getFocusData(AppRoutes.home);
      if (focusData) {
        focusData.viewAggregator = {
          virtualScrollItems: this.virtualScrollItems,
          firstSelectedChildNodeIdRow: (
            this.getSelectedChildNode() as NavigationComponent
          )?.getSelectedChildNodeId(),
          firstSelectedChildNodeId: this.getSelectedChildNodeId(),
        };
      }
    }
  }

  onFirstChildNodes(): void {
    (this.getSelectedChildNode() as NavigationComponent)?.selectChildNodeById(
      this.firstSelectedChildNodeIdRow
    );
    this.updateScroll();
  }

  saveChildrenLastPosition(): void {
    this.virtualScrollComponent?.visibleItems.forEach(visibleItem => {
      const videoId = (
        this.getChildNodeById(visibleItem.id) as NavigationComponent
      )?.getSelectedChildNodeId();
      visibleItem.firstSelectedChildNodeId = videoId || 0;
    });
  }

  processItems(): void {
    this.virtualScrollItems =
    this.customContainers?.map((item: any, index: number) => ({
        id: index,
        width: 0,
        height: 17,
        data: item,
      })) || [];
  }

  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() > 0) {
      if (this.selectPreviousChildNode()) {
       this.virtualScrollComponent?.scrollToPreviousElement();
       this.changeDetector.detectChanges();
      }
      return true;
    }
    return false;
  }

  onDownKey(): boolean {
    if (this.getSelectedChildNodeId() < this.virtualScrollItems.length - 1) {
      if (this.selectNextChildNode()) {
        this.virtualScrollComponent?.scrollToNextElement();
        this.changeDetector.detectChanges();
      }
      return true;
    }
    return false;
  }

  onChangeSelectedChildNode(): void {
    this.updateScroll();
  }

  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeIndex());
    if (focused) {
      let scrollTop = focused.getOffsetTop();
      if (scrollTop !== this.scrollTop) {
        if(scrollTop < 50){
          scrollTop = 50;
        }
        this.scrollTop = scrollTop;
        this.aggregator.nativeElement.style.transform = `translateY(-${this.scrollTop-50}px)`;
      }
    }
  }

  setSelectedItem(item: MediaItem<MediaItemTypes>) {
    this.selectedItem.emit(item);
  }

  trackByFn(index: number, item: any): number {
    return item.id;
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageIsoApi } from '@api/models/language-iso-api';
import { StripeCustomerInfoApi } from '@api/models/stripe-customer-info-api';
import { UserFrontApi } from '@api/models/user-front-api';
import { MeApiService } from '@api/services/me-api.service';
import { User } from '@app/auth/models/user.model';
import { AuthService } from '@app/auth/services/auth-custom/auth.service';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { PaidItemsResponseApi, SubscriptionInfoApi } from '@app/galgo-api/models';
import { PurchaseApiService } from '@app/galgo-api/services';
import { Capitalize } from '@app/shared/functions/capitalize';
import { ItemTypes } from '@app/shared/models/item-types.enum';
import { Video } from '@app/shared/models/video/video.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { interval, Observable, of, throwError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { IPaidItems } from '../../models/paid-items.interface';

const USER_RECOVER_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds


@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentUser: User;
  get currentUser(): User {
    return this._currentUser || this.getUserFromLocalStorage();
  }

  set currentUser(user: User) {
    this._currentUser = user;
    this.setUserInLocalStorage(this._currentUser);
  }

  private _userStatus: string;
  get userStatus(): string {
    return this._userStatus;
  }
  set userStatus(value: string) {
    this._userStatus = value;
  }

  get userProducts(): IPaidItems {
    return this.currentUser?.paidItems;
  }

  get currentUserLanguage(): string {
    return this.currentUser?.favoriteLanguageCode;
  }

  constructor(
    private authCustomService: AuthService,
    private userApiService: MeApiService,
    private purchaseApiService: PurchaseApiService,
    public logger: NGXLogger,
  ) {
    this.authCustomService.logout$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.clear();
      },
      error: (error: void) => throwError(() => error),
    });
    interval(USER_RECOVER_INTERVAL).pipe(
      concatMap(() => this.getUserDataFromServer()),
      untilDestroyed(this),
    ).subscribe();
  }

  getUserDataFromServer(): Observable<User> {
    return this.userApiService.meControllerGetMe().pipe(
      map((user: UserFrontApi) => {
        this.currentUser = new User(user);
        return this.currentUser;
      })
    );
  }

  getUserMediaItemPurchases(): Observable<MediaItem<MediaItemTypes>[]>  {
    if (!this.userProducts.paidVideos || !this.userProducts.paidVideos.length) {
      return of([]);
    }
    return this.getUserPurchases().pipe(
      map((response) => {
        const mediaItems = (response)
          .filter(item => this.userProducts.paidVideos.map(product => product.videoId).includes(item._id))
          .map(item => ({
            id: item._id,
            slug: item.slug,
            thumbnail: item.thumbnail,
            title: item.title,
            itemType: ItemTypes.Video,
          })) as MediaItem<MediaItemTypes>[];
        return mediaItems;
      })
    );
  }

  getUserProducts(): Observable<SubscriptionInfoApi> {
    const params = {
      language: this.currentUserLanguage,
    };
    return this.purchaseApiService.subscriptionControllerGetProducts(params);
  }

  getUserPurchases(): Observable<PaidItemsResponseApi[]>  {
    const params = {
      language: this.currentUserLanguage,
    };
    return this.userApiService.meControllerGetProducts(params);
  }

  getUserStripeInfoUrl(): Observable<StripeCustomerInfoApi> {
    return this.userApiService.meControllerGetStripeCustomerInfo();
  }

  getVideoProgress(video: MediaItem<Video>): number {
    const playingInfo = this?.currentUser?.playings.find(playing => playing.id === video.id);
    if (!playingInfo) {
      return 0;
    }
    return playingInfo.finished ? video.duration : playingInfo.startSecond;
  }

  isLoggedIn() {
    return !!this.currentUser?.id;
  }

  setNewUserInfo(): void{
    this.getUserDataFromServer().pipe(untilDestroyed(this)).subscribe({
      next: (user: User) => {
        this.logger.debug('CatalogSelectorComponent -> SetNewUser(): Success', user);
        this.currentUser = user;
      },
      error: (error: HttpErrorResponse) => {
        this.logger.error('CatalogSelectorComponent -> SetNewUser(): Failed', error);
        return throwError(() => new HttpErrorResponse(error));
      },
    });
  }


  setUserLanguage(id: string): Observable<UserFrontApi> {
    this._currentUser.favoriteLanguageCode = LanguageIsoApi[Capitalize(id)];
    return this.userApiService.meControllerSetData({
      body: {
        _id: this._currentUser.id,
        name: this._currentUser.name,
        email: null,
        status: this._currentUser.userConfirmationStatus,
        favoriteLanguageCode: this._currentUser.favoriteLanguageCode,
        areLegalTermsAccepted: this._currentUser.hasAcceptedLegalTerms,
        legalConditionsId: this._currentUser.legalConditionsId,
      },
    });
  }


  private removeUserFromLocalStorage(): void {
    localStorage.removeItem('currentUser');
  }

  private getUserFromLocalStorage(): User {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user && Object.entries(user)?.length) {
      return new User(user);
    }

    return undefined;
  }

  private setUserInLocalStorage(currentUser: User) {
    localStorage.setItem('currentUser', currentUser.serialize());
  }

  private clear() {
    this._currentUser = undefined;
    this.removeUserFromLocalStorage();
    this._userStatus = '';
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { ButtonPlayerModule } from '../button-player/button-player.module';
import { CustomSeekbarModule } from '../controls-player/custom-seekbar/custom-seekbar.module';
import { ListSelectorIconModule } from '../controls-player/list-selector-icon/list-selector-icon.module';
import { LiveButtonModule } from '../controls-player/live-button/live-button.module';
import { SeekbarModule } from '../controls-player/seekbar/seekbar.module';
import { HeaderPlayerModule } from '../header-player/header-player.module';
import { LogoModule } from '../logo/logo.module';
import { PlayerUiComponent } from './player-ui.component';

const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [
    ANGULAR_MODULES,
    CommonModule,
    LogoModule,
    HeaderPlayerModule,
    ButtonPlayerModule,
    CustomSeekbarModule,
    SeekbarModule,
    ListSelectorIconModule,
    NavigationModule,
    LiveButtonModule
  ],
  declarations: [ PlayerUiComponent ],
  exports: [ PlayerUiComponent, ]
})
export class PlayerUiModule { }

import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { LegalTextApi } from '@api/models';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { LegalConditionsCustomService } from '@core/legal-conditions/legal-conditions.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { throwError } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ty-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: PrivacyPolicyComponent }]
})
export class PrivacyPolicyComponent extends NavigationComponent implements OnInit {
  privacyText: string;
  isLoading: boolean;

  constructor(
    private legalConditionsService: LegalConditionsCustomService,
    private browsingHistoryService: BrowsingHistoryService,
    public el: ElementRef
  ) {
    super(el);

  }

  ngOnInit(): void {
    this.getPrivacyPolicy();
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }

  /**
   * Recieves all legal conditions and instances the privacy policy markdown text
   *
   * @private
   * @memberof PrivacyPolicyTextComponent
   */
  private getPrivacyPolicy() {
    this.legalConditionsService.getLegalConditions().pipe(untilDestroyed(this)).subscribe({
      next: (response: LegalTextApi) => {
        this.privacyText = response.privacyPolicy;

      },
      error: (error: HttpErrorResponse) => throwError(() => new HttpErrorResponse(error)),
    });
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponseApi, ImageSizeApi, ItemTypeApi, VideoAccessApi } from '@api/models';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { UserService } from '@app/auth/services/user/user.service';
import { MediaItem } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { KeyboardService } from '@app/core/navigation/services/keyboard.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { LanguageService } from '@app/core/services/language.service';
import { PlayingService } from '@app/core/services/playing.service';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { ModalService } from '@app/shared/components/modal/services/modal.service';
import { PlayerStatus } from '@app/shared/models/player-status.model';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { PlayerEventTimerService } from '@app/shared/services/player-event-timer.service';
import { IFeatureToggle } from '@core/models/feature-toggle.interface';
import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Video } from '@shared/models/video/video.model';
import { NGXLogger } from 'ngx-logger';
import { Observable, ReplaySubject, Subject, merge as staticMerge, throwError } from 'rxjs';
import { debounceTime, filter, first, tap } from 'rxjs/operators';
import { PlayerTypeApi } from '../galgo-api/models/player-type-api';
import { PlayerName, beatIntervalTimerState, keepPlayingTimerState, videoProgressTimerState } from './config';
import { PING_INTERVAL } from './config/ping-interval.config';
import { ShakaDriverComponent } from './drivers/shaka';
import { TheoDriverComponent } from './drivers/theo';
import { VideojsDriverComponent } from './drivers/videojs/videojs-driver.component';
import { IGalgoPlayerOptions, ITimerState, PlayerDetailType, PlayerDrivers, TimerEventType } from './models';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-galgo-player',
  templateUrl: './galgo-player.component.html',
  styleUrls: ['./galgo-player.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: GalgoPlayerComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalgoPlayerComponent
  extends NavigationComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('brightcove') brightcoveElement: ElementRef;
  @ViewChild('shaka') shakaElement: ElementRef;
  @ViewChild('theo') theoElement: ElementRef;
  @ViewChild('videojs') videojsElement: ElementRef;
  @Input() isTrailer: boolean;
  @Input() options: IGalgoPlayerOptions;
  @Input() mediaItem: MediaItem<Video>;
  @Input() set playerDetails(value: PlayerDetailType) {
    this.playerData = value;
    setTimeout(() => {
      this.createPlayer();
    }, 0);
  }

  get playerDetails(): PlayerDetailType {
    return this.playerData;
  }

  get showDogLogo(): boolean {
    return this.features.showDogPlayer;
  }

  player: any;
  showNextChapter: boolean;
  playerName: PlayerName;
  isPaused: boolean;
  remainingVideoTime: number;
  hasLimitedSlots: boolean;
  pingInterval: number;


  private beatInterval: ReturnType<typeof setInterval>;
  private videoProgressInterval: ReturnType<typeof setInterval>;
  private features: IFeatureToggle;
  private driver: PlayerDrivers;
  private playerData: PlayerDetailType;
  private onPlayerInitialized$: ReplaySubject<void>;
  private play: Subject<ITimerState>;
  private pause: Subject<ITimerState>;
  private end: Subject<ITimerState>;
  private onFirstStart: boolean;
  private onFirstHalfReach: boolean;
  private onFirstEnd: boolean;
  private videoProgress: number;

  get onPlayerInitialized(): Observable<void> {
    return this.onPlayerInitialized$.asObservable();
  }

  get isAudio(): boolean {
    return this.galgoPlayerService.status.metas?.itemType === ItemTypeApi.Audio;
  }

  get thumbnail(): string {
    return this.galgoPlayerService.status.metas?.thumbnail?.landscape;
  }

  get controls(): string[] {
    return this.galgoPlayerService?.status?.controls;
  }

  get metas(): Video {
    return this.galgoPlayerService?.status?.metas;
  }

  get url(): string {
    return this.galgoPlayerService?.status?.url;
  }

  get playerType(): PlayerTypeApi {
    return this.galgoPlayerService?.status?.playerType;
  }

  get poster(): string {
    const landscapes = this.mediaItem?.thumbnail?.landscapes;
    const landscape = this.mediaItem?.thumbnail?.landscape;
    const preferenceOrder = [ImageSizeApi.High, ImageSizeApi.Original];

    if (landscapes?.length > 0) {
      const found = preferenceOrder.map(preference => landscapes.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultThumbnail;
    }
    return landscape ?? this.defaultThumbnail;
  }

  get defaultThumbnail(): string {
    return this.customerDataService.defaultBanner;

  }

  get itemType(): ItemTypeApi {
    return this.galgoPlayerService?.status?.itemType;
  }

  get videoId(): string {
    return this.galgoPlayerService?.status?.metas?.id;
  }


  get isLive(): boolean {
    return this.mediaItem?.live;
  }

  get allowKeepPlaying(): boolean {
    return (
      this.userService.isLoggedIn() && this.options?.enableKeepPlaying && !this.isLive
    );
  }

  get isStart(): boolean {
    return this.videoProgress < 1;
  }

  get isHalf(): boolean {
    return this.videoProgress === 50;
  }

  get isMoreThankHalf(): boolean {
    return this.videoProgress >= 50;
  }

  get isEnd(): boolean {
    return this.videoProgress >= 95;
  }

  get showControls(): boolean {
    return this.galgoPlayerService.status.showControls;
  }

  constructor(
    private analyticsService: AnalyticsService,
    private changeDetector: ChangeDetectorRef,
    private elementRef: ElementRef,
    private featureFlagsService: FeatureFlagsService,
    private keyBoardService: KeyboardService,
    private galgoPlayerService: GalgoPlayerService,
    private settingsService: SettingsService,
    private playingService: PlayingService,
    private userService: UserService,
    private router: Router,
    private browsingHistoryService: BrowsingHistoryService,
    private customerDataService: CustomerDataService,
    private videoDetailService: VideoDetailService,
    private languageService: LanguageService,
    private playerEventTimer: PlayerEventTimerService,
    private modalService: ModalService,
    public logger: NGXLogger,
    public el: ElementRef,
  ) {
    super(el);
    this.galgoPlayerService.updateStatus(
      (status) => (status.showControls = false)
    );
    this.showNextChapter = false;
    this.playerName = this.customerDataService.playerName;
    this.play = new Subject<ITimerState>();
    this.pause = new Subject<ITimerState>();
    this.end = new Subject<ITimerState>();
    this.onFirstStart = false;
    this.onFirstHalfReach = false;
    this.onFirstEnd = false;
    this.hasLimitedSlots = this.featureFlagsService?.currentFeaturesValue?.hasLimitedSlots;


    this.getFeatures();
    this.onPlayerInitialized$ = new ReplaySubject<void>();
    this.subscribeToPlayerInitialization();
  }

  ngOnInit(): void {
    if(!this.isTrailer){
      this.settingsService.lastView$ = 'player';
    }

    if(this.hasLimitedSlots && this.userService.isLoggedIn() && this.mediaItem?.access !== VideoAccessApi.Public){
      this.registerPingInterval();
    }

    this.galgoPlayerService.driverLoader.pipe(untilDestroyed(this)).subscribe({
      next: (value: boolean) => {
        this.getKeyboardEvents();
        this.showControlsChanges();
        this.setUserEvents();
      },
      error: (error: Error) => {
        this.logger.error(
          'GalgoPlayerComponent -> driverLoader service: Failed',
          error
        );
        throw new Error('Error obtaining driverLoader');
      }
    });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  onKeyPress(): boolean {
    this.galgoPlayerService.updateStatus(
      (status) => (status.showControls = true)
    );
    this.showNextChapter = false;
    this.changeDetector.detectChanges();
    return true;
  }

  onPlayKey(): boolean {
    this.galgoPlayerService.driver?.play();
    return true;
  }

  onPlayPauseKey(): boolean {
    if (this.galgoPlayerService.driver?.paused) {
      this.galgoPlayerService.driver?.play();
    } else {
      this.galgoPlayerService.driver?.pause();
    }
    return true;
  }

  onPauseKey(): boolean {
    this.galgoPlayerService.driver?.pause();
    return true;
  }

  onRewindKey(): boolean {
    this.galgoPlayerService.driver?.rewind(10);
    return true;
  }

  onFastForwardKey(): boolean {
    this.galgoPlayerService.driver?.forward(10);
    return true;
  }

  onBackKey(): boolean {
    this.navigateBack();
    return true;
  }

  onStopKey(): boolean {
    this.navigateBack();
    return true;
  }

  ngOnDestroy(): void {
    if(this.driver.player){
      if (this.galgoPlayerService.status.enableControlsAndAnalytics) {
        clearInterval(this.beatInterval);
        clearInterval(this.videoProgressInterval);
        clearInterval(this.pingInterval);
      }
      this.galgoPlayerService.reset();
    } else {
      this.galgoPlayerService.reset();
      clearInterval(this.beatInterval);
      clearInterval(this.videoProgressInterval);
      clearInterval(this.pingInterval);
    }
    this.driver?.destroy();
    this.videoDetailService.deleteVideoNext();
  }

  navigateBack(): void {
    switch (this.settingsService.lastView$) {
      case 'player':
        this.browsingHistoryService.goBack();
        break;
      case 'home':
        this.router.navigate([AppRoutes.home]);
        break;
      case 'search':
        this.router.navigate([AppRoutes.search]);
        break;
      case 'my-space':
        this.router.navigate([AppRoutes.mySpace]);
        break;
      case 'similar-tab':
        this.router.navigate([AppRoutes.home]);
        break;
      case 'more-info-tab':
        this.router.navigate([AppRoutes.home]);
        break;

      default:
        this.browsingHistoryService.goBack();
        break;
    }
  }

  private registerPingInterval(): void {
    this.pingInterval = setInterval(() => this.pingVideo(), PING_INTERVAL);
    this.pingVideo();
  }

  private pingVideo(): void {
    this.videoDetailService.pingVideo(this.mediaItem.id).pipe(first()).subscribe({
      error: error => this.logger.error('Error pinging video: ', this.mediaItem.id, error)
    });
  }

  private setPlayerControls(showControls: boolean): void {
    if (!this.isAudio) {
      this.player?.toggleControls(showControls);
    } else {
      this.galgoPlayerService.updateStatus(
        (status) => (status.showControls = true)
      );
    }
  }

  private showControlsChanges(): void {
    this.galgoPlayerService
      .select((status) => status.showControls)
      .pipe(untilDestroyed(this))
      .subscribe(
        (status: PlayerStatus) => {
          this.setPlayerControls(status.showControls);
          this.changeDetector.detectChanges();
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'GalgoPlayerComponent -> ShowControlsChanges(): Failed',
            error
          );
        }
      );

    // If video is playing and there are no more events in three seconds, the controls are hidden
    // It passes to select select function distinct=false to capture all state updates
    this.galgoPlayerService
      .select((status) => status.showControls, false)
      .pipe(
        debounceTime(3000),
        filter((status) => status.showControls)
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          if (!this.driver?.paused) {
            this.galgoPlayerService.updateStatus(
              (status) => (status.showControls = false)
            );
          }
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'GalgoPlayerComponent -> ShowControlsChanges(): Failed',
            error
          );
        }
      );
  }

  private removeFromPlaying(): void {
    this.playingService.removeVideo({ videoId: this.videoId }).subscribe({
      next: () => {},
      error: (error: HttpErrorResponse) => throwError(() => error),
    });
  }

  private setUserEvents(): void {
    this.elementRef.nativeElement.onclick = () => {
      this.setPlayerControls(true);
    };
  }

  private createPlayer() {
    switch (this.playerName) {
      case PlayerName.brightcove:
        this.initBrightcove();
        break;
      case PlayerName.videojs:
        this.initVideojs();
        break;
      case PlayerName.theo:
        this.initTheo();
        break;
      case PlayerName.shaka:
        this.initShaka();
        break;
      default:
        this.initVideojs();
        break;
    }
  }

  private initTheo() {
    // eslint-disable-next-line no-console
    console.log('Theo');

    if (!this.driver) {
      this.driver = new TheoDriverComponent(
        this.logger,
        this.customerDataService,
        this.languageService,
        this.galgoPlayerService,
        this.modalService,
        this.browsingHistoryService,
        this
      );
      this.driver.create({
        autoplay: this.options.autoplay,
        fullScreen: this.playerDetails.fullscreen,
        videoElement: this.theoElement.nativeElement
      });
    }
    this.driver.updateSource(this.playerData);
    this.galgoPlayerService.setDriver(this.driver);
    this.onPlayerInitialized$.next();
  };

  private initVideojs() {
    // eslint-disable-next-line no-console
    console.log('VideoJS');
    if (!this.driver) {
      this.driver = new VideojsDriverComponent(this.logger, this.galgoPlayerService, this.modalService, this.browsingHistoryService, this);
     this.driver.create({
        autoplay: this.options.autoplay,
        fullScreen: false,
        videoElement: this.videojsElement.nativeElement
      });
    }
    this.driver.updateSource(this.playerData);
    this.galgoPlayerService.setDriver(this.driver);
    this.onPlayerInitialized$.next();
 };

  private initBrightcove() {
     // eslint-disable-next-line no-console
     console.log('Brightcove');
  };

  private initShaka() {
    // eslint-disable-next-line no-console
    console.log('Shaka');
    this.driver = new ShakaDriverComponent(
      this.logger,
      this.languageService,
      this.galgoPlayerService,
      this.modalService,
      this.browsingHistoryService,
      this
    );
    this.driver.create({
      autoplay: this.options.autoplay,
      fullScreen: false,
      videoElement: this.shakaElement.nativeElement
    });

    this.driver.updateSource(this.playerData);
    this.galgoPlayerService.setDriver(this.driver);
    this.onPlayerInitialized$.next();
  }


  /**
   * When the player is initialized with the data, an event it's emit to start with the core events and behaviors of the player
   *
   * @private
   * @memberof GalgoPlayerComponent
   */
  private subscribeToPlayerInitialization() {
    this.onPlayerInitialized$.subscribe({
      next: () => {
        this.logger.info(`Initialized`, this.playerData?.playerName);
        this.subscribeToPlayerEvents();
        this.subscribeToVideoProgress();

        if (this.features.enableBeatAnalytic && this.playerData?.events?.sendBeats) {
          this.subscribeToBeatInterval();
        }

        if (this.allowKeepPlaying) {
          this.subscribeToAddPlayingInterval();
        }
        },
      error:(error) => {
        this.logger.error('GalgoPlayerComponent -> subscribeToPlayerInitialization: Error subscribe', error);
        throw error;
      }
    });
  }

  /**
   * Subscribes to the internal player events
   *
   * @private
   * @memberof GalgoPlayerComponent
   */
  private subscribeToPlayerEvents() {
    this.driver.registerOnPlaying(this.onPlaying.bind(this));
    this.driver.registerOnPause(this.onPause.bind(this));
  }

  /**
   * Actions performed when the player is paused.
   *
   * @private
   * @memberof GalgoPlayerComponent
   */
  private onPause() {
    //this.galgoPlayerService.goBack(true);
  }

  /**
   * Actions performed when the player plays. If the videoprogress is 100% we will reset the progress to be recalculated
   *
   * @private
   * @memberof GalgoPlayerComponent
   */
  private onPlaying() {
    this.play.next({ pause: false });
    if (this.isEnd) {
      this.resetVideoProgress();
    }
  }

  /**
   * Timer that checks every 500ms the progress until the end of the video.
   * Checks the Start, Half and End position to launch the specified actions in that periods.
   * If the video plays on another time period, this observable will rerun.
   *
   * @private
   * @memberof GalgoPlayerComponent
   */
  private subscribeToVideoProgress() {
    this.playerEventTimer
      .setTimer(
        TimerEventType.timer,
        staticMerge(this.play, this.pause),
        videoProgressTimerState
      )
      .pipe(
        filter(() => !this.isEnd),
        tap(() => {
          this.videoProgress = this.calculateProgressTime(this.driver.getCurrentTime());
          this.remainingVideoTime = this.calculateProgressTimeToEndVideo(this.driver.getCurrentTime());
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          if (this.isStart) {
            this.onVideoStart();
          }
          if (this.isHalf || this.isMoreThankHalf) {
            this.onHalfVideo();
          }
          if (this.isEnd) {
            this.launchNextChapter();
            this.onVideoEnd();
          }
        },
      });
  }

  /**
   * Timer that controls the beat interval analytics until the end of the video.
   * It checks the real visualization of the user on the player every second.
   * If the video is paused, the observable too. Once the value reaches the limit, the beat will be emited
   *
   * @private
   * @memberof GalgoPlayerComponent
   */
  private subscribeToBeatInterval() {
    this.playerEventTimer
      .setTimer(
        TimerEventType.interval,
        staticMerge(this.play, this.pause, this.end),
        beatIntervalTimerState
      )
      .pipe(
        filter(() => !this.isEnd),
        untilDestroyed(this)
      )
      .subscribe({
        next: (state: ITimerState) => {
          if (state.value >= state.limit) {
            this.analyticsService.logEvent(GalgoEvent.beat, { video: this.mediaItem });
          }
        },
      });
  }

  /**
   * Timer that controls the "keep playing" interval action until the end of the video.
   * It checks the real visualization of the user on the player every second.
   * If the video is paused, the observable too. Once the value reaches the limit, the keep playing event will be emited.
   *
   * @private
   * @memberof GalgoPlayerComponent
   */
  private subscribeToAddPlayingInterval() {
    this.playerEventTimer
      .setTimer(
        TimerEventType.interval,
        staticMerge(this.play, this.pause, this.end),
        keepPlayingTimerState
      )
      .pipe(
        filter(() => !this.isEnd),
        untilDestroyed(this)
      )
      .subscribe({
        next: (state: ITimerState) => {
          if (state.value >= state.limit) {
            if(!this.isTrailer){
              this.addPlayingProgress();
            }
          }
        },
      });
  }

  private launchNextChapter(){
    if (this.videoDetailService.getVideoNext()) {
      this.showNextChapter = true;
      this.selectChildNodeById(1);
      this.changeDetector.detectChanges();
    }
  }

  /**
   * Performs all the actions when the video starts.
   * Makes the video go fordward if the mediaitem has seconds from "keep playing"
   * Launches the startEvent analytic
   * One time actions has to be inside the onFirstStart condition
   * TODO make onFirstStart an array of callback actions
   */
  private onVideoStart() {
    this.showNextChapter = false;
    if (!this.onFirstStart) {
      if (this.mediaItem?.startSecond > 0) {
        this.driver.forward(this.mediaItem?.startSecond);
      }

      if (this.playerData?.events?.startEvent) {
        const eventStartVideoOrTrailer = this.isTrailer ? this.playerData.events.startTrailerEvent : this.playerData.events.startEvent;
        this.analyticsService.logEvent(eventStartVideoOrTrailer, {
          video: this.mediaItem,
          fromSecond: this.mediaItem?.startSecond,
        });
      }
      this.onFirstStart = true;
    }
    //this.galgoPlayerService.nextchapterShow(false);
  }

  /**
   * Performs all the actions when the half of the video is reached.
   * Launches the halfEvent analytic
   * One time actions has to be inside the onFirstHalfReach condition
   * TODO make onFirstHalfReach an array of callback actions
   */
  private onHalfVideo() {
    if (!this.onFirstHalfReach) {
      if (this.playerData?.events?.halfEvent) {
        const eventHalfVideoOrTrailer = this.isTrailer ? this.playerData.events.halfTrailerEvent : this.playerData.events.halfEvent;
        this.analyticsService.logEvent(eventHalfVideoOrTrailer, {
          video: this.mediaItem,
        });
      }
    }
    this.onFirstHalfReach = true;
  }

  /**
   * Performs all the actions when the end is reached.
   * Launches the finishEvent analytic
   * Pauses the countdowns subscribed to this
   * Always removes the video from "keep playing"
   * One time actions has to be inside the onFirstEnd condition
   * TODO make onFirstEnd an array of callback actions
   */

  private onVideoEnd() {
    if (!this.onFirstEnd) {
      if (this.playerData?.events?.finishEvent) {
        const eventFinishVideoOrTrailer = this.isTrailer ? this.playerData.events.finishTrailerEvent : this.playerData.events.finishEvent;
        this.analyticsService.logEvent(eventFinishVideoOrTrailer, {
          video: this.mediaItem,
        });
      }
    }

    this.end.next({ pause: true });
    if (this.userService.isLoggedIn() && !this.isTrailer) {
      this.removeFromPlaying();
    }

    //this.galgoPlayerService.goBack(false);
    //this.galgoPlayerService.nextchapterShow(false);
    this.galgoPlayerService.endedVideo();
  }



  /**
   * Resets the video progress to 0
   */
  private resetVideoProgress() {
    //this.galgoPlayerService.goBack(true);
    //this.galgoPlayerService.nextchapterShow(false);
    this.videoProgress = 0;
  }

  /**
   * Adds the current time stamp to "keep playing"
   */
  private addPlayingProgress() {
    this.logger.debug(
      `Added video to keep playing - time: ${this.driver.getCurrentTime()}`
    );
    this.playingService
      .setVideo({ videoId: this.mediaItem.id, time: this.driver.getCurrentTime() })
      .subscribe({
        next: () => {
          this.logger.debug('GalgoPlayerComponent => addPlayingProgress');
        },
        error: (error: HttpErrorResponse) => throwError(() => error),
      });
  }

  /**
   * Calculates the actual progress in % from the video. Formated without floats.
   *
   * @param time
   * @returns
   */
  private calculateProgressTime(time: number) {
    return Math.trunc((Math.floor(time) * 100) / Math.floor(this.driver.getDuration()));
  }

  private calculateProgressTimeToEndVideo(currentTime: number) {
    return this.driver.getDuration() - currentTime;
  }


  private getKeyboardEvents() {
    this.keyBoardService.keyEvent?.pipe(untilDestroyed(this)).subscribe(
      () => {
        this.setPlayerControls(true);
      },
      (error) => {
        this.logger.error(
          'GalgoPlayerComponent -> GetKeyboardEvents(): Failed',
          error
        );
      }
    );
  }

  private getFeatures() {
    this.features = this.featureFlagsService.currentFeaturesValue;
  }
}

<ty-login-return-button *ngIf="showBackButton"
                        [navigableId]="0"></ty-login-return-button>
<div *ngIf="!showBackButton"
     class="spacer"></div>
<div id="login-content">
  <h1>{{'login.login' | translate}}</h1>
  <div id="login-methods">
    <div class="login-method login-method-a">
      <div class="title-method">
        <div class="icon-method">A</div>
        <h4>{{'login.qr-login' | translate}}</h4>
      </div>
      <div class="content-method content-method-qr">
        <ty-qr-code [qrUrl]="qrURL"></ty-qr-code>
        <p class="text-large">{{'login.qr-description' | translate}}</p>
      </div>
    </div>
    <div class="login-method login-method-b">
      <div class="title-method">
        <div class="icon-method">B</div>
        <h4>{{'login.manual-login' | translate}}</h4>
      </div>
      <div class="content-method">
        <p class="text-large">{{'login.web-access' | translate}}</p>
        <h2 class="code-url">{{startUrl}}</h2>
        <p class="text-large">{{'login.enter-code' | translate}}</p>
        <ul>
          <li *ngFor="let character of smartTVCode">{{character}}</li>
        </ul>
      </div>
    </div>
  </div>

  <div id="login-access-info">
    <div class="buttons">
      <div class="mail">
        <p class="text-medium">{{ 'login.qr-problems' | translate }}</p>
        <div *ngIf="showLoginButtonEmail"
             class="primary-button box-space"
             (click)="goTo(appRoutes.loginForm)"
             appNavigation
             [navigableId]="1">
          <button class="button button-large">{{'login.mail-access' | translate}}</button>
        </div>
      </div>
      <div class="code">
        <p class="text-medium">{{ 'login.qr-regenerate' | translate }}</p>
        <div class="secondary-button box-space"
             (click)="generateSmartTVCode()"
             appNavigation
             [navigableId]="2">
          <button class="button button-large">{{'login.generate-code' | translate}}</button>
        </div>
      </div>
    </div>
    <p class="text-large final-text">{{ 'login.register-description' | translate }}</p>
  </div>
  <div>
    <pre class="bottom">{{version}}</pre>

<div [ngClass]="(isSeeking$ | async) ? 'scrim--bottom' : 'scrim'"></div>
<div *ngIf="(isSeeking$ | async) === false && (showAVList$ | async) === false"
     class="header-wrapper">
  <ty-header-player [navigableId]="0"></ty-header-player>
</div>
<div class="button-container">
  <div [class.show-icon]="isBrowserOldVersion"
       [class.action-icon]="!isBrowserOldVersion"
       [class.fade-in]="(iconFadeOut$ | async) === false"
       [class.fade-out]="(iconFadeOut$ | async)">
    <svg class="icon"
         aria-hidden="true"
         viewBox="0 0 320 320">
      <use [attr.href]="iconUrl"></use>
    </svg>
  </div>
</div>
<div class="seekbar-container">
  <div *ngIf="((isSeeking$ | async) === false)"
       class="top-components"
       [class.top-components--live]="isLive">
    <div class="left">
      <ty-list-selector-icon *ngIf="audioTracks?.length > 1"
                             class="list-selector-icon"
                             [itemTracks]="audioTracks"
                             [icon]="iconAudio"
                             [typeOfList]="'audios'"
                             (subtitledChanged)="changeAudio($event)"
                             [navigableId]="5"></ty-list-selector-icon>
      <ty-list-selector-icon *ngIf="textTracks?.length"
                             class="list-selector-icon"
                             [itemTracks]="textTracks"
                             [icon]="iconSubtitle"
                             [typeOfList]="'subtitles'"
                             (subtitledChanged)="changeSubtitle($event)"
                             [navigableId]="6"></ty-list-selector-icon>
      <div *ngIf="(audioTracks?.length > 1 || textTracks?.length) && (showAVList$ | async) === false"
           class="spacer"></div>
    </div>
    <div class="right">
      <ty-live-button *ngIf="isLive && isSeekable && (showAVList$ | async) === false"
                      class="list-selector-icon"
                      [navigableId]="7"></ty-live-button>
      <ty-button-player *ngIf="!isLive && (showAVList$ | async) === false"
                        class="list-selector-icon"
                        [labelButton]="restartButton.text"
                        [tooltipButton]="restartButton.alternativeText"
                        [showTooltipButton]="showStartOverButtonTooltip"
                        [showLabelButton]="showStartOverButtonText"
                        [config]="restartButton"
                        [navigableId]="8"></ty-button-player>
      <ty-button-player *ngIf="!isLive && showNextChapterButton && (showAVList$ | async) === false"
                        class="list-selector-icon"
                        [labelButton]="nextChapterButton.text"
                        [tooltipButton]="nextChapterButton.text"
                        [showTooltipButton]="showNextChapterButtonTooltip"
                        [showLabelButton]="showNextChapterButtonText"
                        [config]="nextChapterButton"
                        [navigableId]="9"></ty-button-player>
    </div>
  </div>
  <ty-seekbar *ngIf="(showAVList$ | async) === false"
              class="seekbar-wrap"
              [navigableId]="4"></ty-seekbar>
</div>
<div class="primary-ui-button"
     appNavigation
     [navigableId]="0"
     (click)="onClick()">
  <button class="ui-button button-medium">
    <div class="content">
      <svg *ngIf="true"
           class="icon"
           [class.icon--space]="true"
           aria-hidden="true"
           viewBox="0 0 32 32">
        <use [attr.href]="'./assets/icons/live.svg' + '#icon'"></use>
      </svg>
      <span
            class="text">{{ ((isAtLiveEdge$ | async) ? 'buttons.live' : 'buttons.backToLive') | translate }}</span>
    </div>
  </button>
</div>
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { LanguageService } from '@app/core/services/language.service';
import { AudioApi, CreditsFrontApi, TagResponseApi } from '@app/galgo-api/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'ty-details-info',
  templateUrl: './details-info.component.html',
  styleUrls: ['./details-info.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: DetailsInfoComponent},
  ],
})
export class DetailsInfoComponent extends NavigationComponent implements OnInit{
  @Input() audios: Array<AudioApi>;
  @Input() contentType: TagResponseApi;
  @Input() countries: TagResponseApi[];
  @Input() originalTitle: string;
  @Input() otherTitle: string;
  @Input() participants: CreditsFrontApi[];
  @Input() platformCollection: TagResponseApi;
  @Input() producer: string;
  @Input() producerLabel: string;
  @Input() rightsOwnership: TagResponseApi;
  @Input() subgenere: TagResponseApi;
  @Input() subtitlesAvailable: Array<string> ;
  @Input() tags: TagResponseApi[];

  descriptionItems: TagResponseApi[];
  separator: string;
  translatedSubtitles: Observable<string>[];
  formattedTags = '';

  constructor(
    public el: ElementRef,
    private languageService: LanguageService,
    ) {
    super(el);
    this.separator = ' | ';
  }

  ngOnInit(): void {
    this.translateSubtitlesNames();
    const tags = this.tags?.map(tag => tag.value);
    const subgenereValue = this.subgenere ? `${this.subgenere.value}, ` : '';
    if (tags) {
      this.formattedTags = subgenereValue + tags.join(', ');
    }
  }

  translateSubtitlesNames(){
    this.translatedSubtitles = this.subtitlesAvailable?.map(subtitle =>
      this.languageService.getLanguageName$(subtitle, this.languageService.currentLang));
  }

  onDownKey(): boolean {
    const d = document.getElementById('detail-info-wrapper');
    d.scrollTop += 95;
    return true;
  }

  onUpKey(): boolean {
    const d = document.getElementById('detail-info-wrapper');
    d.scrollTop -= 95;
    if (d.scrollTop === 0) {
      this.selectChildNodeById(0);
      return false;
    }
    return true;
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
      return false;
    }
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  getAudioLabel(audio: any): string {
    const label = audio.label.find((data: AudioApi) => data.language === this.languageService.currentLang);
    return label?.value;
  }
}

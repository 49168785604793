import {
  AnalyticsFrontApi,
  AudioApi,
  CategoryFrontApi,
  CreditsFrontApi,
  FilmExtraDataFrontApi,
  ItemTypeApi,
  SeriesExtraDataFrontApi,
  ShortExtraDataFrontApi,
  TagResponseApi,
  TermApi,
  ThumbnailFrontApi,
  VideoAccessApi,
  VideoExtraDataFrontApi,
  VideoUriApi
} from '@api/models';
import { VideoFrontApi } from '@app/galgo-api/models/video-front-api';
import { Layout } from '@core/models/layout.model';
import { IMediaItem } from '@core/models/media-item.model';
import { IMediaOrigin } from '@shared/models/media-origin.interface';
import { PlayerTypeApi } from '../../../galgo-api/models/player-type-api';
import { CategoryTypeEnum } from '../category-type.enum';


export class Video implements IMediaItem {
  private _id: string;
  private _access: string;
  private _analytics: AnalyticsFrontApi;
  private _audios: Array<AudioApi>;
  private _categories: CategoryFrontApi[];
  private _date: number;
  private _description: string | Array<TermApi>;
  private _duration: number;
  private _extraData: FilmExtraDataFrontApi | SeriesExtraDataFrontApi | ShortExtraDataFrontApi | VideoExtraDataFrontApi;
  private _itemType: ItemTypeApi;
  private _keywords: string;
  private _layout: Layout;
  private _live: boolean;
  private _playerType: PlayerTypeApi;
  private _publishingDate: number;
  private _ranking: number;
  private _shortDescription: string | Array<TermApi>;
  private _slug: string | Array<TermApi>;
  private _thumbnail: ThumbnailFrontApi;
  private _title: string | Array<TermApi>;
  private _isFavourite: boolean;
  private _type: string;
  private _products: string[];
  private _origin: IMediaOrigin;
  private _hasSubscription: boolean;
  private _position: number;
  private _url: string;
  private _trailer?: string;
  private _heroBannerSeconds: number;
  private _isTitle: boolean;
  private _notFullscreen: boolean;
  private _progress: number;
  private _startSecond: number;
  private _longDescription: string | Array<TermApi>;
  private _historicalNote: string | Array<TermApi>;
  private _participants: CreditsFrontApi[];
  private _fullscreen: boolean;
  private _storyboard?: string;
  private _hasAudio?: boolean;
  private _originalTitle?: string;


  get id(): string {
    return this._id;
  }

  get contentType(): TagResponseApi {
    return this.layout.other.find(cat => cat.categoryTypeId === CategoryTypeEnum.contentType);
  }

  get extraData(): FilmExtraDataFrontApi | SeriesExtraDataFrontApi | ShortExtraDataFrontApi | VideoExtraDataFrontApi {
    return this._extraData;
  }

  get hasAudio(): boolean {
    return this._hasAudio;
  }

  get ranking(): number {
    return this._ranking;
  }

  get publishingDate(): number {
    return this._publishingDate;
  }

  get playerType(): PlayerTypeApi {
    return this._playerType;
  }

  get heroBannerSeconds(): number {
    return this._heroBannerSeconds;
  }

  get fullscreen(): boolean {
    return this._fullscreen;
  }

  get notFullscreen(): boolean {
    return this._notFullscreen;
  }

  set notFullscreen(value: boolean) {
    this._notFullscreen = value;
  }

  get startSecond(): number {
    return this._startSecond;
  }

  set startSecond(value: number) {
    this._startSecond = value;
  }

  get subgenre(): TagResponseApi {
    return this.layout?.subgenre.length ? this.layout?.subgenre[0] : null;
  }

  get progress(): number {
    return this._progress;
  }

  set progress(value: number) {
    this._progress = value;
  }

  get live(): boolean {
    return this._live;
  }

  get isTitle(): boolean {
    return this._isTitle;
  }

  get layout(): Layout {
    return this._layout;
  }

  get keyWords(): string {
    return this._keywords;
  }

  get itemType(): ItemTypeApi {
    return this._itemType;
  }

  get duration(): number {
    return this._duration;
  }
  get access(): string {
    return this._access;
  }
  get date(): number {
    return this._date;
  }

  get slug(): string {
    return this._slug instanceof Array ? this._slug[0].value : this._slug;
  }
  get categories(): CategoryFrontApi[] {
    return this._categories;
  }
  get description(): string {
    return this._description instanceof Array ? this._description[0].value : this._description;
  }
  get shortDescription(): string {
    return this._shortDescription instanceof Array ? this._shortDescription[0].value : this._shortDescription;
  }
  get title(): string {
    return this._title instanceof Array ? this._title [0].value : this._title;
  }
  get thumbnail(): ThumbnailFrontApi {
    return this._thumbnail;
  }
  get analytics(): AnalyticsFrontApi {
    return this._analytics;
  }

  get type(): string {
    return this._type;
  }

  get isFavourite(): boolean {
    return this._isFavourite;
  }

  set isFavourite(value: boolean) {
    this._isFavourite = value;
  }

  get products(): string[] {
    return this._products;
  }

  get isSubscriptionAccess() {
    return this._access === VideoAccessApi.Subscription;
  }

  get origin(): IMediaOrigin {
    return this._origin;
  }

  set hasSubscription(value: boolean) {
    this._hasSubscription = value;
  }

  get hasSubscription(): boolean {
    return this._hasSubscription;
  }

  set position(value: number) {
    this._position = value;
  }

  get position(): number {
    return this._position;
  }

  get storyboard(): string{
    return this._storyboard;
  }
  set url(value: string) {
    this._url = value;
  }

  get url(): string {
    return this._url;
  }

  get trailer(): string {
    return this._trailer;
  }

  get longDescription(): string {
    return this._longDescription instanceof Array ? this._longDescription[0].value : this._longDescription;
  }

  get historicalNote(): string {
    return this._historicalNote instanceof Array ? this._historicalNote[0].value : this._historicalNote;
  }

  get participants(): CreditsFrontApi[]{
    return this._participants;
  }

  get audios(): Array<AudioApi> {
    return this._audios;
  }

  get originalTitle(): string {
    return this._originalTitle;
  }

  constructor(init?: IVideoApi, origin?: IMediaOrigin) {
    if (init) {
      this._id = init?._id?.toString();
      this._access = init?.access;
      this._audios = init?.audios;
      this._slug = init?.slug;
      this._categories = init?.categories;
      this._description = init?.description;
      this._thumbnail = init?.thumbnail;
      this._analytics = init?.analytics;
      this._layout = new Layout(init?.layout);
      this._ranking = init?.ranking;
      this._date = init?.date;
      this._duration = init?.duration;
      this._startSecond = init?.startSecond;
      this._itemType = init?.itemType;
      this._keywords = init?.keywords;
      this._live = init?.live;
      this._playerType = init?.playerType;
      this._products = init?.products;
      this._shortDescription = init?.shortDescription;
      this._title = init?.title;
      this._type = ItemTypeApi?.Video;
      this._origin = origin;
      this._hasSubscription = false;
      this._heroBannerSeconds = init?.heroBannerSeconds;
      this._position = init?.position;
      this._url = init?.url;
      this._isTitle = false;
      this._trailer = this.setTrailerUrl(init?.trailer);
      this._progress = this.calculateProgress();
      this._storyboard = init?.storyboard;
      this._longDescription = init?.longDescription ? init?.longDescription : init?.description;
      this._historicalNote = init?.extraData?.historicalNote;
      this._participants = init?.participants;
      this._hasAudio = init?.hasAudio;
      this._extraData = init?.extraData;
      this._originalTitle = init?.originalTitle;
    }
  }

  calculateProgress() {
    return (100 * this._startSecond) / this._duration;
  }

  private setTrailerUrl(trailerUri: VideoUriApi) {
    return trailerUri?.hls?.uri || trailerUri?.dash?.uri || trailerUri?.master;
  }
}

export interface IVideoApi extends VideoFrontApi {
  position?: number;
  url?: string;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { BackgroundThumbnailModule } from '@app/shared/components/background-thumbnail/background-thumbnail.module';
import { ButtonModule } from '@app/shared/components/button/button.module';
import 'hammerjs';
import { VisModule } from 'ngx-vis';
import { DaySelectorComponent } from './components/day-selector/day-selector.component';
import { EpgComponent } from './epg.component';
import { EPGService } from './services/epg.service';
const ANGULAR_MODULES = [ CommonModule ];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    BackgroundThumbnailModule,
    NavigationModule,
    VisModule,
    ButtonModule
  ],
  providers: [
    EPGService,
  ],
  declarations: [ EpgComponent, DaySelectorComponent ],
  exports: [ EpgComponent ]
})
export class EpgModule { }

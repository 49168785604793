import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { Video } from '@app/shared/models/video/video.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { SettingsService } from '../../settings/services/settings.service';
import { LivesConfiguration } from './lives.config';

@UntilDestroy()
@Component({
  selector: 'ty-lives',
  templateUrl: './lives.component.html',
  styleUrls: ['./lives.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: LivesComponent }]

})
export class LivesComponent extends NavigationComponent implements OnInit, OnDestroy{
  @ViewChild('lives') lives!: ElementRef;
  mediaItems: MediaItem<MediaItemTypes>[];
  isDataReceived: boolean;
  currentUrl: string;
  title: string;
  scrollTop: number;
  hasEPG: boolean;


  constructor(
    private browsingHistoryService: BrowsingHistoryService,
    private featureFlagsService: FeatureFlagsService,
    public logger: NGXLogger,
    private settingsService: SettingsService,
    private videoDetailService: VideoDetailService,
    public el: ElementRef
  ) {
    super(el);
    this.isDataReceived = false;
    this.scrollTop = 0;
    this.title = LivesConfiguration.title;
    this.hasEPG = this.featureFlagsService.currentFeaturesValue.enableEpg;
   }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    this.setDefaultActiveChildNodeId(0);
    this.settingsService.languageSet$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
        if (focusData?.lives) {
          this.mediaItems = focusData.lives.items;
          this.isDataReceived = true;
        } else {
          this.getLives();
        }
      },
    });
  }

  ngOnDestroy(): void {
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      focusData.lives = {
        items: this.mediaItems,
      };
    }
  }

  getLives(){
    this.videoDetailService.getLinealChannels().pipe(untilDestroyed(this)).subscribe({
      next: (items: Video[]) => {
        this.isDataReceived = true;
        this.mediaItems = items;
        this.logger.debug(
          'CollectionsMenuComponent -> GetCollections(): Success',
          items
        );
      },
      error: (error: HttpErrorResponse) => {
        this.logger.error(
          'CollectionsMenuComponent -> GetCollections(): Failed',
          error
        );
      }
    });
  }

  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.lives.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

}

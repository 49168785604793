import { Injectable } from '@angular/core';
import { ContainerApi, DogLogoApi, VideoFrontApi, VideoUriFrontApi } from '@api/models';
import { SlotResponseApi } from '@api/models/slot-response-api';
import { ContainerApiService, VideoApiService } from '@api/services';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';

import { LanguageService } from '@app/core/services/language.service';
import { Collection } from '@app/shared/models/collection/collection.model';
import { CacheService } from '@app/shared/services/cache.service';
import { Container, IContainer } from '@core/models/container.model';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { ContainerService } from '@core/services/container.service';
import { IVideoApi, Video } from '@shared/models/video/video.model';

import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ContainerByIdParams } from '../../home/services/container-by-id-params.interface';
import { VideoByIdParams } from './video-by-id-params.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoDetailService {
  private videoNext: VideoFrontApi;
  private dogLog: DogLogoApi;

  constructor(
    private cacheService: CacheService,
    private containerService: ContainerService,
    private containerApiService: ContainerApiService,
    private featureFlagsService: FeatureFlagsService,
    private languageService: LanguageService,
    private videoApiService: VideoApiService
  ) { }

  getVideoDetails(videoId: string): Observable<Video> {
    const cacheKey = this.cacheService.generateCacheKey(`getDetailSheet:${videoId}`);
    return this.cacheService.getFromCache<Video>(cacheKey).pipe(
      switchMap((cachedVideo: Video | null) => {
        if (cachedVideo) {
          return of(cachedVideo);
        } else {
          const videoParams: VideoByIdParams = {
            id: videoId
          };
          return this.videoApiService.videoControllerFindById(videoParams).pipe(
            map((response: IVideoApi) => new Video(response)),
            tap(video => this.cacheService.addToCache(cacheKey, video))
          );
        }
      })
    );
  }

  getCollectionDetails(containerId: string): Observable<Collection> {
    const cacheKey = this.cacheService.generateCacheKey(`getDetailSheet:${containerId}`);
    return this.cacheService.getFromCache<Collection>(cacheKey).pipe(
      switchMap((cachedCollection: Collection | null) => {
        if (cachedCollection) {
          return of(cachedCollection);
        } else {
          const collectionParams: ContainerByIdParams = {
            id: containerId
          };
          return this.containerApiService.containerControllerFindById(collectionParams).pipe(
            map((response: IContainer) => {
              const collection = new Collection(response);
              this.cacheService.addToCache(cacheKey, collection);
              return collection;
            })
          );
        }
      })
    );
  }

  setVideoNext(video: VideoFrontApi): void {
    this.videoNext = video;
  }

  setDog(logo: DogLogoApi): void {
    this.dogLog = logo;
  }

  getDogLogo(): DogLogoApi {
    return this.dogLog;
  }


  getVideoNext(): VideoFrontApi {
    return this.videoNext;
  }

  deleteVideoNext(): void {
    this.videoNext = null;
  }

  getVideoURI(videoId: string): Observable<VideoUriFrontApi> {
    const params = {
      id: videoId
    };
    return this.videoApiService.videoControllerGetUris(params).pipe(
      tap((response: VideoUriFrontApi) => {
        if (response.next.video) {
          this.setVideoNext(response.next.video);
        }
        if (response.uris.dog) {
          this.setDog(response.uris.dog);
        }
      })
    );
  }

  getSimilarContent(videoId: string): Observable<MediaItem<MediaItemTypes>[]> {
    const params = {
      id: videoId
    };
    return this.videoApiService.videoControllerFindRelatedContainer(params)
      .pipe(
        map((response: ContainerApi) => {
          const relatedContainer: IContainer = response as IContainer;
          if (!this.featureFlagsService.currentFeaturesValue.enableCollections) {
            relatedContainer.subcontainers = this.filterCollections(relatedContainer.subcontainers);
          }
          return this.containerService.getMediaItemsFromContainer(new Container(relatedContainer));
        }
        )
      );
  }

  getLinealChannels(): Observable<Video[]> {
    return this.videoApiService.videoControllerLinealChannels({language: this.languageService.currentLang})
    .pipe(map(videos => videos.map(video => new Video(video))));
  }

  pingVideo(videoId: string): Observable<SlotResponseApi> {
    return this.videoApiService.videoControllerMantainSlot({id: videoId});
  }

  getEPGEvent(channel: string, event: string ,token: string): Observable<VideoUriFrontApi> {
      return this.videoApiService.epgControllerGetEventUrisFromLinealChannel({channelId: channel, eventId: event, Authorization: token});
  }

  private filterCollections(container: IContainer[]): IContainer[] {
    container.forEach((slider: IContainer) => {
      slider.subcontainers = [];
    });
    return container;
  }



}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/auth/services/user/user.service';
import { ContainerTypes } from '@app/core/models/container-types.enum';
import { Container, IContainerParams } from '@app/core/models/container.model';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { ContainerService } from '@app/core/services/container.service';
import { LanguageService } from '@app/core/services/language.service';
import { MySpaceService } from '@app/core/services/my-space.service';
import { SettingsService } from '@app/pages/protected/settings/services/settings.service';
import { CategoryTypeEnum } from '@app/shared/models/category-type.enum';
import { Collection } from '@app/shared/models/collection/collection.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { ContainerGridType, ContainerGridTypes } from '../models/container-grid-types';

@UntilDestroy()
@Component({
  selector: 'ty-container-grid',
  templateUrl: './container-grid.component.html',
  styleUrls: ['./container-grid.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ContainerGridComponent }]

})
export class ContainerGridComponent extends NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('containers') containers!: ElementRef;

  mediaItems: MediaItem<MediaItemTypes>[];
  containerItems: Container[];
  gridType: ContainerGridType;
  categoryTypeId: CategoryTypeEnum;
  isDataReceived: boolean;
  currentUrl: string;
  title: string;
  scrollTop: number;
  noItemsTitle = 'containerGrid.noItemsTitle';
  noItemsText = 'containerGrid.noItemsText';

  constructor(
    private browsingHistoryService: BrowsingHistoryService,
    private containerService: ContainerService,
    private languageService: LanguageService,
    private mySpaceService: MySpaceService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private userService: UserService,
    public logger: NGXLogger,
    public el: ElementRef

  ) {
    super(el);
    this.gridType = this.route.snapshot.data.containerType;
    if (this.gridType === ContainerTypes.categoryType) {
      this.categoryTypeId = this.route.snapshot.data.categoryTypeId;
    }
    this.isDataReceived = false;
    this.scrollTop = 0;
  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    this.settingsService.languageSet$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        const focusData = this.getFocusData();
        if (focusData) {
          this.mediaItems = focusData.items;
          this.title = focusData.title;
          this.isDataReceived = true;
        } else {
          this.getMediaItems();
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.saveFocusData();
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }


  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.containers.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  private getFocusData(): any {
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    switch(this.gridType) {
      case ContainerTypes.collection:
        return focusData?.collectionmenu;
      case ContainerTypes.categoryType:
        switch(this.categoryTypeId) {
          case CategoryTypeEnum.topics:
            return focusData?.topicsmenu;
          default:
            return null;
        }
      case ContainerGridTypes.purchases:
        return focusData?.purchases;
      default:
        return null;
    }
  }

  private getMediaItems() {
    let obs: Observable<(MediaItem<MediaItemTypes> | Container )[] | Container>;
    const channels = this.currentUrl.substring(this.currentUrl.lastIndexOf('/') + 1);
    const mediaId = this.route.snapshot.params.id;

    if (channels === 'channels') {
      obs = this.containerService.getContainerById({
        id: 'channels',
        page: 1,
        size: 1000
      });
    } else if (mediaId) {
      obs = this.containerService.getContainerById({
        id: mediaId,
        page: 1,
        size: 1000
      });
    } else if (Object.values(ContainerTypes).includes(this.gridType as ContainerTypes)) {
      if (this.currentUrl === AppRoutes.collectionsMenu){
        obs = this.containerService.getContainerById({
          id: 'collections',
          page: 1,
          size: 1000
        });
      } else {
        obs = this.getContainerByContainerType(this.gridType as ContainerTypes);
      }
    } else {
      switch (this.gridType) {
        case ContainerGridTypes.purchases:
          obs = this.userService.getUserMediaItemPurchases();
          break;
        case ContainerGridTypes.mySpace:
          obs = this.getFavorites();
          break;
      }
    }

    if (!obs) {
      this.router.navigate(['/404']);
      return;
    }

    obs.pipe(untilDestroyed(this)).subscribe({
      next: (items: (Collection[] | Container)) => {
        this.isDataReceived = true;
        if (items instanceof Container) {
          const mappedSubcontainers = items.subContainers.map(subcontainer => new Collection(subcontainer));
          this.mediaItems = [...mappedSubcontainers, ...items.videos];
          if (channels === 'channels') {
            this.setTexts(channels);

          }else{
            this.setTexts(items.name);
          }
        } else if (this.gridType === ContainerTypes.categoryType) {
          if (items.length) {
            this.mediaItems = items[0]?.subContainers.map(subcontainer => new Collection(subcontainer));
            this.setTexts(items[0]?.name);
          }
        } else {
          this.mediaItems = items;
          this.setTexts();
        }
        this.logger.debug(
          'CollectionsMenuComponent -> GetCollections(): Success',
          items
        );
      },
      error: (error: HttpErrorResponse) => {
        this.logger.error(
          'CollectionsMenuComponent -> GetCollections(): Failed',
          error
        );
      }
    });
  }

  private setTexts(title?: string): void {
    if (title === 'channels' || title === 'Collections') {
      this.title = 'menu.' + title.toLowerCase();
      return;
    }
    if (title) {
      this.title = title;
      return;
    }
    this.title = 'menu.' + this.gridType.toLowerCase();

    switch (this.gridType) {
      case ContainerTypes.collection:
      case ContainerGridTypes.purchases:
        this.noItemsTitle = 'purchases.no-purchases';
        this.noItemsText = 'purchases.text';
        break;
      case ContainerGridTypes.mySpace:
        this.noItemsTitle = 'my-space.noFavouritesTitle';
        this.noItemsText = 'my-space.noFavouritesText';
        break;
    }
  }

  private getContainerByContainerType(containerType: ContainerTypes): Observable<Container[]> {
    const params: IContainerParams = {
      language: this.languageService.currentLang,
      containerType,
      categoryType: this.categoryTypeId,
      page: 0,
      size: 1000,
    };
    return this.containerService.getContainerByContainerType(params);
  }

  private saveFocusData(): void {
    const items = {
      items: this.mediaItems,
      title: this.title,
    };
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      switch(this.gridType) {
        case ContainerTypes.collection:
          focusData.collectionmenu = items;
          break;
        case ContainerTypes.categoryType:
          switch(this.categoryTypeId) {
            case CategoryTypeEnum.topics:
              focusData.topicsmenu = items;
              break;
          }
          break;
        case ContainerGridTypes.purchases:
          focusData.purchases = items;
      }
    }
  }

  private getFavorites(): Observable<MediaItem<MediaItemTypes>[]> {
    return this.mySpaceService.getFavorites();
  }
}

import { IModalData } from '@app/shared/models/modal-data.interface';
import { ModalActions } from './modal-actions.enum';

export const PASSWORD_CHANGE_REQUIRED_MODAL_DATA: IModalData = {
  title: 'modal.password-change-required.title',
  content: 'modal.password-change-required.content',
  icon: 'warning-icon',
  buttons: [
    {
      text: 'buttons.close',
      action: ModalActions.close
    }
  ]
};

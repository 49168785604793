<h1>{{'exit.message' | translate}}</h1>
<div class="primary-button button-wrapper"
     (click)="exitApp()"
     appNavigation
     [navigableId]="0">
  <button class="button button-large">
    <h4>{{'exit.accept' | translate}}</h4>
  </button>
</div>
<div class="secondary-button button-wrapper"
     (click)="navigateBack()"
     appNavigation
     [navigableId]="1">
  <button class="button button-large">
    <h4>{{'exit.cancel' | translate}}</h4>
  </button>
</div>

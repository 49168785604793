/* tslint:disable */
/* eslint-disable */
export enum PlatformApi {
  Android = 'android',
  Androidtv = 'androidtv',
  Ios = 'ios',
  Smarttv = 'smarttv',
  Web = 'web',
  Roku = 'roku'
}

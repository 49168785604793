
import { CreditsFrontApi, VideoFrontApi } from '@app/galgo-api/models';
import { AdvertisementApi } from '@app/galgo-api/models/advertisement-api';
import { AnalyticsFrontApi } from '@app/galgo-api/models/analytics-front-api';
import { ContainerApi } from '@app/galgo-api/models/container-api';
import { ContainerSimpleApi } from '@app/galgo-api/models/container-simple-api';
import { LayoutFrontApi } from '@app/galgo-api/models/layout-front-api';
import { ThumbnailFrontApi } from '@app/galgo-api/models/thumbnail-front-api';
import { MediaItem } from '@core/models/media-item.model';
import { Video } from '@shared/models/video/video.model';
import { IBaseApiParams } from './base-api-params.interface';
import { ContainerTypes } from './container-types.enum';

export interface IContainerByIdParams extends IBaseApiParams {
  id: string;
  pv?: number;
  sv?: number;
}
export interface IContainerParams extends IBaseApiParams {
  /**
   * Page container number. Minimum value: 1, Default: 1
   */
  page?: number;

  /**
   * Page container size. Minimum value: 1, Default: 15
   */
  size?: number;

  /**
   * Page Videos number. Only for this container. Minimum value: 1, Default: 1
   */
  pv?: number;

  /**
   * Page Videos size. For this container and its subcontainers. Minimum value: 1, Default: 15
   */
  sv?: number;

  /**
   * set version 2 to get results by container slug
   */
  version?: number;
  language?: string;
/**
 * Container type
 */
  containerType: ContainerTypes;
  /**
   * CategoryTypeId. Only needed for containerType="CategoryType"
   */
  categoryType?: number;
}

export interface IContainer extends ContainerApi, ContainerSimpleApi {
  analytics?: AnalyticsFrontApi;
  categoriesOperator?: string;
  createdAt?: string;
  datePublishEnd?: string;
  datePublishStart?: string;
  heroBanner?: Array<VideoFrontApi | AdvertisementApi>;
  hasAds?: boolean;
  isHome?: boolean;
  isSuggestedForHome?: boolean;
  heroBannerSeconds?: number;
  position?: number;
  published?: boolean;
  removed?: boolean;
  shortDescription?: string;
  subcontainers?: any[];
  updatedAt?: string;
  layout?: LayoutFrontApi;
  ranking?: number;
  isCustom?: boolean;
  url?: string;
  participants?: CreditsFrontApi[];
  customRoute?: string;
}

export interface ISimpleContainer {
  subcontainers: any[];
  videos: VideoFrontApi[];
}

export class Container {
  private _id: string;
  private _analytics: AnalyticsFrontApi;
  private _description: string;
  private _hasAds: boolean;
  private _name: string;
  private _published: boolean;
  private _shortDescription: string;
  private _slug: string;
  private _subcontainers: any[];
  private _itemType: string;
  private _isCustom: boolean;
  private _videos: MediaItem<Video>[];
  private _position: number;
  private _categoriesOperator: string;
  private _createdAt: string;
  private _datePublishEnd: string;
  private _datePublishStart: string;
  private _heroBanner: (VideoFrontApi | AdvertisementApi)[];
  private _isHome?: boolean;
  private _isSuggestedForHome?: boolean;
  private _removed?: boolean;
  private _updatedAt?: string;
  private _customRoute: string;
  private _thumbnail: ThumbnailFrontApi;
  private _participants: CreditsFrontApi[];

  get id(): string {
    return this._id;
  }
  get subContainers(): any[] {
    return this._subcontainers;
  }

  get heroBanner(): (VideoFrontApi | AdvertisementApi)[] {
    return this._heroBanner;
  }

  get isHome(): boolean {
    return this._isHome;
  }

  get customRoute(): string {
    return this._customRoute;
  }

  get isSuggestedForHome(): boolean {
    return this._isSuggestedForHome;
  }

  get isRemoved(): boolean {
    return this._removed;
  }

  get categoriesOperator(): string {
    return this._categoriesOperator;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  get datePublishEnd(): string {
    return this._datePublishEnd;
  }

  get datePublishStart(): string {
    return this._datePublishStart;
  }

  get createdAt(): string {
    return this._createdAt;
  }
  get slug(): string {
    return this._slug;
  }
  get hasAds(): boolean {
    return this._hasAds;
  }
  get isPublished(): boolean {
    return this._published;
  }
  get description(): string {
    return this._description;
  }
  get shortDescription(): string {
    return this._shortDescription;
  }

  set title(value: string) {
    this._name = value;
  }

  get title(): string {
    return this._name;
  }

  get analytics(): AnalyticsFrontApi {
    return this._analytics;
  }

  get participants(): CreditsFrontApi[] {
    return this._participants;
  }

  get position(): number {
    return this._position;
  }

  set itemType(value: string) {
    this._itemType = value;
  }

  get itemType(): string {
    return this._itemType;
  }

  set isCustom(value: boolean) {
    this._isCustom = value;
  }

  get isCustom(): boolean {
    return this._isCustom;
  }

  get videos(): MediaItem<Video>[] {
    return this._videos;
  }

  set videos(value: MediaItem<Video>[]) {
    this._videos = value;
  }

  get hasVideos(): boolean {
    return !!this._videos?.length;
  }

  get hasCollections(): boolean {
    return !!this._subcontainers?.length;
  }

  get thumbnail(): ThumbnailFrontApi {
    return this._thumbnail;
  }

  get name(): string {
    return this._name;
  }

  constructor(init?: IContainer) {
    if (init) {
      this._id = init?._id?.toString();
      this._slug = init?.slug;
      this._hasAds = init?.hasAds;
      this._published = init?.published;
      this._description = init?.description;
      this._shortDescription = init?.shortDescription;
      this._name = init?.name;
      this._analytics = init?.analytics;
      this._itemType = init?.itemType;
      this._isCustom = init?.isCustom;
      this._videos = this.formatVideos(init?.videos);
      this._subcontainers = init?.subcontainers;
      this._position = init?.position;
      this._hasAds = init?.hasAds;
      this._categoriesOperator = init?.categoriesOperator;
      this._createdAt = init?.createdAt;
      this._datePublishEnd = init?.datePublishEnd;
      this._datePublishStart = init?.datePublishStart;
      this._heroBanner = init?.heroBanner;
      this._isHome = init?.isHome;
      this._isSuggestedForHome = init?.isSuggestedForHome;
      this._removed = init?.removed;
      this._updatedAt = init?.updatedAt;
      this._customRoute = init?.customRoute;
      this._thumbnail = init?.thumbnail;
      this._participants = init?.participants;
    }
  }

  private formatVideos(videos: (VideoFrontApi | AdvertisementApi)[]): MediaItem<Video>[] {
    let mediaItemVideos: MediaItem<Video>[] = [];
    if (!!videos?.length) {
      mediaItemVideos = videos.map((video: VideoFrontApi) => new Video(video));

      return mediaItemVideos;
    }

    return [];
  }
}

import { Component, ElementRef } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { KeyboardSearchService } from '@app/shared/services/keyboard-search.service';
import { IconList } from '@core/models/icon-list.enum';
import { IKeyboardEvent } from '@shared/models/keyboard-event.interface';
import { BackSpaceKey, KeyboardKeys, SearchKey, SpaceKey } from '@shared/models/keyboard-options.enum';
import { downLeftValues, downRightValues, leftValues, rightValues } from './config/keyboard-navigation.config';

@Component({
  selector: 'ty-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: KeyboardComponent }]
})

export class KeyboardComponent extends NavigationComponent {

  keyIndex: number;

  get backspaceKey(): IKeyboardEvent {
    return BackSpaceKey;
  }

  get keyboardKeys(): IKeyboardEvent[] {
    return KeyboardKeys;
  }

  get searchKey(): IKeyboardEvent {
    return SearchKey;
  }

  get spaceKey(): IKeyboardEvent {
    return SpaceKey;
  }

  get backspaceIconUrl(): string {
    return `./assets/icons/${IconList.backSpaceIcon}#icon`;
  }

  get spaceIconIUrl(): string {
    return `./assets/icons/${IconList.spaceIcon}#icon`;
  }

  get searchIconUrl(): string {
    return `./assets/icons/${IconList.searchIcon}#icon`;
  }

  constructor(
    private keyboardSearchService: KeyboardSearchService,
    public el: ElementRef
  ) {
    super(el);
    this.keyIndex = 0;
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onDownKey(): boolean {
    if (downLeftValues.includes(this.getSelectedChildNodeId())) {
      this.selectChildNodeById(36);
    }else if (downRightValues.includes(this.getSelectedChildNodeId())) {
      this.selectChildNodeById(37);
    }else{
      this.selectChildNodeById(this.getSelectedChildNodeId()+6);
    }
    return true;
  }

  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() === 36) {
      this.selectChildNodeById(30);
    }else if (this.getSelectedChildNodeId() === 37) {
      this.selectChildNodeById(33);}else{
        this.selectChildNodeById(this.getSelectedChildNodeId()-6);
      }
    return true;
  }


  onLeftKey(): boolean {
    if (leftValues.includes(this.getSelectedChildNodeId())) {
      return false;
    }
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    if (rightValues.includes(this.getSelectedChildNodeId()) || this.getSelectedChildNodeId() === 37) {
      return false;
    }
    this.selectNextChildNode();
    return true;
  }

  setSelectedItem(keyIndex) {
    this.keyIndex = keyIndex;
  }

  keyboardEvent(eventKeyboard: IKeyboardEvent) {
    this.keyboardSearchService.keyboardEvent(eventKeyboard);
  }
}

<div class="button-wrapper secondary-button"
     appNavigation
     [navigableId]="0"
     (click)="changeState()"
     (focus)="focus()"
     (mouseover)="focus()"
     (blur)="blur()"
     (mouseleave)="blur()">
  <button class="button button-large">
    <div class="content">
      <svg *ngIf="iconName"
           class="icon"
           [class.icon--space]="showButtonText"
           aria-hidden="true"
           viewBox="0 0 32 32">
        <use [attr.href]="'./assets/icons/' + iconName + '#icon'"></use>
      </svg>
      <span *ngIf="showButtonText"
            class="text">{{buttonText | translate}}</span>
    </div>
  </button>
</div>
<div class="button-wrapper primary-button"
     [id]="selectorId"
     appNavigation
     [navigableId]="0"
     (focus)="focus()"
     (mouseover)="focus()"
     (blur)="blur()"
     (mouseleave)="blur()">
  <button class="button button-large">
    <div class="content">
      <svg *ngIf="iconName"
           class="icon icon--space"
           aria-hidden="true"
           viewBox="0 0 32 32">
        <use [attr.href]="iconName"></use>
      </svg>
      <span class="text">{{ text | translate }}</span>
    </div>
  </button>
</div>
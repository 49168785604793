<ty-background-thumbnail class="co-background-thumbnail"
                         *ngIf="thumbnail"
                         [thumbnail]="thumbnail"
                         [isHeaderHidden]="isHeaderHidden">
</ty-background-thumbnail>
<ty-galgo-player id="galgo-player"
                 [ngClass]="{'trailer-loading': !trailerStart }"
                 *ngIf="containerType === 'Video' && mediaItem && trailer && trailerFlag"
                 [playerDetails]="playerDetails"
                 [mediaItem]="mediaMetas"
                 [options]="options"
                 [isTrailer]="isTrailer">
</ty-galgo-player>
<div class="content-detail"
     [class.hidden]="isHeaderHidden">
  <div class="content-detail-container">
    <div class="detail">
      <div *ngIf="showBackButton || containerType === 'Microsite' || containerType === 'Collection'"
           class="return-button">
        <ty-return-button [navigableId]="(containerType === 'Microsite' || containerType === 'Collection') ? 1 : 0"
                          (backEvent)="onBackKey()"></ty-return-button>
      </div>
      <div *ngIf="!showBackButton"
           class="spacer"></div>
      <ty-detail [media]="mediaMetas"
                 [detailConfiguration]="videoDetailConfig"></ty-detail>
      <div *ngIf="containerType === 'Video' || containerType === 'Series'"
           class="button-list"
           id="button-list">
        <ty-button-list [navigableId]="1"
                        class="button-component"
                        [btnList]="videoDetailBtnListConfig"
                        (clickEvent)="clickEvent($event)">
        </ty-button-list>
        <ty-my-space-button [navigableId]="2"
                            class="button-component"
                            [itemType]="itemType"
                            [showButtonText]="videoDetailBtnListConfig.length <= 1"
                            [id]="mediaId"></ty-my-space-button>
      </div>
      <div class="subscription-messages">
        <div *ngIf="showPPVMessage && purchaseEnd"
             class="ppv-text">{{ 'purchases.purchased-until' | translate }}{{ purchaseEnd }}</div>
        <div *ngIf="showSubscriptionMessage"
             class="subscription-text">{{ 'subscription.text' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="tabs-title"
     *ngIf="isHeaderHidden">
  <h1>{{videoTitle}}</h1>
</div>
<div class="tabs-wrapper">
  <ty-tabs [navigableId]="3"
           id="tabs"
           *ngIf="mediaMetas"
           [videoId]="mediaId"
           (closeTabs)="closeTabs()"
           (selectedTab)="selectTab($event)"></ty-tabs>

  <ng-container *ngIf="selectedTab === 'more-information'">
    <ty-more-information></ty-more-information>
  </ng-container>

  <ng-container *ngIf="selectedTab === 'similar'">
    <ty-similar [navigableId]="4"></ty-similar>
  </ng-container>

  <ng-container *ngIf="selectedTab === 'details'">
    <ty-details-tabs [navigableId]="4"></ty-details-tabs>
  </ng-container>

  <ng-container *ngIf="selectedTab === 'chapters'">
    <ty-chapters [navigableId]="4"></ty-chapters>
  </ng-container>
</div>
<ty-ppv-modal *ngIf="showPpvModal"
              [navigableId]="10"
              [price]="price"
              [isPPV]="price!==''"
              [categorySlug]="categorySlug"
              [videoId]="videoSlug"></ty-ppv-modal>

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'ty-list-selector-icon',
  templateUrl: './list-selector-icon.component.html',
  styleUrls: ['./list-selector-icon.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: ListSelectorIconComponent }],
})
export class ListSelectorIconComponent extends NavigationComponent {
  @Output() subtitledChanged = new EventEmitter<number>();
  @Input() itemTracks: any[];
  @Input() icon: string;
  @Input() typeOfList: string;

  showSubtitleList: boolean;
  showAVList$: Observable<boolean>;


  get itemType(): string {
    return `controls-player.` + this.typeOfList;
  }

  get showAVControlsPlayerText(): boolean {
    return this.customerDataService?.playerUI?.showAVControlsPlayerText;
  }

  get showAVControlsTooltip(): boolean {
    return this.customerDataService?.playerUI?.showAVControlsTooltip;
  }

  constructor(
    public el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private customerDataService: CustomerDataService,
    private galgoPlayerService: GalgoPlayerService
  ) {
    super(el);
    this.showSubtitleList = false;
    this.galgoPlayerService.setShowAVList(false);
    this.showAVList$ = this.galgoPlayerService.getShowAVList();
  }

  onEnterKey(): boolean {
    (this.getSelectedChildNode()?.el.nativeElement as HTMLElement)?.click();
    return true;
  }

  onClickBtn(): void {
    this.showSubtitleList = true;
    this.galgoPlayerService.setShowAVList(true);

  }

  onChildNodesUpdated(): void {
    if(this.getChildNodeById(1)){
      this.selectChildNodeById(1);
    }
  }

  changeItem(itemId: number): void {
    this.subtitledChanged.emit(itemId);
    this.onBackKey();
  }

  onBackKey(): boolean {
    if(this.showSubtitleList){
      this.showSubtitleList = false;
      this.galgoPlayerService.setShowAVList(false);
      this.changeDetectorRef.detectChanges();
      this.selectChildNodeById(0);
      return true;
    }
    return false;
  }

}

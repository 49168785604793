import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CustomErrorApi, ErrorResponseApi, SubtitleApi, VideoUriFrontApi } from '@api/models';
import { AuthService } from '@app/auth/services/auth-custom/auth.service';
import { MediaItem } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { playerOptions } from '@app/galgo-player/config';
import { IGalgoPlayerOptions, PlayerDetailType } from '@app/galgo-player/models';
import { IDrmConfig } from '@app/galgo-player/models/drm-config.interface';
import { GENREIC_ERROR_MODAL_DATA, ModalActions, REGISTER_MODAL_DATA } from '@app/shared/components/modal/config';
import { EVENT_ERROR_MODAL_DATA } from '@app/shared/components/modal/config/event-error-modal.config';
import { GEOBLOCKING_ERROR_MODAL_DATA } from '@app/shared/components/modal/config/geobloking-error-modal.config';
import { PPV_MODAL_DATA } from '@app/shared/components/modal/config/ppv-error-modal.config';
import { SLOTS_ERROR_MODAL_DATA } from '@app/shared/components/modal/config/slots-error-modal.config';
import { ModalService } from '@app/shared/components/modal/services/modal.service';
import { VideoUriFront } from '@app/shared/models/video-uri.model';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { PlayerDetailsService } from '@app/shared/services/player-details.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VideoDetailService } from '@pages/protected/media-detail/services/video-detail.service';
import { Video } from '@shared/models/video/video.model';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { videoPlayerEvents } from './config';
import { PLAYER_CONTROLS } from './video-controls';

@UntilDestroy()
@Component({
  selector: 'ty-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: PlayerComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerComponent extends NavigationComponent implements OnInit {

  videoId: string;
  videoUrl: string;
  drmConfig: IDrmConfig;
  videoMetas: Video;
  loadPlayer: boolean;
  mediaItem: Video;
  playerDetails: PlayerDetailType;
  options: IGalgoPlayerOptions;
  idChannel: string;
  idEvent: string;
  videoSubtitles: SubtitleApi[];
  adsUrl: string;

  constructor(
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private galgoPlayerService: GalgoPlayerService,
    private videoDetailService: VideoDetailService,
    private playerDetailsService: PlayerDetailsService,
    private modalService: ModalService,
    private browsingHistoryService: BrowsingHistoryService,
    private customerDataService: CustomerDataService,
    public logger: NGXLogger,
    public el: ElementRef
    ) {
      super(el);
      this.galgoPlayerService.reset();
      this.loadPlayer = false;
      this.galgoPlayerService.updateStatus(status => {
        status.enableControlsAndAnalytics = true;
      });
      this.mediaItem = this.metas;
      this.options = playerOptions;
  }

  get metas(): Video {
    return this.galgoPlayerService?.status?.metas;
  }

  ngOnInit(): void {
    this.getVideoIdFromUrl();
  }

  private getVideoIdFromUrl() {
    this.route.params.pipe(untilDestroyed(this)).subscribe(
      (params: Params) => {
        this.videoId = params.id;
        this.idChannel = this.route.snapshot.queryParams.idChannel;
        this.idEvent = this.route.snapshot.queryParams.idEvent;
        this.getVideoMetas();
        this.logger.debug('PlayerComponent -> GetVideoIdFromUrl(): Success', params);
      },
      (error: ErrorResponseApi) => {
        this.logger.error('PlayerComponent -> GetVideoIdFromUrl(): Failed', error);
        this.router.navigate([AppRoutes.home]);
      }
    );
  }

  private generatePlayerDetails(mediaItem: MediaItem<Video>) {
    this.playerDetails = this.playerDetailsService.generatePlayerDetails(
      mediaItem,
      this.videoUrl,
      videoPlayerEvents,
      this.videoSubtitles,
      this.adsUrl,
      this.drmConfig
    );
    this.logger.info('VideoDetailsComponent => generatePlayerDetails: details', this.playerDetails);
  }

  private getVideoUrl() {
    this.videoDetailService.getVideoURI(this.videoId)
      .pipe(map((video: VideoUriFrontApi) => new VideoUriFront(video)), untilDestroyed(this))
      .subscribe({
        next: (videoUri: VideoUriFront) => this.handleVideoUriResponse(videoUri),
         error: (error) => {
           this.errorType(error.error.error);
          },
        complete: () => {
          this.logger.info('PlayerComponent -> GetVideoUrl(): Completed');
        }
      });
  }

  private getEPGEventUrl() {
    const token = this.authService.token?.accessToken;
    this.videoDetailService.getEPGEvent(this.idChannel, this.idEvent, token)
      .pipe(map((video: VideoUriFrontApi[]) => new VideoUriFront(video[0])), untilDestroyed(this))
      .subscribe({
        next: (videoUri: VideoUriFront) => this.handleVideoUriResponse(videoUri),
        error: (error) => {
          this.errorType(error.error.error);
        },
        complete: () => {
          this.logger.info('PlayerComponent -> GetEPGEventUrl(): Completed');
        }
      });
  }

  private handleVideoUriResponse(videoUri: VideoUriFront): void {
    this.logger.debug('URIS: VideoDetailsComponent -> Success', videoUri);
    this.videoUrl = this.setVideoUrl(videoUri);
    this.drmConfig = videoUri.drmMetadata ? videoUri.drmConfig : undefined;
    this.videoMetas.startSecond = videoUri.startSecond;
    this.videoSubtitles = videoUri?.subtitles || [];
    this.adsUrl = videoUri.uris?.vast;
    this.galgoPlayerService.status.url = this.videoUrl;
    this.galgoPlayerService.status.metas = this.videoMetas;
    this.galgoPlayerService.updateStatus(status => {
        status.controls = PLAYER_CONTROLS;
        status.url = this.videoUrl;
        status.metas = this.videoMetas;
        status.title = this.videoMetas?.title;
        status.itemType = this.videoMetas?.itemType;
        status.playerType = this.videoMetas?.playerType;
    });
    this.logger.debug('PlayerComponent -> HandleVideoUriResponse(): Success', this.videoUrl);
    this.generatePlayerDetails(this.mediaItem);
    this.loadPlayer = true;
    this.changeDetector.detectChanges();
  }


  private errorType(error: ErrorResponseApi) {
    switch (error) {
      case CustomErrorApi.$4034:
        this.modalService.open(
          GEOBLOCKING_ERROR_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction)
        );
        this.logger.error('Video Geoblocked: ', error);
        break;
      case CustomErrorApi.$4032:
        this.modalService.open(
          REGISTER_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction)
        );
        this.logger.error('Video login: ', error);
        break;
      case CustomErrorApi.$40018:
        this.modalService.open(
          EVENT_ERROR_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction)
        );
        this.logger.error('Video login: ', error);
        break;
      case CustomErrorApi.$40019:
        this.modalService.open(
          EVENT_ERROR_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction)
        );
        this.logger.error('Video login: ', error);
        break;
      case CustomErrorApi.$4096:
        this.modalService.open(
          SLOTS_ERROR_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction)
        );
        this.logger.error('User slots error: ', error);
        break;
      case CustomErrorApi.$4021:
        this.modalService.open(
          PPV_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction)
        );
        this.logger.error('Video subscription error: ', error);
        break;
      default:
        this.modalService.open(GENREIC_ERROR_MODAL_DATA, this, (modalAction: ModalActions) => this.manageModalAction(modalAction));
        this.logger.error('PlayerComponent -> GetVideoUrl(): Failed', error);
        break;
    }
  }

  private manageModalAction(action: ModalActions): void {
    switch(action) {
      case ModalActions.identify:
        this.router.navigate([AppRoutes.login]);
        break;
      case ModalActions.retry:
        this.getVideoUrl();
        break;
      case ModalActions.return:
      case ModalActions.close:
      default:
        this.router.navigate([AppRoutes.home]);
        break;
    }
  }

  private setVideoUrl(videoURLRetrieved: VideoUriFront) {
    return (
      videoURLRetrieved?.uris?.dash?.uri ||
      videoURLRetrieved?.uris?.hls?.uri ||
      videoURLRetrieved?.uris?.master
    );
  }

  private getVideoMetas() {
    this.videoDetailService.getVideoDetails(this.videoId).pipe(untilDestroyed(this)).subscribe(
      (videoMetas: Video) => {
        this.videoMetas = videoMetas;
        this.mediaItem = videoMetas;
        if (this.idEvent) {
          this.getEPGEventUrl();
        }else {
          this.getVideoUrl();
        }
        this.logger.debug('PlayerComponent -> GetVideoMetas(): Success', videoMetas);
      },
      (error: HttpErrorResponse) => {
        this.logger.error('PlayerComponent -> GetVideoMetas(): Failed', error);
      }
    );
  }

}

import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { KeyCodesNames } from '../config/keycodes.config';
import { IKeyCodes } from '../models/keycodes.interface';

import { PlatformService } from './platform.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
/**
 * This is a service to listen key event pressed.
 */
export class KeyboardService {

  keyCodes: IKeyCodes;
  keyEvent: Observable<string>;


  private _subjectKeyEvent$: Subject<string>;

  constructor(
    public platformService: PlatformService,
    private logger: NGXLogger
  ) {
    this._subjectKeyEvent$ =  new BehaviorSubject<string>('');
    this.keyEvent = this._subjectKeyEvent$.asObservable();
    this.getKeyCodesByPlatform();
    document.addEventListener('wheel', (event: WheelEvent) => {
      this._subjectKeyEvent$.next(event.deltaY < 0 ? KeyCodesNames.up : KeyCodesNames.down);
    });
  }

  changeKeyboardEventListener(event: KeyboardEvent) {
    const code = event.keyCode || (!isNaN(Number(event.code)) ? Number(event.code) : 0);
    let key = '';
    switch (code) {
      case this.keyCodes.up:
        key = KeyCodesNames.up;
        break;
      case this.keyCodes.down:
        key = KeyCodesNames.down;
        break;
      case this.keyCodes.right:
        key = KeyCodesNames.right;
        break;
      case this.keyCodes.left:
        key = KeyCodesNames.left;
        break;
      case this.keyCodes.enter:
        key = KeyCodesNames.enter;
        event.preventDefault();
        break;
      case this.keyCodes.back:
        key = KeyCodesNames.back;
        break;
      case this.keyCodes.playPause:
        key = KeyCodesNames.playPause;
        break;
      case this.keyCodes.play:
        key = KeyCodesNames.play;
        break;
      case this.keyCodes.pause:
        key = KeyCodesNames.pause;
        break;
      case this.keyCodes.stop:
        key = KeyCodesNames.stop;
        break;
      case this.keyCodes.rewind:
        key = KeyCodesNames.rewind;
        break;
      case this.keyCodes.fastForward:
        key = KeyCodesNames.fastForward;
        break;
      case this.keyCodes.mediaTrackPrevious:
        key = KeyCodesNames.mediaTrackPrevious;
        break;
      case this.keyCodes.mediaTrackNext:
        key = KeyCodesNames.mediaTrackNext;
        break;
      case this.keyCodes.intro:
        key = KeyCodesNames.intro;
        break;
      case this.keyCodes.zero:
        key = KeyCodesNames.zero;
        break;
      case this.keyCodes.one:
        key = KeyCodesNames.one;
        break;
      case this.keyCodes.two:
        key = KeyCodesNames.two;
        break;
      case this.keyCodes.three:
        key = KeyCodesNames.three;
        break;
      case this.keyCodes.four:
        key = KeyCodesNames.four;
        break;
      case this.keyCodes.five:
        key = KeyCodesNames.five;
        break;
      case this.keyCodes.six:
        key = KeyCodesNames.six;
        break;
      case this.keyCodes.seven:
        key = KeyCodesNames.seven;
        break;
      case this.keyCodes.eight:
        key = KeyCodesNames.eight;
        break;
      case this.keyCodes.nine:
        key = KeyCodesNames.nine;
        break;
      default:
    }
    this.logger.debug('Key pressed: ' + key );
    this._subjectKeyEvent$.next(key as KeyCodesNames);
  }

  /**
   * Get key codes by platform (Tizen, WebOS or Standard Keyboard)
   */

  private getKeyCodesByPlatform(): void {
    this.platformService.keyCodes.pipe(untilDestroyed(this)).subscribe(
      {
        next: (keyCodes: IKeyCodes) => {
          this.keyCodes = keyCodes;
          this.logger.debug('KeyboardService - Get key codes by platform -> getKeyCodesByPlatform(): Success', this.keyCodes);
        },
        error: (error) => {
          this.logger.error('KeyboardService - Error getting key codes -> getKeyCodesByPlatform(): Failed', this.keyCodes);
        }
      }
    );
  }

}

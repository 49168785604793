import { IPlayerButtons } from '@app/shared/models/button-player.interface';
import { ActionList } from '@core/models/action-list.enum';
import { IconList } from '@core/models/icon-list.enum';

export const playerButtons: IPlayerButtons = {
  play_pause: {
    action: ActionList.playPause,
    play: {
      icon: {
        unfilled: IconList.pauseIconUnfilled,
        filled: IconList.pauseIconUnfilled
      },
      action: ActionList.play
    },
    pause: {
      icon: {
        filled: IconList.playerIconUnfilled,
        unfilled: IconList.playerIconUnfilled
      },
      action: ActionList.pause
    },
    initialPosition: true
  },
  rewind: {
    icon: {
      filled: IconList.rewindIcon,
      unfilled: IconList.rewindIconUnfilled
    },
    action: ActionList.rewind
  },
  forward: {
    icon: {
      filled: IconList.forwardIcon,
      unfilled: IconList.forwardIconUnfilled
    },
    action: ActionList.forward
  },
  nextChapter: {
    icon: {
      filled: IconList.nextChapterIcon,
      unfilled: IconList.nextChapterUnfilled
    },
    action: ActionList.nextChapter
  },
  restart: {
    icon: {
      filled: IconList.restart,
      unfilled: IconList.restartUnfilled
    },
    action: ActionList.restart
  }
};

import { Injectable } from '@angular/core';

import { LanguageApi, SettingsApi } from '@api/models';
import { Language } from '@app/core/models/language.model';
import { ISettings } from '@app/core/models/settings.interface';
import { SettingsApiService } from '@app/galgo-api/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  currentSettings$: ReplaySubject<ISettings>;
  languageSet$: ReplaySubject<void>;
  lastView$ = '';

  constructor(private settingsApiService: SettingsApiService) {
    this.currentSettings$ = new ReplaySubject<ISettings>();
    this.languageSet$ = new ReplaySubject<void>();
  }

  getSettingsFromApi(): Observable<ISettings> {
    return this.settingsApiService.settingsControllerFind().pipe(
      map((settings: SettingsApi) => {
        const languages = settings.languages.available.map(
          (availableLanguage: LanguageApi) => new Language(availableLanguage)
        );
        const currentSettings: ISettings = {
          availableLanguages: languages,
          defaultLanguage: settings.languages.default,
        };
        this.currentSettings$.next(currentSettings);
        return currentSettings;
      })
    );
  }

  refreshSettings(language?: string): void {
    this.getSettingsFromApi().pipe(untilDestroyed(this)).subscribe();
  }

  setLanguageInLocalStorage(id: string) {
    localStorage.setItem('language', id);
  }

  getLanguageFromLocalStorage(): string {
    return localStorage.getItem('language');
  }

  removeLanguageFromLocalStorage() {
    localStorage.removeItem('language');
  }
}

import { IModalData } from '@app/shared/models/modal-data.interface';
import { ModalActions } from './modal-actions.enum';

export const GEOBLOCKING_ERROR_MODAL_DATA: IModalData = {
  title: 'modal.geoblock.title',
  content: 'modal.geoblock.text',
  icon: 'icon__geoblock',
  buttons: [
    {
      text: 'buttons.close',
      action: ModalActions.return
    }
  ]
};

<div class="button-wrapper"
     appNavigation
     [navigableId]="0"
     (click)="changeState()"
     (focus)="focus()"
     (mouseover)="focus()"
     (blur)="blur()"
     (mouseleave)="blur()">
  <button class="button">
    <span class="content">
      <img *ngIf="iconName"
           src="./assets/customer/icons/{{iconName}}"
           alt="{{iconName}}"
           class="icon"
           [class.icon--space]="showButtonText" />
      <span *ngIf="showButtonText"
            class="text">{{buttonText | translate}}</span>
    </span>
  </button>
</div>

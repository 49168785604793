import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { platforms } from '@app/core/navigation/models/platforms.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Subscription, forkJoin, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { GalgoEvent } from './analytics/models/galgo-event.interface';
import { AnalyticsService } from './analytics/services/analytics.service';
import { APP_CONFIG } from './app.config';
import { User } from './auth/models/user.model';
import { UserService } from './auth/services/user/user.service';
import { Language } from './core/models/language.model';
import { NetworkStatus } from './core/models/network-status.enum';
import { ISettings } from './core/models/settings.interface';
import { ignoreURLConfig, mainURLConfig } from './core/navigation/config/app-navigation-url.config';
import { KeyCodesNames } from './core/navigation/config/keycodes.config';
import { NavigationComponent } from './core/navigation/navigation.component';
import { BrowsingHistoryService } from './core/navigation/services/browsing-history.service';
import { InitializerNavigationModuleService } from './core/navigation/services/initializer-navigation-module.service';
import { KeyboardService } from './core/navigation/services/keyboard.service';
import { NavigationModeService } from './core/navigation/services/navigation-mode.service';
import { PlatformService } from './core/navigation/services/platform.service';
import { CustomerDataService } from './core/services/customer-data.service';
import { EnvironmentService } from './core/services/environment.service';
import { LanguageService } from './core/services/language.service';
import { AppConfig } from './models/app.model';
import { SettingsService } from './pages/protected/settings/services/settings.service';


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ty-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: AppComponent }],
})
export class AppComponent extends NavigationComponent implements OnInit {

  cursor: boolean;
  isBrowser: boolean;

  private networkSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private config: AppConfig,
    public keyboardService: KeyboardService,
    public logger: NGXLogger,
    private customerDataService: CustomerDataService,
    private keyBoardService: KeyboardService,
    private initializerNavigationServiceModule: InitializerNavigationModuleService,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private environmentService: EnvironmentService,
    private platformService: PlatformService,
    private browsingHistoryService: BrowsingHistoryService,
    private router: Router,
    public el: ElementRef,
    public navigationModeService: NavigationModeService
  ) {
    super(el);
    this.navigationModeService.cursor
      .subscribe({
        next: (value: boolean) => {
          this.cursor = value;
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error(
            'AppComponent -> cursor(): Failed',
            error
          );
          throw new Error('Error obtaining cursor boolean');
        }
      });
    this.setRootNode();

    this.getKeysEvents();
    this.getSettings();
    this.getEssentialData();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  @HostListener('document:keydown', ['$event'])
  rootKeyboardHandler(event: KeyboardEvent): void {
    this.keyboardService.changeKeyboardEventListener(event);
  }

  ngOnInit(): void {
    this.analytics.appVersion = this.environmentService.version;
    this.document.addEventListener('cursorStateChange', (event: any) => {
      this.navigationModeService.cursor.next(event.detail.visibility);
      this.cursor = event.detail.visibility;
    }, false);
    this.initializerNavigationServiceModule.initializeServices(ignoreURLConfig, mainURLConfig);
    this.isBrowser = this.platformService.platform === platforms.browser;
    this.platformService.configureNetworkStateChanges();
    this.networkSubscription = this.platformService.getNetworkEvents().subscribe({
      next: (status: string) => {
        if (status === NetworkStatus.disconnected) {
          const route = this.router.url;
          if (['/player'].some(item => route.includes(item))) {
            alert('CONNECTION LOST');
            this.browsingHistoryService.goBack();
          }
        }
      },
      error: (error) => {
        this.logger.error('PlatformService -> getNetworkEvents(): Failed', error);
      }
    });

  }

  getKeysEvents(): void {

    this.keyBoardService.keyEvent.subscribe({
      next: (key: string) => {
        switch (key) {
          case KeyCodesNames.up:
            this.handleUpKey();
            break;
          case KeyCodesNames.down:
            this.handleDownKey();
            break;
          case KeyCodesNames.right:
            this.handleRightKey();
            break;
          case KeyCodesNames.left:
            this.handleLeftKey();
            break;
          case KeyCodesNames.enter:
            this.handleEnterKey();
            break;
          case KeyCodesNames.back:
            this.handleBackKey();
            break;
          case KeyCodesNames.playPause:
            this.handlePlayPauseKey();
            break;
          case KeyCodesNames.play:
            this.handlePlayKey();
            break;
          case KeyCodesNames.pause:
            this.handlePauseKey();
            break;
          case KeyCodesNames.stop:
            this.handleStopKey();
            break;
          case KeyCodesNames.rewind:
            this.handleRewindKey();
            break;
          case KeyCodesNames.fastForward:
            this.handleFastForwardKey();
            break;
        }
        this.handleKeyPress(key);
      },
      error: (error) => {
        this.logger.error('NavigationService -> handleKey(): Failed', error);
      }
    });
  }

  private getUser() {
    if (this.userService?.currentUser) {
      return this.userService.getUserDataFromServer().pipe(untilDestroyed(this));
    }
    return of(new User());
  }

  private getSettings() {
    return this.settingsService.getSettingsFromApi().pipe(untilDestroyed(this));
  }

  private getEssentialData() {
    forkJoin([this.getUser(), this.getSettings()])
      .pipe(first(), untilDestroyed(this))
      .subscribe({
        next: ([user, settings]: [User, ISettings]) => {
          this.checkLanguage(settings);
          if (user?.id) {
            this.analytics.setUserId(user.id);
            this.analytics.logEvent(GalgoEvent.newSession);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.checkLanguage(undefined);
          this.logger.error(
            'AppComponent -> GetEssentialData(): Failed',
            error
          );
          throw new Error('Error obtaining settings');
        }
      });
  }

  private checkLanguage(settings: ISettings) {
    const url = new URL(window.location.href);
    const queryLanguage = url.searchParams.get('lang');
    const languageFromLocalStorage = this.settingsService.getLanguageFromLocalStorage();
    this.setLanguage(this.customerDataService.defaultLanguage);

    if (
      this.userService.currentUserLanguage &&
      !queryLanguage &&
      !languageFromLocalStorage
    ) {
      this.setLanguage(this.userService.currentUserLanguage);
      return;
    }

    const browserLanguages = navigator.languages;
    if (queryLanguage || languageFromLocalStorage) {
      const availableLanguage = queryLanguage || languageFromLocalStorage;
      const isAvailable = !!settings?.availableLanguages?.find(
        (language: Language) => language.id === availableLanguage
      );
      if (isAvailable) {
        this.setLanguage(availableLanguage);
        this.settingsService.setLanguageInLocalStorage(availableLanguage);
        return;
      }
    }

    const navigatorLanguageAvailable = browserLanguages.find((browserLanguage: string) =>
      settings?.availableLanguages?.some(
        (language: Language) => language?.id === this.getLanguageISOCode(browserLanguage)
      )
    );

    if (navigatorLanguageAvailable) {
      this.setLanguage(this.getLanguageISOCode(navigatorLanguageAvailable));
      return;
    }
  }

  private setLanguage(id: string) {
    this.languageService.setLanguage(id);
    this.languageService.use(id);
    this.languageService.currentLang = id;
    this.document.documentElement.lang = id;
    this.settingsService.languageSet$.next();
  }

  private getLanguageISOCode(language: string): string {
    return language.split('-', 1)[0];
  }
}

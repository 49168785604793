<div id="keyboard">
  <div class="grid">
    <div *ngFor="let key of keyboardKeys; let col = index"
         (click)="keyboardEvent(key)"
         appNavigation
         [navigableId]="col">{{key.content}}</div>
  </div>
  <div class="row">
    <span class="space"
          appNavigation
          [navigableId]="36"
          (click)="keyboardEvent(spaceKey)">
      <i class="gi-2xl gi-space"></i>
    </span>
    <span class="backspace"
          appNavigation
          [navigableId]="37"
          (click)="keyboardEvent(backspaceKey)">
      <i class="gi-2xl gi-backspace"></i>
    </span>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { LoginRedirectGuard } from '@app/auth/guards/login-redirect.guard';
import { MainLayoutComponent } from './main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'settings',
        pathMatch: 'full',
      },
       {
         path: 'home',
         canActivate: [AuthGuard],
         loadChildren: () => import('./home/home-page.module').then((m) => m.HomeModule),
       },
      {
        path: 'video',
        canActivate: [AuthGuard],
        loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
      },
      {
        path: 'series',
        canActivate: [AuthGuard],
        loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
      },
      {
        path: 'microsites',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./container-grid/container-grid.module').then((m) => m.ContainerGridModule),
          },
          {
            path: 'detail',
            loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
          }
        ]
      },
      {
        path: 'categories',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./container-grid/container-grid.module').then((m) => m.ContainerGridModule),
          },
          {
            path: 'detail',
            loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
          }
        ]
      },
      {
        path: 'collections',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./container-grid/container-grid.module').then((m) => m.ContainerGridModule),
          },
          {
            path: 'detail',
            loadChildren: () => import('./media-detail/media-detail.module').then((m) => m.MediaDetailModule),
          }
        ]
      },
      {
        path: 'my-space',
        canActivate: [LoginRedirectGuard],
        loadChildren: () => import('./container-grid/container-grid.module').then((m) => m.ContainerGridModule),
        data: {
          containerType: 'favourites'
          //Cannot use ContainerGridTypes.mySpace becaues of this bug of compodoc {@link https://github.com/compodoc/compodoc/issues/1334}
        }
      },
      {
        path: 'search',
        canActivate: [AuthGuard],
        loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'collections-menu',
        canActivate: [AuthGuard],
        loadChildren: () => import('./container-grid/container-grid.module').then((m) => m.ContainerGridModule),
        data: {
          containerType: 'Collection' //ContainerTypeApi.Collection
        }
      },
      {
        path: 'genres',
        canActivate: [AuthGuard],
        loadChildren: () => import('./container-grid/container-grid.module').then((m) => m.ContainerGridModule),
        data: {
          containerType: 'CategoryType', //ContainerTypeApi.CategoryType,
          categoryTypeId: 2 //CategoryTypeEnum.topics
        }
      },
      {
        path: 'purchases',
        canActivate: [LoginRedirectGuard],
        loadChildren: () => import('./container-grid/container-grid.module').then((m) => m.ContainerGridModule),
        data: {
          containerType: 'purchases' //ContainerGridTypes.purchases,
        }
      },
      {
        path: 'see-more',
        canActivate: [AuthGuard],
        loadChildren: () => import('./see-more/see-more.module').then((m) => m.SeeMoreModule),
      },
      {
        path: 'settings',
        canActivate: [LoginRedirectGuard],
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'lives',
        canActivate: [AuthGuard],
        loadChildren: () => import('./lives/lives.module').then((m) => m.LivesModule),
      },
      {
        path: 'login-redirect',
        loadChildren: () => import('./login-redirect/login-redirect.module').then((m) => m.LoginRedirectModule),
      },
    ]
  },
  {
    path: 'player',
    loadChildren: () => import('./player/player.module').then((m) => m.PlayerModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProtectedPagesRoutingModule { }

import { Injectable } from '@angular/core';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  loading: boolean;
  loader: BehaviorSubject<boolean>;
  eventsToDisable: string[] = ['keydown', 'mousedown', 'mouseover', 'click', 'mousemove'];

  get isSearch(): boolean {
    return this.browsingHistoryService.currentURL ===  AppRoutes.search;
  }

  constructor(private browsingHistoryService: BrowsingHistoryService) {
    this.loading = false;
    this.loader = new BehaviorSubject<boolean>(false);
   }
   showLoader(): void {
    this.loading = true;
    if(!this.isSearch){
      this.eventsToDisable.forEach(event => {
        document.addEventListener(event, this.disableEventPropagation, true);
      });
    }
    this.loader.next(this.loading);
  }

  hideLoader(): void {
    this.loading = false;
      this.eventsToDisable.forEach(event => {
        document.removeEventListener(event, this.disableEventPropagation, true);
      });
    this.loader.next(this.loading);
  }

  isLoading(): Observable<boolean> {
    return this.loader.asObservable().pipe(distinctUntilChanged());
  }

  disableEventPropagation(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}

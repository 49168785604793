<div *ngIf="!!seasons?.length">
  <div *ngFor="let season of seasons; let seasonIndex = index"
       [ngClass]="{'selected-season': seasonIndex === selectedSeason}"
       class="season">
    <div class="quaternary-button"
         appNavigation
         [navigableId]="seasonIndex"
         (focus)="setSelectSeason(seasonIndex)">
      <button class="button button-medium">
        <div class="content">
          <span class="content__season">{{'details.season' | translate}} {{seasonIndex+1}}</span>
          <span class="content__chapters">{{getNumberOfChapters(seasonIndex)}}
            {{'details.chapters' | translate}}</span>
        </div>
      </button>
    </div>
  </div>
</div>
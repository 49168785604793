<div id="settings">
  <h4>{{'settings.name' | translate}}</h4>
  <div class="settings-columns-wrapper">
    <div class="settings-column">
      <div class="settings-row">
        <div class="first-column">
          <h3>{{'settings.profile.name' | translate}}</h3>
          <img src="assets/images/default-user.png"
               alt="Default user">
        </div>
        <div class="div-padding-top">
          <span *ngIf="data?.userEmail"><strong>{{'settings.profile.email' |
              translate}}</strong>{{data?.userEmail}}</span>
          <span *ngIf="data?.userLanguage"
                class="languages">
            <strong>{{'settings.profile.language' | translate}}</strong>
            <div class="primary-button"
                 (click)="showLanguage=!showLanguage"
                 appNavigation
                 [navigableId]="0">
              <button [class.button--disabled]="languages?.length === 1"
                      class="button button-medium short">{{userLangDescription | translate}}</button>
            </div>
          </span>
          <ty-language-selector *ngIf="showLanguage && languages?.length > 1"
                                [navigableId]="1"
                                [languages]="languages"
                                (showLanguageChange)="onShowLanguageChange($event)"></ty-language-selector>
        </div>
      </div>
      <div class="settings-row"
           *ngIf="data?.subscriptionPlan">
        <div>
          <h3>{{'settings.subscription.name' | translate}}</h3>
        </div>
        <div>
          <span *ngIf="data?.subscriptionPlan"><strong>{{'settings.subscription.plan' |
              translate}}</strong>{{data?.subscriptionPlan}}</span>
          <span *ngIf="data?.subscriptionPaymentMethod"><strong>{{'settings.subscription.payment' |
              translate}}</strong>{{data?.subscriptionPaymentMethod}}</span>
          <span *ngIf="data?.subscriptionPaymentDate"><strong>{{'settings.subscription.date' |
              translate}}</strong>{{data?.subscriptionPaymentDate}}</span>
        </div>
      </div>
      <div class="settings-row bottom-part">
        <div class="first-column">
          <h3>{{'legal.name' | translate}}</h3>
        </div>
        <div>
          <div class="tertiary-button"
               (click)="goTo(appRoutes.terms)"
               appNavigation
               [navigableId]="2">
            <button class="button button-medium no-margin legals">{{'legal.terms' |
                translate}}</button>
          </div>
          <div class="tertiary-button terms"
               (click)="goTo(appRoutes.privacy)"
               appNavigation
               [navigableId]="3">
            <button class="button button-medium legals">{{'legal.privacy' |
                translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="settings-column">
      <h3>{{'settings.contact.name' | translate}}</h3>
      <p>{{'settings.contact.text' | translate: { brandName: brandName } }}</p>
      <span
            *ngIf="data?.brandEmail"><strong>{{'settings.contact.mail' | translate}}</strong>{{data?.brandEmail}}</span>
      <span *ngIf="data?.brandPhone"><strong>{{'settings.contact.phone' |
          translate}}</strong>{{data?.brandPhone}}</span>
      <span *ngIf="data?.brandWebsite"><strong>{{'settings.contact.web' |
          translate}}</strong>{{data?.brandWebsite}}</span>
      <div class="secondary-button"
           (click)="openLogoutModal()"
           appNavigation
           [navigableId]="4">
        <button class="button button-medium">{{'buttons.logout' | translate}}</button>
      </div>
      <span class="version">{{appVersion}}</span>
    </div>
  </div>
</div>
<div class="header-wrapper">
  <ty-header-player [navigableId]="0"></ty-header-player>
</div>
<div class="button-container">
  <ty-button-player *ngIf="!isLive && showRestartButton"
                    class="button restart"
                    [config]="restartButton"
                    [navigableId]="8"></ty-button-player>
  <ty-button-player *ngIf="!isLive"
                    class="button rewind"
                    [config]="rewindButton"
                    [navigableId]="1"></ty-button-player>
  <ty-button-player class="button play"
                    [config]="centralButton"
                    [navigableId]="2"></ty-button-player>
  <ty-button-player *ngIf="!isLive"
                    class="button forward"
                    [config]="forwardButton"
                    [navigableId]="3"></ty-button-player>
  <ty-button-player *ngIf="!isLive && showNextChapterButton"
                    class="button nextChapter"
                    [config]="nextChapterButton"
                    [navigableId]="6"></ty-button-player>
</div>
<div class="seekbar-container">
  <div class="top-components">
    <ty-list-selector-icon *ngIf="audioTracks?.length > 1 && !isLive"
                           class="list-selector-icon"
                           [itemTracks]="audioTracks"
                           [iconActive]="iconAudioActive"
                           [iconDefault]="iconAudioDefault"
                           (subtitledChanged)="changeAudio($event)"
                           [navigableId]="7"></ty-list-selector-icon>
    <ty-list-selector-icon *ngIf="textTracks?.length && !isLive"
                           class="list-selector-icon"
                           [itemTracks]="textTracks"
                           [iconActive]="iconSubtitleActive"
                           [iconDefault]="iconSubtitleDefault"
                           (subtitledChanged)="changeSubtitle($event)"
                           [navigableId]="4"></ty-list-selector-icon>
  </div>

  <ty-seekbar *ngIf="!isLive"
              class="seekbar-wrap"
              [navigableId]="5"></ty-seekbar>
</div>
<div class="scrim"></div>

<feature-toggle-provider [features]="featureToggleData | async">
  <div id="my-swiper">
    <div #swiper
         id="swiper">
      <ng-container *ngIf="videos && videos.length > 0">
        <div class="banner swiper-wrapper"
             *ngFor="let video of videos; let i = index"
             (click)="getButtonInfo()"
             appNavigation
             [navigableId]="i">
          <div id="home-banner"
               class="swiper-slide">
            <ty-background-thumbnail class="co-background-thumbnail image"
                                     [thumbnail]="video?.thumbnail?.banners"></ty-background-thumbnail>
            <div id="home-banner-detail-container">
              <div class="detail">
                <div class="metadata">
                  <ty-tag *ngIf="isLive(video)"
                          [content]="tagTypeLive"></ty-tag>
                  <ty-tag *ngIf="getSubscriptionName(video)"
                          [content]="tagTypeSubscription"></ty-tag>
                  <ty-tag *ngIf="isPpv(video)"
                          [content]="tagTypePpv"></ty-tag>
                </div>
                <div class="home-banner-topic-title"
                     *featureToggle="'titleAndTopicOnBanner'">
                  <div class="detail-topic-wrapper"
                       *ngIf="video?.layout?.topic && showHeroBannerTopics">
                    <div class="detail-topic"
                         *ngFor="let topic of video?.layout?.topic; let last = last">
                      {{topic.value}}<span *ngIf="!last">,&nbsp;</span>
                    </div>
                  </div>
                  <h1 *ngIf="video?.title">{{video?.title}}</h1>
                </div>
                <div class="primary-button button-banner">
                  <button class="button">{{'home.info' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="swiper-pagination swiper-pagination-horizontal">
      <div class="swiper-pagination-bullet"
           *ngFor="let video of videos; let i = index"
           [ngClass]="{'swiper-pagination-bullet-active': getSelectedChildNodeIndex() === i}"
           (click)="selectChildNodeById(i); updateScroll()">
      </div>
    </div>
  </div>
</feature-toggle-provider>

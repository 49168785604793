/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

import { CatalogResponseApi } from '../models/catalog-response-api';

@Injectable({
  providedIn: 'root',
})
export class CatalogsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation catalogControllerGetCatalogs
   */
  static readonly CatalogControllerGetCatalogsPath = '/catalogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `catalogControllerGetCatalogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  catalogControllerGetCatalogs$Response(params?: {

    /**
     * Whether should return only catalogs for public access. Default: false
     */
    showAll?: boolean;
    language?: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<StrictHttpResponse<Array<CatalogResponseApi>>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogsApiService.CatalogControllerGetCatalogsPath, 'get');
    if (params) {
      rb.query('showAll', params.showAll, {});
      rb.header('language', params.language, {});
      rb.query('image-format', params['image-format'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CatalogResponseApi>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `catalogControllerGetCatalogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  catalogControllerGetCatalogs(params?: {

    /**
     * Whether should return only catalogs for public access. Default: false
     */
    showAll?: boolean;
    language?: string;
    'image-format'?: 'jpg' | 'avif' | 'webp' | 'other';
  }): Observable<Array<CatalogResponseApi>> {

    return this.catalogControllerGetCatalogs$Response(params).pipe(
      //required for catalog selector
      map((r: StrictHttpResponse<Array<CatalogResponseApi>>) => {
        const catalogs = r.body as Array<CatalogResponseApi>;

        catalogs.forEach(catalog => {
          if (catalog.backUrl.endsWith('/')) {
            catalog.backUrl = catalog.backUrl.slice(0, -1);
          }
        });

        return catalogs;
      })
    );
  }

}

import { Injectable } from '@angular/core';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { IBaseApiParams } from '@core/models/base-api-params.interface';
import { Container, IContainerByIdParams } from '@core/models/container.model';
import { ContainerService } from '@core/services/container.service';
import { Carousel } from '@shared/features/carousel/model/carousel.model';
import { NGXLogger } from 'ngx-logger';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private _customCarouselsError = false;

  constructor(
    private containerService: ContainerService,
    private browsingHistoryService: BrowsingHistoryService,
    private logger: NGXLogger
  ) {}

  get currentUrl(): string {
    return this.browsingHistoryService.currentURL;
  }

  get customCarouselsError(): boolean {
    return this._customCarouselsError;
  }

  set customCarouselsError(value: boolean) {
    this._customCarouselsError = value;
  }

  getHomeContainers(
    homeParams: IContainerByIdParams,
    customParams: IBaseApiParams
  ): Observable<[Container, Carousel[]]> {
    return forkJoin([
      this.containerService.getContainerById(homeParams),
      this.currentUrl === AppRoutes.home
        ? this.containerService.getCustomCarousels(customParams).pipe(
            tap(() => {
              this.customCarouselsError = false;
            }),
            catchError((error) => {
              this.logger.trace('HomeService => getHomeContainers', error);
              this.customCarouselsError = true;
              return of([]);
            })
          )
        : of([]),
    ]).pipe(first());
  }
}

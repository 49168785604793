import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { AnalyticsService } from '@app/analytics/services/analytics.service';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { ErrorResponseApi, ItemTypeApi } from '@app/galgo-api/models';
import { GridComponent } from '@app/shared/components';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchService } from '@pages/protected/search/services/search.service';
import { SettingsService } from '@pages/protected/settings/services/settings.service';
import { KeyboardAction } from '@shared/models/keyboard-actions.enum';
import { IKeyboardEvent } from '@shared/models/keyboard-event.interface';
import { KeyboardSearchService } from '@shared/services/keyboard-search.service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, throwError } from 'rxjs';
import { SearchConfig } from './search.config';
@UntilDestroy()
@Component({
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: SearchComponent }],
})
export class SearchComponent extends NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('search') search!: GridComponent;

  galgoEvent: GalgoEvent;
  mediaItems$: BehaviorSubject< MediaItem<MediaItemTypes>[]> = new BehaviorSubject([]);
  resultsNotFound: boolean;
  searchTitle: string;
  textToSearch: string;
  title: string;
  scrollTop: number;
  isLoading: boolean;
  searchDone: boolean;
  currentUrl: string;

  private _setInitialFocus: boolean;
  public get setInitialFocus(): boolean {
    return this._setInitialFocus;
  }
  public set setInitialFocus(value: boolean) {
    this._setInitialFocus = value;
  }

  get navigableGrid(): boolean {
    return SearchConfig.navigableGrid;
  }

  constructor(
    private keyboardSearchService: KeyboardSearchService,
    private customerDataService: CustomerDataService,
    private analyticsService: AnalyticsService,
    public logger: NGXLogger,
    private searchService: SearchService,
    private settingsService: SettingsService,
    private browsingHistoryService: BrowsingHistoryService,
    public el: ElementRef
  ) {
    super(el);
    this.galgoEvent = GalgoEvent.videoFromPopularSearch;
    this.title = SearchConfig.title;
    this.searchTitle = SearchConfig.popularTitle;
    this.textToSearch = '';
    this.setInitialFocus = false;
    this.resultsNotFound = false;
    this.scrollTop = 0;
    this.searchDone = false;
    this.currentUrl = '';
  }

  ngOnInit(): void {
    this.currentUrl = this.browsingHistoryService.currentURL;
    this.settingsService.languageSet$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
        if (focusData?.search) {
          this.mediaItems$.next(focusData.search.items);
          this.searchTitle = focusData.search.searchTitle;
          this.selectChildNodeById(1);
        } else {
          this.getPopularVideos();
        }
      },
    });
    this.getKeyboardInput();
  }

  ngOnDestroy(): void {
    const focusData = this.browsingHistoryService.getFocusData(this.currentUrl);
    if (focusData) {
      focusData.search = {
        items: this.mediaItems$.value,
        searchTitle: this.searchTitle
      };
    }
  }

  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
      return false;
    }
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    this.selectNextChildNode();
    return true;
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }

  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.search.el.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  private getPopularVideos() {
    this.searchService
      .getPopularVideos()
      .pipe(untilDestroyed(this))
      .subscribe(
        (items: MediaItem<MediaItemTypes>[]) => {
          this.filterSearching(items);
          this.logger.debug(
            'SearchComponent -> GetPopularVideos(): Success',
            items
          );
        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'SearchComponent -> GetPopularVideos(): Failed',
            error
          );
        }
      );
  }


  private filterSearching(response: MediaItem<MediaItemTypes>[]): void {
    this.logger.debug(`SearchComponent -> filterSearching(): Success`,response);

    const collections = response.filter((mediaItem: MediaItem<MediaItemTypes>) =>
      mediaItem?.type === ItemTypeApi.Container
    );
    const videos = response.filter((mediaItem: MediaItem<MediaItemTypes>) =>
      mediaItem?.type === ItemTypeApi.Video
    );
    const audios = response.filter((mediaItem: MediaItem<MediaItemTypes>) =>
      mediaItem?.type === ItemTypeApi.Audio
    );

    this.mediaItems$.next([...collections, ...videos, ...audios]);
  }

  private searchMediaItems() {
    this.searchService
      .searchMediaItem(this.textToSearch)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: MediaItem<MediaItemTypes>[]) => {
          this.logger.debug(
            `SearchComponent -> SearchMediaItems(): Success`,
            response
          );
          this.setInitialFocus = true;
          this.mediaItems$.next(response);
          this.searchDone = response.length === 0 ? false : true;

          if(this.textToSearch.length && this.searchDone){
            this.browsingHistoryService.removeAllFocusData();
            this.galgoEvent = GalgoEvent.search;
            this.resultsNotFound = !response.length;
            this.analyticsService.logEvent(this.galgoEvent, {
              textToSearch: this.textToSearch,
            });
            this.galgoEvent = GalgoEvent.videoFromSearch;
          } else {
            this.galgoEvent = GalgoEvent.videoFromPopularSearch;
            this.analyticsService.logEvent(this.galgoEvent, {
              textToSearch: this.textToSearch,
              });
            this.resultsNotFound = !response.length;
          }

        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            `SearchComponent -> SearchMediaItems(): Success`,
            error
          );
          return throwError(error);
        }
      );
  }

  private getKeyboardInput() {
    let typingTimer: any;
    const doneTypingInterval = 1500; // 1.5 second

    this.keyboardSearchService.keyBoardObservable
      .pipe(untilDestroyed(this))
      .subscribe(
        (keyboardEvent: IKeyboardEvent) => {
          if (keyboardEvent) {
            clearTimeout(typingTimer);

            switch (keyboardEvent.action) {
              case KeyboardAction.Add:
                if (this.textToSearch.length < 50) {
                  this.textToSearch = this.textToSearch + keyboardEvent.content;
                }
                if (this.textToSearch.length > 3) {
                  typingTimer = setTimeout(() => {
                    this.searchMediaItems();
                  }, doneTypingInterval);
                }
                break;
              case KeyboardAction.Delete:
                if (this.textToSearch.length !== 1) {
                  this.textToSearch = this.textToSearch.slice(0, -1);
                  if (this.textToSearch.length > 3) {
                    typingTimer = setTimeout(() => {
                      this.searchMediaItems();
                    }, doneTypingInterval);
                  }
                } else {
                  this.textToSearch = '';
                  this.getPopularVideos();
                }
                break;
              case KeyboardAction.Search:
                if (this.textToSearch) {
                  this.searchMediaItems();
                }
                break;
            }
          }
          if (this.textToSearch) {
            this.searchTitle = this.textToSearch;
          } else if (this.searchDone) {
            this.searchTitle = SearchConfig.searchResult;
          } else {
            this.searchTitle = SearchConfig.popularTitle;
          }
        },
        (error: ErrorResponseApi) => {
          this.logger.error(
            'SearchComponent -> GetKeyboardInput(): Failed',
            error
          );
        }
      );
  }
}

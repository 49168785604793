<div class="seekbar-wrapper">
  <div *ngIf="showProgress"
       class="time current-time">{{ timeString }}</div>
  <ty-custom-seekbar class="seekbar"
                     [value]="seekTime"
                     [duration]="duration"
                     [thumbnails]="thumbnails"
                     [outerSeeking]="isSeeking"
                     [storyboard]="storyboard"
                     [isLive]="isLive"
                     [isSeekable]="isSeekable"
                     (seeking)="setSeeking($event)"
                     (onchange)="videoSeek($event)"
                     (click)="seekbarClick()"
                     appNavigation
                     [navigableId]="0"></ty-custom-seekbar>
  <div *ngIf="!isLive"
       class="time duration">{{ durationString }}</div>
</div>
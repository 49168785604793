import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit
} from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { PlayerName } from '@app/galgo-player/config';
import { VideoDetailService } from '@app/pages/protected/media-detail/services/video-detail.service';
import { AudioTrack } from '@app/shared/models/audioTrack.interface';
import { IPlayPauseButton } from '@app/shared/models/button-player.interface';
import { GalgoPlayerService } from '@app/shared/services/galgo-player.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IButtonConfig } from '@shared/components/button-list/button-config.interface';
import { NGXLogger } from 'ngx-logger';
import { FeatureFlagsService } from '../../../core/services/feature-flags.service';
import { playerButtons } from './configuration/player-buttons.config';

@UntilDestroy()
@Component({
  selector: 'ty-controls-player',
  templateUrl: './controls-player.component.html',
  styleUrls: ['./controls-player.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: ControlsPlayerComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlsPlayerComponent
  extends NavigationComponent implements OnInit
{

  playerName: PlayerName;
  isLive: boolean;
  audioTracksArray: AudioTrack[] = [];
  iconSubtitleActive = './assets/customer/icons/filled-btn-subtitle.svg';
  iconSubtitleDefault = './assets/customer/icons/unfilled-btn-subtitle.svg';
  iconAudioActive = './assets/customer/icons/filled-btn-audio.svg';
  iconAudioDefault = './assets/customer/icons/unfilled-btn-audio.svg';


  get forwardButton(): IButtonConfig {
    return playerButtons?.forward;
  }

  get centralButton(): IPlayPauseButton {
    return playerButtons?.play_pause;
  }
  get rewindButton(): IButtonConfig {
    return playerButtons?.rewind;
  }

  get nextChapterButton(): IButtonConfig {
    return playerButtons?.nextChapter;
  }

  get showNextChapterButton(): boolean {
    return this.videoDetailService.getVideoNext() ? true : false;
  }

  get textTracks(): TextTrack[] {
    return this.galgoPlayerService.subtitles;
  }

  get audioTracks(): AudioTrack[]{
    return this.galgoPlayerService.audioTracks;
  }

  constructor(
    public el: ElementRef,
    public logger: NGXLogger,
    private customerDataService: CustomerDataService,
    private galgoPlayerService: GalgoPlayerService,
    private featureFlagsService: FeatureFlagsService,
    private videoDetailService: VideoDetailService
    ) {
    super(el);
    this.playerName = this.customerDataService.playerName;
    this.isLive = this.galgoPlayerService.live;

  }

  ngOnInit(): void {
    this.setDefaultActiveChildNodeId(2);
  }

  onLeftKey(): boolean {
    if(!this.isLive && (this.getSelectedChildNodeId() > 1 && this.getSelectedChildNodeId() < 4 )) {
        this.selectPreviousChildNode();
    }
    if (this.getSelectedChildNodeId() === 6) {
      this.selectChildNodeById(3);
    }

    if (this.getSelectedChildNodeId() === 4) {
      this.selectChildNodeById(7);
    }
    return true;
  }

  onRightKey(): boolean {
    if(!this.isLive && (this.getSelectedChildNodeId() > 0 && this.getSelectedChildNodeId() < 3 )) {
        this.selectNextChildNode();
    }else if (this.getSelectedChildNodeId() === 3 && this.selectChildNodeById(6)){
      this.selectChildNodeById(6);
    }else if (this.getSelectedChildNodeId() === 7) {
      this.selectChildNodeById(4);
    }
    return true;
  }

  onDownKey(): boolean {
    if (this.getSelectedChildNodeId() === 0) {
      this.selectChildNodeById(2);
    }else if (this.getSelectedChildNodeId() > 0 && this.getSelectedChildNodeId() < 4 || this.getSelectedChildNodeId() === 6){
      this.selectChildNodeById(5);
    }else if (this.getSelectedChildNodeId() === 4 || this.getSelectedChildNodeId() === 7){
      this.selectChildNodeById(5);
    }
    return true;
  }

  onUpKey(): boolean {
    const selectedChildNodeId = this.getSelectedChildNodeId();
    switch (selectedChildNodeId) {
      case 4:
        this.selectChildNodeById(2);
        break;
      case 7:
        this.selectChildNodeById(2);
        break;
      case 5:
        if (this.getChildNodeById(7)){
          this.selectChildNodeById(7);
        }else if (this.getChildNodeById(4)){
          this.selectChildNodeById(4);
        }else{
          this.selectChildNodeById(2);
        }
        break;
      default:
        if (this.featureFlagsService.currentFeaturesValue.showBackButton) {
          if (selectedChildNodeId > 0 && selectedChildNodeId < 4 || selectedChildNodeId === 6) {
            this.selectChildNodeById(0);
          } else {
            this.selectPreviousChildNode();
          }
        }
    }
    return true;
  }

  onEnterKey(): boolean {
    if(this.getSelectedChildNodeId() !== 5 ){
      (this.getChildNodeByPosition(this.getSelectedChildNodeId()).el.nativeElement as HTMLElement).click();
    }
    return true;
  }

  changeSubtitle(subtitleId: number) {
    if (subtitleId !== 0) {
      this.textTracks.forEach(track => {
        track.mode = 'disabled';
      });
      this.galgoPlayerService.driver.unselectSubtitles();
      this.textTracks[subtitleId - 1].mode = 'showing';
      this.galgoPlayerService.driver.setSelectedSubtitle(subtitleId);
    } else {
      this.textTracks.forEach(track => {
        track.mode = 'disabled';
      });
      this.galgoPlayerService.driver.unselectSubtitles();
    }
  }
  changeAudio(audioId: number) {
    this.galgoPlayerService.enableAudioTrack(audioId-1);
  }
}

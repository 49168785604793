import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { GalgoPlayerModule } from '@app/galgo-player/galgo-player.module';
import { PlayerUiModule } from '@app/shared/components/player-ui/player-ui.module';
import { PlayerRoutingModule } from './player-routing.module';
import { PlayerComponent } from './player/player.component';

const ANGULAR_MODULES = [ CommonModule ];
const COMPONENTS_MODULES = [
  GalgoPlayerModule
];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
    ... COMPONENTS_MODULES,
    PlayerRoutingModule,
    GalgoPlayerModule,
    NavigationModule,
    PlayerUiModule
  ],
  declarations: [ PlayerComponent ],
})
export class PlayerModule { }

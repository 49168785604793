import { IURLMatch } from '../models/url-match.interface';

export const ignoreURLConfig: IURLMatch[] = [
  {
    url: '/pages/login-redirect',
    matchExact: true
  },
  {
    url: '/pages/player',
    matchExact: false
  },
  {
    url: '/similar',
    matchExact: false
  },
  {
    url: '/more-information',
    matchExact: false
  },
  {
    url: '/chapters',
    matchExact: false
  },
  {
    url: '/details',
    matchExact: false
  },
];

export const mainURLConfig: IURLMatch[] = [
  {
    url: '/pages/home',
    matchExact: true
  },
  {
    url: '/pages/search',
    matchExact: true
  },
  {
    url: '/pages/my-space',
    matchExact: true
  },
  {
    url: '/pages/settings',
    matchExact: true
  },
  {
    url: '/pages/collections-menu',
    matchExact: true
  },
  {
    url: '/pages/genres',
    matchExact: true
  },
  {
    url: '/pages/purchases',
    matchExact: true
  },
  {
    url: '/pages/lives',
    matchExact: true
  },
  {
    url: '/pages/channels',
    matchExact: true
  },
];
